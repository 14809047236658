import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";

function PrivateRoute({ children }) {
  const auth = useContext(AuthContext);

  return auth.token.session_id ? children : <Navigate to="/" />;
}

export default PrivateRoute;
