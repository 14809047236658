import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const MemberNav = (props) => {
    return (
        <ListGroup className="me-4 left-content">
            <ListGroup.Item as={Link} to="/member-info" action variant="light">
                {props.t("memberNav.Basic Information")}
            </ListGroup.Item>
            <ListGroup.Item as={Link} to="/deposit" action variant="light">
                {props.t("memberNav.Deposit")}
            </ListGroup.Item>
            <ListGroup.Item as={Link} to="/withdraw" action variant="light">
                {props.t("memberNav.Withdraw")}
            </ListGroup.Item>
            <ListGroup.Item as={Link} to="/bet-record" action variant="light">
                {props.t("memberNav.Bet Record")}
            </ListGroup.Item>
            <ListGroup.Item as={Link} to="/statement" action variant="light">
                {props.t("memberNav.Statement")}
            </ListGroup.Item>
            <ListGroup.Item
                as={Link}
                to="/change-password"
                action
                variant="light"
            >
                {props.t("memberNav.Change Password")}
            </ListGroup.Item>
            <ListGroup.Item as={Link} to="/bonus" action variant="light">
                {props.t("memberNav.Bonus")}
            </ListGroup.Item>
            <ListGroup.Item as={Link} to="/transfer" action variant="light">
                {props.t("memberNav.Transfer")}
            </ListGroup.Item>
            <ListGroup.Item as={Link} to="/turnover" action variant="light">
                {props.t("memberNav.Turnover")}
            </ListGroup.Item>
        </ListGroup>
    );
};

export default MemberNav;
