import { useState, useEffect } from "react";
import {
    Container,
    Row,
    Form,
    Button,
    Spinner
} from "react-bootstrap";
import { betRecord as betRecordInApi, gameSwitch as gameSwitchInApi } from "../../../api";
import MemberNav from "../MemberNav";
import Date from "../../../components/date/Date";
import TableBetRecord from "../../../components/table/TableStament";

const BetRecord = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);

    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
        provider: "",
        url: "",
    });

    useEffect(() => {
        const gameSwitch = async () => {
            const response = await gameSwitchInApi()
            const groupGame = {}
            response.forEach(game => {
                if (!game.category || !game.betRecordUrl) return;

                if (!groupGame[game.category]) groupGame[game.category] = [];
                groupGame[game.category].push({
                    value: game.betRecordUrl,
                    text: game.name,
                    gamesSwitch: game.gamesSwitch
                });
            })
            setGames(groupGame);
        }
        gameSwitch()
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        const selectedProvider = event.target.options[event.target.selectedIndex].getAttribute('provider');

        if (name == 'url') {
            setRecord([]);
        }
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
                provider: selectedProvider
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!formData.provider) {
            alert("Please select a provider");
            return false;
        }

        const betRecord = async () => {
            setLoading(true);
            const response = await betRecordInApi(formData);
            if (response) {
                let responseData = response.data;
                if (response.data.report) {
                    responseData = response.data.report
                }
                setRecord(responseData);
            }

            setLoading(false);
        };
        betRecord();
    };

    const gameOptions = (options) => {
        return options.map((option, index) => {
            return (
                <option key={`pro-option-betRecord-${index}`} value={option.value} provider={option.gamesSwitch}>
                    {option.text}
                </option>
            );
        });
    };

    const updateDate = (data) => {
        setFormData(prev => { return { ...prev, ...data } })
    }

    return (
        <Container className="d-flex">
            <MemberNav t={props.t} />
            <Row className="py-2 flex-grow-1 d-flex flex-column right-content">
                <Form.Group className="mb-3">
                    <div className="mb-2">
                        <select className="btn bg-primary" name="url" onChange={(e) => handleChange(e)}>
                            <option>{props.t('betRecord.Provider')}</option>
                            {
                                games &&
                                Object.keys(games).map((gameGroup, index) =>
                                    <optgroup key={`pro-group-betRecord-${index}`} label={gameGroup}>
                                        {gameOptions(games[gameGroup])}
                                    </optgroup>
                                )
                            }
                        </select>
                    </div>
                    <Date data={formData} handleChange={handleChange} selectDate={updateDate} t={props.t} />

                    <Button
                        variant="light"
                        className="btn bg-primary m-auto mt-2 d-block"
                        onClick={(e) => handleSubmit(e)}
                        disabled={loading}>
                        {loading ? (
                            <Spinner
                                variant="standard"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            props.t('betRecord.Submit')
                        )}
                    </Button>
                </Form.Group>
                <TableBetRecord loading={loading} data={record} url={formData.url} provider={formData.provider} t={props.t} />
            </Row>
        </Container>
    )
}

export default BetRecord