import { Link } from "react-router-dom"
const WithdrawMenu = (props) => {

    return (
        <div className="d-flex mb-2">
            <Link className="text-white me-2" to="/withdraw">{props.t('with.Withdraw')}</Link>
            <Link className="text-white" to="/withdraw-record">{props.t('with.Withdraw List')}</Link>
        </div>
    )
}

export default WithdrawMenu