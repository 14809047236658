import { gameSwitch as gameSwitchInApi } from "../../../api";
import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import CustomCarousel from "../../../components/carousel/Carousel";

const Poker = (props) => {
	const [games, setGames] = useState([]);

	useEffect(() => {
		const gameInfo = async () => {
			const response = await gameSwitchInApi("Poker");
			//console.log(response)

			setGames(response);
		};
		gameInfo();
	}, []);

	return (
		<>
			<CustomCarousel />
			<Container className="container-lg" fluid>
				<Row className="game">
					{
						games.length > 0 &&
						games.map((g, index) =>
							<Col key={`sport-${index}`} sm={4}>
								<Link to={`/games/${g.url}`} className="position-relative game-cont">
									<span className="game-enter">Enter</span>
									{(g.url !== 'indo_poker' && !g.ongdoId) && <img className="game-logo game-logo-poker" src={`../images/game-logo/${g.img}.png`} alt={`logo-${g.name}-${index}`} />}
									<h5>{g.name}</h5>
									<img className="game-bg" src={`../images/kg/game/poker/${g.img}.jpg`} alt={`${g.name}-${index}`} />
								</Link>
							</Col>
						)
					}
				</Row>
			</Container>
		</>
	);
};

export default Poker;
