import { useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Table,
    Spinner
} from "react-bootstrap";
import { referralBonusRecord as referralBonusRecordInApi } from "../../../../api";

import MenuKg from "../../../../components/menu/MenuKg";
import BonusMenuKg from "../bonusMenu/BonusMenuKg";
import DateKg from "../../../../components/date/DateKg";

const ReferralBonusRecord = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const referralBonusRecord = async () => {
            setLoading(true);
            const response = await referralBonusRecordInApi(formData);
            //console.log(response)
            if (response.data) {
                setRecord(response.data);
            }

            setLoading(false);
        };
        referralBonusRecord();
    };
    const updateDate = (data) => {
        setFormData(prev => { return { ...prev, ...data } })
    }
    const statusArr = {
        0: "Submitting",
        1: "Completed",
        2: "Closed",
        3: "Expired",
        4: "Processing",
    };
    //console.log(formData);
    return (
        <Container className="container-lg member-info my-3 mb-5" fluid>
            <Row className="gx-0">
                <Col lg={3}>
                    <MenuKg t={props.t} />
                </Col>
                <Col lg={9} className="ms-lg-0 ms-n4">
                    <Row className="py-2 flex-grow-1 d-flex flex-column right-content">
                        <BonusMenuKg t={props.t} />
                        <Form.Group className="my-3">
                            <DateKg data={formData} handleChange={handleChange} selectDate={updateDate} t={props.t} />
                            <Button className="btn-red-gradient px-4 btn-shadow my-3" onClick={(e) => handleSubmit(e)}>Submit</Button>
                        </Form.Group>
                        <Table striped hover>
                            <thead className="thead-bg">
                                <tr>
                                    <th>{props.t('bonus.No.')}</th>
                                    <th>{props.t('bonus.Type')}</th>
                                    <th>{props.t('bonus.End Time')}</th>
                                    <th>{props.t('bonus.Status')}</th>
                                    <th>{props.t('bonus.Transfer Bonus')}</th>
                                    <th>{props.t('bonus.Transfer Count')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ?
                                        <tr>
                                            <td colSpan="6">
                                                <Spinner
                                                    className="d-block mx-auto"
                                                    variant="standard"
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" />
                                            </td>
                                        </tr> :
                                        record.length > 0 ?
                                            record.map((rec, index) => (
                                                <tr key={`refB-record-${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>Provider</td>
                                                    <td>{rec.end_time}</td>
                                                    <td>{props.t('bonus.' + statusArr[rec.status])}</td>
                                                    <td>
                                                        {rec.ref_amount
                                                            ? parseFloat(rec.ref_amount).toFixed(2)
                                                            : "0.00"}
                                                    </td>
                                                    <td>{rec.transfer_count}</td>
                                                </tr>
                                            )) : <tr><td colSpan="6" className="text-center">{props.t('bonus.No record found')}</td></tr>
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default ReferralBonusRecord;
