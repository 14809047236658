import { Container, Row } from "react-bootstrap";

function Footer(props) {
  return (
    <>
      <Container>
        <Row>
          <h5 className="text-white mt-4">OUR PREFERRED BANKING PARTNERS:</h5>
          <div className="d-flex mt-2 mb-5">
            <img src={`../images/design1/footer/links.png`} alt="Links" />
            <img src={`../images/design1/footer/plus.png`} alt="Plus" />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
