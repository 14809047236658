import { Link } from "react-router-dom";

const WithdrawMenu = (props) => {
    const pathName = window.location.pathname;
    
    return (
        <div className="d-flex border-bottom border-1 py-3 px-0 member-menu">
            <Link className={`${pathName === '/withdraw' ? 'active ' : ''}me-4`} to="/withdraw"><h4 className="fw-bold">{props.t('with.Withdraw')}</h4></Link>
            <Link className={`${pathName === '/withdraw-record' ? 'active ' : ''}me-4`} to="/withdraw-record"><h4 className="fw-bold">{props.t('with.Withdraw List')}</h4></Link>
            <Link className={`${pathName === '/bind-bank' ? 'active' : ''}`} to="/bind-bank"><h4 className="fw-bold">{props.t('with.Fix Bank Account')}</h4></Link>
        </div>
    )
}

export default WithdrawMenu