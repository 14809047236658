import { Link } from "react-router-dom"

const TransferMenuKg = (props) => {

    return (
        <div className="d-flex border-bottom border-1 py-3 px-0">
            <Link className="me-4" to="/transfer"><h4 className="fw-bold">Transfer</h4></Link>
            <Link to="/transfer-record"><h4 className="fw-bold">Transfer List</h4></Link>
        </div>
    )
}

export default TransferMenuKg