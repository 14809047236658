import { Link } from "react-router-dom"

const DepositMenuKg = (props) => {

    return (
        <div className="d-flex border-bottom border-1 py-3 px-0">
            <Link className="me-4" to="/deposit"><h4 className="fw-bold">{props.t('deposit.Deposit')}</h4></Link>
            <Link to="/deposit-record"><h4 className="fw-bold">{props.t('deposit.Deposit List')}</h4></Link>
        </div>
    )
}

export default DepositMenuKg