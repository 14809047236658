import { useState, useEffect } from "react";
import {
    Container,
    Row,
    Form,
    Button,
    FormCheck,
    Table,
    Spinner,
} from "react-bootstrap";
import MemberNav from "../MemberNav";
import {
    gameSwitch as gameSwitchInApi,
    checkTurnover as checkTurnoverInApi,
} from "../../../api";
import Date from "../../../components/date/Date";

const Turnover = (props) => {
    const [games, setGames] = useState([]);
    const [checkAll, setCheckAll] = useState(true);
    const [record, setRecord] = useState([]);
    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
        game: [],
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const gameInfo = async () => {
            const response = await gameSwitchInApi();
            setGames(response);
        };
        gameInfo();
    }, []);

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setFormData((prevFormData) => {
            let newValue = value;
            if (type === "checkbox") {
                // Add or remove the provider from checklist of game
                let prevGameList = prevFormData.game;
                let gameIndex = prevGameList.indexOf(value);
                if (gameIndex !== -1) {
                    prevGameList.splice(gameIndex, 1);
                }
                newValue = checked ? [...prevGameList, value] : prevGameList;
            }

            return {
                ...prevFormData,
                [name]: newValue,
            };
        });
    };

    const checkProvider = (category) => {
        const element = document.querySelectorAll(".check-turnover");
        let providerArr = [];
        for (var i = 0; i < element.length; i++) {
            const checkId = element[i].id;
            const ele = document.getElementById("check_" + checkId);
            if (category === "all") {
                ele.checked = checkAll;
            } else if (checkId.toString().indexOf(category) !== -1) {
                ele.checked = !ele.checked;
            }
            if (ele.checked) {
                providerArr.push(ele.value);
            }
        }
        setFormData((prevData) => {
            return {
                ...prevData,
                game: [...providerArr],
            };
        });
        if (category === "all") setCheckAll((prev) => !prev);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (formData.game.length <= 0) {
            alert(`${props.t("turnover.Please select a provider")}`);
            return;
        }
        const checkTurnover = async () => {
            setLoading(true);
            const response = await checkTurnoverInApi(formData);
            if (response.data) {
                setRecord(response.data);
            }
            setLoading(false);
        };
        checkTurnover();
    };
    const updateDate = (data) => {
        setFormData((prev) => {
            return { ...prev, ...data };
        });
    };
    console.log(formData);
    return (
        <Container className="d-flex">
            <MemberNav t={props.t} />
            <Row className="py-2 flex-grow-1 d-flex flex-column right-content">
                <h4 className="text-white">{props.t("turnover.Turnover")}</h4>
                <Form.Group className="d-flex">
                    <Date
                        data={formData}
                        handleChange={handleChange}
                        selectDate={updateDate}
                        t={props.t}
                    />
                </Form.Group>
                <div className="d-flex mt-3 align-items-center">
                    <span className="text-white me-2">
                        {props.t("turnover.Provider")}:
                    </span>
                    <Button
                        onClick={() => checkProvider("all")}
                        className="btn bg-primary me-2"
                        variant="light"
                    >
                        {props.t("turnover.Select All")}
                    </Button>
                    <Button
                        onClick={() => checkProvider("casino")}
                        className="btn bg-primary me-2"
                        variant="light"
                    >
                        {props.t("turnover.Casino")}
                    </Button>
                    <Button
                        onClick={() => checkProvider("sport")}
                        className="btn bg-primary me-2"
                        variant="light"
                    >
                        {props.t("turnover.Sports")}
                    </Button>
                    <Button
                        onClick={() => checkProvider("slot")}
                        className="btn bg-primary me-2"
                        variant="light"
                    >
                        {props.t("turnover.Slots")}
                    </Button>
                    <Button
                        onClick={() => checkProvider("lottery")}
                        className="btn bg-primary me-2"
                        variant="light"
                    >
                        {props.t("turnover.Lottery")}
                    </Button>
                </div>
                <Form className="mt-3">
                    <div className="d-flex flex-wrap justify-content-between">
                        {games.map((g, index) => (
                            <Form.Check
                                className="text-white turnover-item mb-2"
                                key={`turnover-${index}`}
                            >
                                <FormCheck.Label
                                    className="check-turnover"
                                    id={`${g.category.toLowerCase()}_${index}`}
                                >{`${index + 1}. ${g.name}`}</FormCheck.Label>
                                <FormCheck.Input
                                    type="checkbox"
                                    name="game"
                                    value={`${g.turnoverId}`}
                                    id={`check_${g.category.toLowerCase()}_${index}`}
                                    data-category={`${g.category}`}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Form.Check>
                        ))}
                    </div>
                    <Button
                        className="btn bg-primary d-block m-auto mb-4"
                        variant="light"
                        onClick={(e) => handleSubmit(e)}
                    >
                        {props.t("turnover.Submit")}
                    </Button>
                </Form>
                <Table striped bordered hover variant="light">
                    <thead>
                        <tr>
                            <th>{props.t("turnover.No.")}</th>
                            <th>{props.t("turnover.Start Time")}</th>
                            <th>{props.t("turnover.End Time")}</th>
                            <th>{props.t("turnover.Provider")}</th>
                            <th>{props.t("turnover.Turnover")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="5">
                                    <Spinner
                                        variant="standard"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="d-block m-auto"
                                    />
                                </td>
                            </tr>
                        ) : record.length > 0 ? (
                            record.map((rec, index) => (
                                <tr key={`d-record-${index}`}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {formData.startDate.replace("T", " ")}
                                    </td>
                                    <td>
                                        {formData.endDate.replace("T", " ")}
                                    </td>
                                    <td>{rec.p_name}</td>
                                    <td>
                                        {rec.total_amount
                                            ? parseFloat(
                                                  rec.total_amount
                                              ).toFixed(2)
                                            : "0.00"}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    {props.t("turnover.No record found")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
};

export default Turnover;
