import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom";

import CustomCarousel from "../carousel/Carousel";

const MainKg = (props) => {
    return (
        <>
            <CustomCarousel />
            <Container className="container-lg" fluid>
                <Row className="mb-5 game-link">
                    <Col lg={6} className="d-flex">
                        <Link to="/games/ag-casino">
                            <img src={`../images/kg/home/link/ag.png`} alt="AG" />
                        </Link>
                        <Link to="/slots/slots_haba_index">
                            <img src={`../images/kg/home/link/haba.png`} alt="HABA" />
                        </Link>
                        <Link to="/slots/slots_mg">
                            <img src={`../images/kg/home/link/mg.png`} alt="MG" />
                        </Link>
                        <Link to="/slots/slots_pt">
                            <img src={`../images/kg/home/link/pt.png`} alt="PT" />
                        </Link>
                    </Col>
                    <Col lg={6} className="d-flex">
                        <Link to="/games/gd-casino">
                            <img src={`../images/kg/home/link/gd.png`} alt="GD" />
                        </Link>
                        <Link to="/games/afb2-sports">
                            <img src={`../images/kg/home/link/afb.png`} alt="AFB Casino" />
                        </Link>
                        <Link to="/games/dg99-casino">
                            <img src={`../images/kg/home/link/dg99.png`} alt="DG" />
                        </Link>
                        <Link to="/games/wm-casino">
                            <img src={`../images/kg/home/link/wm.png`} alt="WM" />
                        </Link>
                    </Col>
                </Row>
                <Row className="game-category">
                    <Col lg={8} className="mb-2 mb-lg-0">
                        <Row className="game-list g-2 g-md-4">
                            <Col lg={4} xs={6}>
                                <Link to="/casino" className="game-list-first">
                                    <img src={`../images/kg/home/list/casino.jpg`} alt="Casino" />
                                </Link>
                            </Col>
                            <Col lg={4} xs={6} className="d-flex flex-column justify-content-between">
                                <Link to="/sport" className="mb-2 mb-md-4">
                                    <img src={`../images/kg/home/list/sport.jpg`} alt="Sport" />
                                </Link>
                                <Link to="/slot">
                                    <img src={`../images/kg/home/list/games.jpg`} alt="Games" />
                                </Link>
                            </Col>
                            <Col lg={4} sm={12} className="d-flex flex-lg-column justify-content-between">
                                <Link to="/fishing" className="me-2 me-md-4 mb-md-4">
                                    <img src={`../images/kg/home/list/fishing.jpg`} alt="Fishing" />
                                </Link>
                                <Link to="/lottery-lobby">
                                    <img src={`../images/kg/home/list/lottery.jpg`} alt="Lottery" />
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4}>
                        <div className="home-loker d-flex flex-column">
                            <div className="home-loker-title my-2 my-lg-3">
                                <img src={`../images/kg/home/loker/title.png`} alt="Title Lottery" />
                            </div>
                            <div className="home-loker-all mb-2 mb-lg-3 position-relative">
                                <img src={`../images/kg/home/loker/num.png`} alt="Title Lottery" />
                                <span className="position-absolute d-flex align-items-center text-center w-100 h-100 top-0">
                                    <h5 className="text-uppercase">thb</h5>
                                    <b>100,000,000,000.00</b>
                                </span>
                            </div>
                            <div className="home-loker-content position-relative">
                                <img src={`../images/kg/home/loker/txt.png`} alt="Title Lottery" />
                                <marquee direction="up" className="position-absolute">
                                    <ul>
                                        <li>
                                            <img src={`../images/kg/home/link/afb.png`} alt="" />
                                            <b>100,000,000,000.00</b>
                                        </li>
                                        <li>
                                            <img src={`../images/kg/home/link/afb.png`} alt="" />
                                            <b>100,000,000,000.00</b>
                                        </li>
                                        <li>
                                            <img src={`../images/kg/home/link/afb.png`} alt="" />
                                            <b>100,000,000,000.00</b>
                                        </li>
                                        <li>
                                            <img src={`../images/kg/home/link/afb.png`} alt="" />
                                            <b>100,000,000,000.00</b>
                                        </li>
                                        <li>
                                            <img src={`../images/kg/home/link/afb.png`} alt="" />
                                            <b>100,000,000,000.00</b>
                                        </li>
                                    </ul>
                                </marquee>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="app">
                <Container>
                    <div className="app-cont">
                        <h4 className="text-uppercase d-flex align-items-center"><i></i>download mobile app</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ...</p>
                        <ul className="d-flex">
                            <li>
                                <img src={`../images/kg/home/code.png`} alt="" />
                                <Link to="/" className="btn btn-gold-gradient btn-shadow">
                                    ios
                                </Link>
                            </li>
                            <li>
                                <img src={`../images/kg/home/code.png`} alt="" />
                                <Link to="/" className="btn btn-gold-gradient btn-shadow">
                                    android
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Container>
            </div>
            <Container className="container-lg" fluid>
                <div className="promo position-relative">
                    {/* <cite><u></u><u></u><u></u><u></u><u></u><u></u></cite> */}
                    <cite>
                        <u><img src={`../images/kg/home/chip/1.png`} alt="Chip" /></u>
                        <u><img src={`../images/kg/home/chip/2.png`} alt="Chip" /></u>
                        <u><img src={`../images/kg/home/chip/3.png`} alt="Chip" /></u>
                        <u><img src={`../images/kg/home/chip/4.png`} alt="Chip" /></u>
                        <u><img src={`../images/kg/home/chip/5.png`} alt="Chip" /></u>
                        <u><img src={`../images/kg/home/chip/6.png`} alt="Chip" /></u>
                    </cite>
                    <div className="promo-list rounded-3 px-3 py-4 py-lg-5 mb-3 mb-lg-5">
                        <Row>
                            <Col md={12} lg={6} className="d-flex align-items-center mb-3 mb-lg-0">
                                <span>
                                    <i className="bi bi-person-plus-fill"></i>
                                    <h5>Register</h5>
                                </span>
                                <span>
                                    <i className="bi bi-shield-fill-check"></i>
                                    <h5>account security</h5>
                                </span>
                            </Col>
                            <Col md={12} lg={6} className="d-flex align-items-center">
                                <span>
                                    <i className="bi bi-wallet-fill"></i>
                                    <h5>Deposit</h5>
                                </span>
                                <span>
                                    <i className="bi bi-currency-exchange"></i>
                                    <h5>Withdraw</h5>
                                </span>
                            </Col>
                        </Row>
                    </div>

                    <Link to="/">
                        <img className="rounded-3" src={`../images/kg/home/gift.jpg`} alt="Promotion" />
                    </Link>
                </div>
            </Container>
        </>
    )
}

export default MainKg