import { Link } from "react-router-dom";
const BonusMenuKg = (props) => {
    return (
        <div className="d-flex border-bottom border-1 py-3 px-0">
            <Link className="me-3" to="/bonus">
                <h5 className="fw-bold">{props.t("bonus.Bonus")}</h5>
            </Link>
            <Link className="me-3" to="/referral-list">
                <h5 className="fw-bold">{props.t("bonus.Referral List")}</h5>
            </Link>
            <Link className="me-3" to="/referral-bonus">
                <h5 className="fw-bold">{props.t("bonus.Referral Bonus")}</h5>
            </Link>
            <Link className="me-3" to="/referral-bonus-record">
                <h5 className="fw-bold">{props.t("bonus.Referral Bonus Record")}</h5>
            </Link>
            <Link className="me-3" to="/promo-record">
                <h5 className="fw-bold">{props.t("bonus.Promotion Record")}</h5>
            </Link>
            <Link to="/auto-promo-record">
                <h5 className="fw-bold">{props.t("bonus.Auto Promotion Record")}</h5>
            </Link>
        </div>
    );
};

export default BonusMenuKg;
