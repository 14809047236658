import { useState } from "react";
import { Container, Row, Form, Button, Spinner } from "react-bootstrap";
import { changePassword as changePasswordInApi } from "../../../api";
import { toast } from "react-toastify";
import MemberNav from "../MemberNav";

function ChangePassword(props) {
	const [formData, setFormData] = useState({
		currentPass: "",
		newPass: "",
		confirmNew: "",
	});
	const [loading, setLoading] = useState(false);

	const changePassword = async (data) => {
		setLoading(true);
		const response = await changePasswordInApi(data);
		console.log(response);
		if (response.code) {
			toast.success(`${props.t('password.Your password has been changed')}`);
		}
		setLoading(false);
	};
	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormData((prevFormData) => {
			return {
				...prevFormData,
				[name]: type === "checkbox" ? checked : value,
			};
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const { currentPass, newPass, confirmNew } = event.target;

		if (currentPass.value === "") {
			alert(`${props.t('password.Please enter your current password')}`);
			currentPass.focus();
			return false;
		}
		if (newPass.value === "") {
			alert(`${props.t('password.Please enter your new password')}`);
			newPass.focus();
			return false;
		}
		var pattern_passwd = /^(?=.*[0-9].*)(?=.*[a-zA-Z].*).{8,50}$/;
		if (!pattern_passwd.test(newPass.value)) {
			alert(`${props.t('password.Password can only contain alphabet and numbers ,length must be more than 8')}`);
			newPass.focus();
			return false;
		}
		if (confirmNew.value === "") {
			alert(`${props.t('password.Confirm password cannot be empty')}`);
			confirmNew.focus();
			return false;
		}
		if (confirmNew.value !== newPass.value) {
			alert(`${props.t('password.New password and confirm password must be the same')}`);
			confirmNew.focus();
			return false;
		}

		changePassword(formData);
	};

	return (
		<>
			<Container className="password-form d-flex">
				<MemberNav t={props.t} />
				<Row className="py-2 flex-grow-1 d-flex flex-column">
					<h4 className="text-white">{props.t('password.Change Password')}</h4>
					<Form className="mt-4" onSubmit={handleSubmit}>
						<Form.Group className="mb-3 d-flex align-items-center">
							<Form.Label column sm="3" className="text-white">
								{props.t('password.Current Password')}:
							</Form.Label>
							<Form.Control
								type="password"
								name="currentPass"
								onChange={(e) => handleChange(e)}
							/>
						</Form.Group>
						<Form.Group className="mb-3 d-flex align-items-center">
							<Form.Label column sm="3" className="text-white">
								{props.t('password.New Password')}:
							</Form.Label>
							<Form.Control
								type="password"
								name="newPass"
								onChange={(e) => handleChange(e)}
							/>
						</Form.Group>
						<Form.Group
							className="mb-3 d-flex align-items-center"
							controlId="amount"
						>
							<Form.Label column sm="3" className="text-white">
								{props.t('password.Confirm Password')}:
							</Form.Label>
							<Form.Control
								type="password"
								name="confirmNew"
								onChange={(e) => handleChange(e)}
							/>
						</Form.Group>
						<Form.Group className="mb-3 d-flex">
							<Form.Label column sm="3" className="text-white"></Form.Label>
							<Button
								type="submit"
								variant="light"
								className="btn bg-primary"
								disabled={loading}
							>
								{loading ? (
									<Spinner
										variant="standard"
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									props.t('password.Submit')
								)}
							</Button>
						</Form.Group>
					</Form>
				</Row>
			</Container>
		</>
	);
}

export default ChangePassword;
