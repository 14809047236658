import { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner, Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { bankAccount, withdraw as withdrawInApi } from "../../../api"
import { toast } from "react-toastify";
import MenuKg from "../../../components/menu/MenuKg";
import WithdrawMenu from "./withdrawMenu/WithdrawMenuKg";

function Withdraw(props) {
	const [formData, setFormData] = useState({
		bankaccount: "",
		amount: "",
		min: 0,
		max: 0,
		bankName: "",
	});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [bindBankStatus, setBindBankStatus] = useState(false);

	const { id_mod_bank, bank_acc_name, bank_acc_no } = JSON.parse(
		localStorage.getItem("token")
	);


	useEffect(() => {
		if (!id_mod_bank || !bank_acc_name || !bank_acc_no) {
			setBindBankStatus(true);
		}

		bankAccount(1)
			.then((res) =>
				res.forEach(bank => {
					if (bank.id_mod_bank === id_mod_bank) {
						setFormData(prev => {
							return { ...prev, min: bank.min_withdraw, max: bank.max_withdraw, bankName: bank.bank_name }
						});
					}
				}
				));
	}, []);

	const withdraw = async (data) => {
		setLoading(true);
		const response = await withdrawInApi(data);

		const code = parseInt(response.code);
		if (code === 1) {
			toast.success(`${props.t('with.Withdraw successfully')}`);
		} else if (code === -2) {
			toast.error(`${props.t('default.Unauthorized access')}`);
		} else {
			toast.error(`${props.t('default.Request failed') + "-" + code}`);
		}

		setLoading(false);
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormData((prevFormData) => {
			return {
				...prevFormData,
				[name]: type === "checkbox" ? checked : value,
			};
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const { amount } = event.target;

		if (amount.value === "") {
			alert(`${props.t('with.Amount cannot be empty')}`);
			amount.focus();
			return false;
		}

		if (parseFloat(amount.value) < formData.min) {
			alert(`${props.t('with.Amount must be more than') + " " + formData.min}`);
			amount.focus();
			return false;
		}

		if (parseFloat(amount.value) > formData.max) {
			alert(`${props.t('with.Amount cannot more than') + " " + formData.max}`);
			amount.focus();
			return false;
		}

		withdraw(formData, () => navigate("/withdraw", { replace: true }));
	};
	//console.log(formData);
	return (
		<>
			<Container className="container-lg member-info my-3 mb-5" fluid>
				{
					bindBankStatus && (
						<Modal show="true">
							<Modal.Header>
								<Modal.Title>Bind Bank</Modal.Title>
							</Modal.Header>
							<Modal.Body>You have to bind your bank account details to make a withdraw!</Modal.Body>
							<Modal.Footer>
								<Link to="/bind-bank" className="btn btn-red-gradient btn-shadow mx-1">
									Bind Bank
								</Link>
							</Modal.Footer>
						</Modal>
					)
				}
				<Row>
					<Col lg={3}>
						<MenuKg t={props.t} />
					</Col>
					<Col lg={9}>
						<Row className="py-2 px-3">
							<WithdrawMenu t={props.t} />
							<Form className="mt-4" onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="bankaccount">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label>
												{props.t('with.Bank')}:
											</Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Control type="text" value={formData.bankName} readOnly />
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label>
												{props.t('with.Bank Account Name')}:
											</Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Control type="text" value={bank_acc_name} readOnly />
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label>
												{props.t('with.Bank Account Number')}:
											</Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Control type="number" value={bank_acc_no} readOnly />
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3" controlId="amount">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label>
												{props.t('with.Amount')}:
											</Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Control
												type="number"
												onChange={handleChange}
												name="amount"
											/>
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label></Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Button
												type="submit"
												variant="light"
												className="btn btn-red-gradient px-4 btn-shadow"
												disabled={loading}
											>
												{loading ? (
													<Spinner
														variant="standard"
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/>
												) : (
													props.t('with.Submit')
												)}
											</Button>
										</Col>
									</Row>
								</Form.Group>
							</Form>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Withdraw;
