import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { gameInfo as gameInConfig } from "../../config";
import { request as requestInApi } from "../../api";
import { Container, Spinner } from "react-bootstrap";

const PokerGame = () => {
    const [gameUrl, setGameUrl] = useState("");
    const [gameApi, setGameApi] = useState("");
    const [loading, setLoading] = useState(false);

    const { game } = useParams();
    const { session_id, useracc } = JSON.parse(localStorage.getItem("token"));
    const webId = localStorage.getItem("webId");
    let param = "login.php?web_id=" + webId + "&username=" + useracc + "&platfrom=PC&" + "language=en&token=" + session_id;


    useEffect(() => {
        const gameInfo = async () => {
            setLoading(true);
            const response = await gameInConfig({ gametype: "Poker" });

            for (let i = 0; i < response.length; i++) {
                if (response[i].url === game) {
                    if (response[i].ongdoId) {
                        param = param + "&gameid=" + response[i].ongdoId
                    }
                    const api = response[i].api + param

                    if (response[i].apiReturnData === 1) {
                        setGameApi(api);
                    } else {
                        setGameUrl(api);
                    }

                    break;
                }
            }
            setLoading(false);
        };
        gameInfo();

    }, []);

    useEffect(() => {
        if (gameApi) {
            const loginUrl = async () => {
                setLoading(true);

                const getUrl = await fetch(gameApi, {
                    method: "POST"
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((json) => {
                        setGameUrl(json.LoginURL);
                    });

                setLoading(false);

            };
            loginUrl();
        }
    }, [gameApi])
    console.log(gameUrl)
    return (
        <>
            <Container>
                {loading ? (
                    <Spinner
                        variant="standard"
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                        className="text-white m-auto d-block mt-4"
                    />
                ) : (
                    <iframe src={gameUrl} className="w-100" style={{ height: "52rem" }} />
                )}
            </Container>
        </>
    );
};

export default PokerGame;
