import { useState, useEffect, useRef } from "react";
import { Container, Row, Form, Button, Spinner, Table } from "react-bootstrap";
import { transfer as transferInApi, transferBal as transferBalInApi } from "../../../api"
import { toast } from "react-toastify";
import MemberNav from "../MemberNav";
import TransferMenu from "./transferMenu/TransferMenu";

function Transfer(props) {
    const [balance, setBalance] = useState(localStorage.getItem("balance") || 0.00);
    const [formData, setFormData] = useState({
        from: 1,
        to: 1,
        amount: "",
    });
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTransfer, setloadingTransfer] = useState(false);
    const myRef = useRef([]);

    useEffect(() => {
        const transferBal = async () => {
            setLoading(true);
            const response = await transferBalInApi()
            if (response) {
                setGames(response);
            }
            setLoading(false);
        }

        transferBal()
    }, []);
    console.log(games)
    const transfer = async (data) => {
        setloadingTransfer(true);
        const response = await transferInApi(data);
        const code = parseInt(response.code);
        if ([0, 1].includes(code)) {
            toast.success(props.t('transfer.Transfer successfully'));
            setGames((prevGames) => {
                return prevGames.map(game => {
                    if (game.transferId == response.transferId) {
                        if (response.currentBal) {
                            setBalance(response.currentBal)
                        }
                        return { ...game, ...response }
                    }
                    return { ...game }
                })
            })
        } else {
            toast.error(props.t('transfer.Transfer failed'));
        }

        setloadingTransfer(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let newValue = value
        if (['to', 'from'].includes(name)) {
            newValue = parseInt(value)
        }
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: newValue,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { from, to, amount } = event.target;
        const currentBal = parseFloat(balance).toFixed(2);

        if (from.value === to.value) {
            alert(`${props.t('transfer.FROM and TO cannot be the same')}`);
            return false;
        } else if (amount.value <= 0) {
            alert(`${props.t('transfer.Amount cannot be empty')}`);
            amount.focus();
            return false;
        } else if (parseInt(from.value) === 1 && parseFloat(amount.value) > currentBal) {
            alert(`${props.t('transfer.Amount cannot more than Main Balance:') + " " + currentBal}`);
            amount.focus();
            return false;
        } else if (parseInt(from.value) > 1) {
            const gameBal = myRef.current[from.value].getAttribute("data-balance");
            const providerName = myRef.current[from.value].getAttribute("data-name");

            if (parseFloat(amount.value) > parseFloat(gameBal)) {
                alert(`${props.t('transfer.Amount cannot more than') + " " + providerName + ": " + gameBal}`);
                amount.focus();
                return false;
            }

        }
        transfer(formData);
    };
    const balFilter = (data) => {
        let bal = data.balance
        let balText = data.balance
        if (data.m_balance) {
            balText = data.balance + "(" + data.m_balance + ")"
        } else if (data.mem_balance) {
            bal = data.mem_balance
            balText = data.mem_balance
        }

        return [bal, balText];
    }
    return (
        <>
            <Container className="transfer-form d-flex">
                <MemberNav t={props.t} />
                <Row className="py-2 flex-grow-1 d-flex flex-column">
                    <TransferMenu t={props.t} />
                    <Table borderless variant="dark" className="transfer-table">
                        <tbody>
                            <tr>
                                <td>{props.t('transfer.Main Balance')} :</td>
                                <td>{parseFloat(balance).toFixed(2)}</td>
                            </tr>
                            {
                                loading ?
                                    <tr>
                                        <td colSpan="2">
                                            <Spinner
                                                className="d-block mx-auto"
                                                variant="standard"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </td>
                                    </tr> :
                                    games.length > 0 &&
                                    games.map((game, index) => {
                                        const gameBal = balFilter(game)
                                        return (
                                            <tr
                                                className="game_balance"
                                                data-balance={gameBal[0]}
                                                data-name={game.name}
                                                key={`game-transfer-${index}`}
                                                ref={(e) => {
                                                    myRef.current[game.transferId] = e;
                                                }}
                                            >
                                                <td>{game.name} :</td>
                                                <td>
                                                    {gameBal[1]}
                                                </td>
                                            </tr>
                                        )
                                    })
                            }
                        </tbody>
                    </Table>
                    <Form className="mt-4" onSubmit={handleSubmit}>
                        <Form.Group
                            className="mb-3 d-flex align-items-center"
                        >
                            <Form.Label column sm="3">
                                {props.t('transfer.From')}:
                            </Form.Label>
                            <Form.Select name="from" onChange={(e) => handleChange(e)} value={formData.from}>
                                <option value="1">{props.t('transfer.Main Balance')}</option>
                                {
                                    games.length > 0 &&
                                    games.map((game, index) =>
                                        <option key={`from-transfer-${index}`} value={game.transferId}>{game.name}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex align-items-center">
                            <Form.Label column sm="3">
                                {props.t('transfer.To')}:
                            </Form.Label>
                            <Form.Select name="to" onChange={(e) => handleChange(e)} value={formData.to}>
                                <option value="1">{props.t('transfer.Main Balance')}</option>
                                {
                                    games.length > 0 &&
                                    games.map((game, index) =>
                                        <option key={`to-transfer-${index}`} value={game.transferId}>{game.name}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex align-items-center">
                            <Form.Label column sm="3">
                                {props.t('transfer.Amount')}:
                            </Form.Label>
                            <Form.Control type="number" name="amount" onChange={(e) => handleChange(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex">
                            <Form.Label column sm="3"></Form.Label>
                            <Button
                                type="submit"
                                variant="light"
                                className="btn bg-primary"
                                disabled={loadingTransfer || loading}
                            >
                                {loadingTransfer || loading ? (
                                    <Spinner
                                        variant="standard"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    props.t('transfer.Submit')
                                )}
                            </Button>
                        </Form.Group>
                    </Form>
                </Row>
            </Container>
        </>
    );
}

export default Transfer;
