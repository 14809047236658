import { useState } from "react";
import {
    Container,
    Row,
    Form,
    Col,
    Button,
    Table,
    Spinner
} from "react-bootstrap";
import MemberNav from "../../MemberNav";
import { autoPromoRecord as autoPromoRecordInApi } from "../../../../api";
import BonusMenu from "../bonusMenu/BonusMenu";
import Date from "../../../../components/date/Date";

const AutoPromoRecord = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const autoPromoRecord = async () => {
            setLoading(true);
            const response = await autoPromoRecordInApi(formData);
            //console.log(response)
            if (response.data) {
                setRecord(response.data);
            }

            setLoading(false);
        };
        autoPromoRecord();
    };
    const updateDate = (data) => {
        setFormData(prev => { return { ...prev, ...data } })
    }
    const promoType = (type) => {
        if (type.includes('turnover')) {
            return 'Auto Promotion Turnover'
        } else if (type.includes('turnover')) {
            return 'Auto Promotion Win Loss'
        }

        return type;
    }
    //console.log(record);
    return (
        <Container className="d-flex">
            <MemberNav t={props.t} />
            <Row className="py-2 flex-grow-1 d-flex flex-column right-content">
                <BonusMenu t={props.t} />
                <Form.Group className="my-3">
                    <Date data={formData} handleChange={handleChange} selectDate={updateDate} t={props.t} />
                    <Button variant="light" className="btn bg-primary m-auto mt-2 d-block" onClick={(e) => handleSubmit(e)}>Submit</Button>
                </Form.Group>
                <Table striped bordered hover variant="light">
                    <thead>
                        <tr>
                            <th>{props.t('bonus.No.')}</th>
                            <th>{props.t('bonus.Type')}</th>
                            <th>{props.t('bonus.Game name')}</th>
                            <th>{props.t('bonus.Time')}</th>
                            <th>{props.t('bonus.Amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td colSpan="6">
                                    <Spinner
                                        className="d-block mx-auto"
                                        variant="standard"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />
                                </td>
                            </tr> :
                            record.length > 0 ?
                                record.map((rec, index) => (
                                    <tr key={`refB-record-${index}`}>
                                        <td>{index + 1}</td>
                                        <td>{props.t('bonus.' + promoType(rec.remark))}</td>
                                        <td>{rec.parameter2}</td>
                                        <td>{rec.date_time}</td>
                                        <td>
                                            {rec.type2
                                                ? parseFloat(rec.type2).toFixed(2)
                                                : "0.00"}
                                        </td>
                                    </tr>
                                )) : <tr><td colSpan="6" className="text-center">No record found !</td></tr>}
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
};

export default AutoPromoRecord;
