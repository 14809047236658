import { useState, useEffect } from "react";
import { lottery4dSubmitter as lottery4dSubmitterInApi } from "../../../api";
const Lottery2D3D4D = (props) => {
    const [loading, setLoading] = useState(false);
    const numOfRow = 10;
    const [totalAmt, setTotalAmt] = useState(0);
    const [totalNetAmt, setTotalNetAmt] = useState(0);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        let currentTotalAmt = 0;
        for (const [key, value] of Object.entries(formData)) {
            let currentAmt = value === "" ? 0 : parseInt(value);
            if (key.includes("amount") && !key.includes("net")) {
                currentTotalAmt = currentTotalAmt + currentAmt;
            }
        }
        setTotalAmt(currentTotalAmt);
    }, [formData]);
    console.log(formData);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let netAmtArr = [];

        // Maybe change the input name format
        if (name.includes('amount')) {
            const nameNetAmt = 'net-' + name;
            const amt = parseFloat(value);
            const lottType = value.length;
            const disc = props.currentSetting[props.lottName[lottType]].discount;
            const netAmt = (amt - (amt * disc / 100)).toFixed(2);
            netAmtArr = { [nameNetAmt]: netAmt };
        }
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
                ...netAmtArr
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if(window.confirm('Confirm betting?')) {
            const lottery4dSubmitter = async () => {
                setLoading(true);
                const data = { lotteryId: props.currentLott.type2, betInfo: formData, numRow: numOfRow };
                const response = await lottery4dSubmitterInApi(data);
                
                if (parseInt(response.code) === 1) {
                    alert("Bet successfully, please check it in bet list");
                }

                setLoading(false);
            };
            lottery4dSubmitter();
        }
    }
    return (
        <>
            <div className="lottery-info d-flex align-items-center mt-2 p-3">
                <div className="lottery-desc me-2">2D3D4D</div>
                <div className="lottery-time me-auto d-flex align-items-center pt-1">
                    <p className="me-2 mb-0">
                        <span className="fw-bold">OPEN TIME</span> :
                        {props.currentLott.open_time}
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">CLOSE TIME</span> :
                        {props.currentLott.close_time}
                    </p>
                    <p className="mb-0">
                        <span className="fw-bold">PERIOD</span> :
                        {props.currentLott.period}
                    </p>
                </div>
                <div className="lottery-time d-flex align-items-center pt-1">
                    <p className="me-2 mb-0">
                        <span className="fw-bold">DISCOUNT</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">2D</span> : <span>{props.currentSetting['2D'] && props.currentSetting['2D'].discount}%</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">3D</span> : <span>{props.currentSetting['3D'] && props.currentSetting['3D'].discount}%</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">4D</span> : <span>{props.currentSetting['4D'] && props.currentSetting['4D'].discount}%</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">ODDS</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">2D</span> : <span>{props.currentSetting['2D'] && props.currentSetting['2D'].factor}</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">3D</span> : <span>{props.currentSetting['3D'] && props.currentSetting['3D'].factor}</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">4D</span> : <span>{props.currentSetting['4D'] && props.currentSetting['4D'].factor}</span>
                    </p>
                </div>
            </div>
            <div className="lottery-bet row">
                <div className="lottery-bet-info col-6">
                    <div className="lottery-label row">
                        <span className="col-4">Number</span>
                        <span className="col-4">Amount</span>
                        <span className="col-4">Net Amount</span>
                    </div>
                    {[...Array(numOfRow)].map((v, index) => (
                        <div
                            className="lottery-col row"
                            key={`lottery-row-${index}`}
                        >
                            <div className="col-4">
                                <input
                                    type="text"
                                    name={`num${index + 1}`}
                                    onChange={handleChange}
                                    maxLength="4"
                                />
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    name={`amount${index + 1}`}
                                    onChange={handleChange}
                                    maxLength="6"
                                />
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    name={`net-amount${index + 1}`}
                                    value={formData[`net-amount${index + 1}`] ? formData[`net-amount${index + 1}`] : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="lottery-bet-info col-6">
                    <div className="lottery-label row">
                        <span className="col-4">Number</span>
                        <span className="col-4">Amount</span>
                        <span className="col-4">Net Amount</span>
                    </div>
                    {[...Array(numOfRow)].map((v, index) => (
                        <div
                            className="lottery-col row"
                            key={`lottery-row-${index}`}
                        >
                            <div className="col-4">
                                <input
                                    type="text"
                                    name={`num${index + numOfRow + 1}`}
                                    onChange={handleChange}
                                    maxLength="4"
                                />
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    name={`amount${index + numOfRow + 1}`}
                                    onChange={handleChange}
                                    maxLength="6"
                                />
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    name={`net-amount${index + numOfRow + 1
                                        }`}
                                    value={formData[`net-amount${index + numOfRow + 1}`] ? formData[`net-amount${index + numOfRow + 1}`] : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="lottery-footer my-3 d-flex align-items-center px-3">
                <p className="me-2 mb-0">
                    <span className="me-2">Total Amount:</span>
                    <span>{parseFloat(totalAmt).toFixed(2)}</span>
                </p>
                <p className="me-2 mb-0">
                    <span className="me-2">Total Net Amount:</span>
                    <span>{parseFloat(totalNetAmt).toFixed(2)}</span>
                </p>
                <a href="#" onClick={(e) => handleSubmit(e)} className="lottery-submit mx-2 px-4">
                    Submit
                </a>
            </div>
        </>
    )
}

export default Lottery2D3D4D;