import { useState, useEffect } from "react";

const Alternate = (props) => {
    const numOfRow = 10;
    const [totalAmt, setTotalAmt] = useState(0);
    const [totalNetAmt, setTotalNetAmt] = useState(0);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        let currentTotalAmt = 0;
        for (const [key, value] of Object.entries(formData)) {
            let currentAmt = value === "" ? 0 : parseInt(value);
            if (key.includes("amount") && !key.includes("net")) {
                currentTotalAmt = currentTotalAmt + currentAmt;
            }
        }
        setTotalAmt(currentTotalAmt);
    }, [formData]);
    console.log(formData);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let netAmtArr = [];

        // Maybe change the input name format
        if (name.includes('amount')) {
            console.log(name)
            const netName = name.split('-');
            const nameNetAmt = 'net-' + netName[0];
            const amt = parseFloat(value);
            const lottType = value.length;
            const disc = props.currentSetting[props.lottName[lottType]].discount;
            let prevNetAmt = parseFloat(formData[nameNetAmt] ? formData[nameNetAmt] : 0);
            let netAmt = amt - (amt * disc / 100);
            netAmt = (netAmt + prevNetAmt).toFixed(2);
            netAmtArr = { [nameNetAmt]: netAmt };
        }
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
                ...netAmtArr
            };
        });
    };
    return (
        <>
            <div className="lottery-info d-flex align-items-center mt-2 p-3">
                <div className="lottery-desc me-2">2D3D4D</div>
                <div className="lottery-time me-auto d-flex align-items-center pt-1">
                    <p className="me-2 mb-0">
                        <span className="fw-bold">OPEN TIME</span> :
                        {props.currentLott.open_time}
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">CLOSE TIME</span> :
                        {props.currentLott.close_time}
                    </p>
                    <p className="mb-0">
                        <span className="fw-bold">PERIOD</span> :
                        {props.currentLott.period}
                    </p>
                </div>
                <div className="lottery-time d-flex align-items-center pt-1">
                    <p className="me-2 mb-0">
                        <span className="fw-bold">DISCOUNT</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">2D</span> : <span>{props.currentSetting['2D'] && props.currentSetting['2D'].discount}%</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">3D</span> : <span>{props.currentSetting['3D'] && props.currentSetting['3D'].discount}%</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">4D</span> : <span>{props.currentSetting['4D'] && props.currentSetting['4D'].discount}%</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">ODDS</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">2D</span> : <span>{props.currentSetting['2D'] && props.currentSetting['2D'].factor}</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">3D</span> : <span>{props.currentSetting['3D'] && props.currentSetting['3D'].factor}</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">4D</span> : <span>{props.currentSetting['4D'] && props.currentSetting['4D'].factor}</span>
                    </p>
                </div>
            </div>
            <div className="lottery-bet row">
                <div className="lottery-bet-info">
                    <div className="lottery-label row justify-content-between">
                        <span className="col-2">Number</span>
                        <span className="col-2">2D Amount</span>
                        <span className="col-2">3D Amount</span>
                        <span className="col-2">4D Amount</span>
                        <span className="col-2">Net Amount</span>
                    </div>
                    {[...Array(numOfRow)].map((v, index) => (
                        <div
                            className="lottery-col row justify-content-between"
                            key={`lottery-row-${index}`}
                        >
                            <div className="col-2">
                                <input
                                    type="text"
                                    name={`num${index + 1}`}
                                    onChange={handleChange}
                                    maxLength="4"
                                />
                            </div>
                            <div className="col-2">
                                <input
                                    type="text"
                                    name={`amount${index + 1}-2d`}
                                    onChange={handleChange}
                                    maxLength="6"
                                />
                            </div>
                            <div className="col-2">
                                <input
                                    type="text"
                                    name={`amount${index + 1}-3d`}
                                    onChange={handleChange}
                                    maxLength="6"
                                />
                            </div>
                            <div className="col-2">
                                <input
                                    type="text"
                                    name={`amount${index + 1}-4d`}
                                    onChange={handleChange}
                                    maxLength="6"
                                />
                            </div>
                            <div className="col-2">
                                <input
                                    type="text"
                                    name={`net-amount${index + 1}`}
                                    value={formData[`net-amount${index + 1}`] ? formData[`net-amount${index + 1}`] : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="lottery-footer my-3 d-flex align-items-center px-3">
                <p className="me-2 mb-0">
                    <span className="me-2">Total Amount:</span>
                    <span>{parseFloat(totalAmt).toFixed(2)}</span>
                </p>
                <p className="me-2 mb-0">
                    <span className="me-2">Total Net Amount:</span>
                    <span>{parseFloat(totalNetAmt).toFixed(2)}</span>
                </p>
                <a href="#" className="lottery-submit mx-2 px-4">
                    Submit
                </a>
            </div>
        </>
    )
}

export default Alternate;