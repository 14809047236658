import { useState, useEffect } from "react";
import { bankAccount, bindBank as bindBankInApi } from "../../../api"
import { Container, Col, Row, Form, Button, Spinner } from "react-bootstrap";
import MenuKg from "../../../components/menu/MenuKg";
import WithdrawMenu from "../withdraw/withdrawMenu/WithdrawMenuKg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BindBank = (props) => {
    const [formData, setFormData] = useState({
        tel: "",
        email: "",
        bank: "",
        bankAccName: "",
        bankAccNumber: "",
    });
    const [bank, setBank] = useState({});
    const [hideBtn, setHideBtn] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { id_mod_bank, bank_acc_name, bank_acc_no, tel, email } = JSON.parse(
        localStorage.getItem("token")
    );

    useEffect(() => {
        bankAccount(1).then((res) => setBank(res));

        if (!id_mod_bank || !bank_acc_name || !bank_acc_no || !tel || !email) {
            setHideBtn(true);
        }
    }, []);

    const bindBank = async (data, callBack) => {
        setLoading(true);
        const response = await bindBankInApi(data);
        const code = parseInt(response.msg);

        if (code === 1) {
            const bankInfo = response.data;
            const memberInfo = JSON.parse(localStorage.getItem("token"));
            for (const x in bankInfo) {
                if (bankInfo[x] && bankInfo[x] !== undefined) {
                    if (x === "bank_id") {
                        memberInfo["id_mod_bank"] = bankInfo[x];
                    }
                    memberInfo[x] = bankInfo[x];
                }
            }
            localStorage.setItem('token', JSON.stringify(memberInfo));

            toast.success(`${props.t('bind.Fix Bank successfully')}`);
            callBack();
        } else if (code === -2) {
            toast.error(`${props.t('default.Unauthorized access')}`);
        } else if (code === -7) {
            toast.error(`${props.t('bind.Bank account name has been used')}`);
        } else {
            toast.error(`${props.t('default.Request failed') + "-" + code}`);
        }

        setLoading(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { bank, bankAccName, bankAccNumber, email, tel } = event.target;

        if (bank && bank.value === "") {
            alert(`${props.t('bind.Please choose a bank')}`);
            bank.focus();
            return false;
        }
        if (bankAccName && bankAccName.value === "") {
            alert(`${props.t('bind.Please enter your bank account name')}`);
            bankAccName.focus();
            return false;
        }
        if (bankAccNumber && bankAccNumber.value === "") {
            alert(`${props.t('bind.Please enter your bank account number')}`);
            bankAccNumber.focus();
            return false;
        }
        if (email && email.value === "") {
            alert(`${props.t('bind.Please enter your valid email')}`);
            email.focus();
            return false;
        }
        if (tel && tel.value === "") {
            alert(`${props.t('bind.Please enter your valid phone number')}`);
            tel.focus();
            return false;
        }

        bindBank(formData, () => navigate("/withdraw", { replace: true }));
    };
    return (
        <>
            <Container className="container-lg member-info my-3 mb-5" fluid>
                <Row>
                    <Col lg={3}>
                        <MenuKg t={props.t} />
                    </Col>
                    <Col lg={9}>
                        <Row className="py-2 px-3">
                            <WithdrawMenu t={props.t} />
                            <Form className="mt-4" onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="bankaccount">
                                    <Row className="align-items-center">
                                        <Col xs={4} sm={3}>
                                            <Form.Label>
                                                {props.t('bind.Bank')}:
                                            </Form.Label>
                                        </Col>
                                        <Col xs={8} sm={9}>
                                            {
                                                id_mod_bank !== "" && id_mod_bank !== "0" ?
                                                    (
                                                        <Form.Select
                                                            aria-label="bank"
                                                            disabled="true"
                                                            value={id_mod_bank}
                                                        >
                                                            <option value="">{props.t('bind.Please choose a bank')}</option>
                                                            {
                                                                bank.length > 0 &&
                                                                bank.map((item, index) =>
                                                                    <option
                                                                        key={index}
                                                                        value={item.id_mod_bank}
                                                                    >
                                                                        {item.bank_name}
                                                                    </option>
                                                                )
                                                            }
                                                        </Form.Select>
                                                    ) : (
                                                        <Form.Select
                                                            aria-label="bank"
                                                            onChange={handleChange}
                                                            name="bank"
                                                        >
                                                            <option value="">{props.t('bind.Please choose a bank')}</option>
                                                            {
                                                                bank.length > 0 &&
                                                                bank.map((item, index) =>
                                                                    <option
                                                                        key={index}
                                                                        value={item.id_mod_bank}
                                                                    >
                                                                        {item.bank_name}
                                                                    </option>
                                                                )
                                                            }
                                                        </Form.Select>
                                                    )
                                            }

                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col xs={4} sm={3}>
                                            <Form.Label>
                                                {props.t('bind.Bank Account Name')}:
                                            </Form.Label>
                                        </Col>
                                        <Col xs={8} sm={9}>
                                            <Form.Control type="text" name="bankAccName" onChange={handleChange} defaultValue={bank_acc_name} readOnly={bank_acc_name !== ""} />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col xs={4} sm={3}>
                                            <Form.Label>
                                                {props.t('bind.Bank Account Number')}:
                                            </Form.Label>
                                        </Col>
                                        <Col xs={8} sm={9}>
                                            <Form.Control type="number" name="bankAccNumber" onChange={handleChange} defaultValue={bank_acc_no} readOnly={bank_acc_no !== ""} />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col xs={4} sm={3}>
                                            <Form.Label>
                                                {props.t('bind.Email')}:
                                            </Form.Label>
                                        </Col>
                                        <Col xs={8} sm={9}>
                                            <Form.Control type="text" name="email" onChange={handleChange} defaultValue={email} readOnly={email !== ""} />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col xs={4} sm={3}>
                                            <Form.Label>
                                                {props.t('bind.Tel')}:
                                            </Form.Label>
                                        </Col>
                                        <Col xs={8} sm={9}>
                                            <Form.Control type="number" name="tel" onChange={handleChange} defaultValue={tel} readOnly={tel !== ""} />
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col xs={4} sm={3}>
                                            <Form.Label></Form.Label>
                                        </Col>
                                        <Col xs={8} sm={9}>
                                            <Button
                                                type="submit"
                                                variant="light"
                                                className="btn btn-red-gradient px-4 btn-shadow"
                                                disabled={loading || !hideBtn}
                                            >
                                                {loading ? (
                                                    <Spinner
                                                        variant="standard"
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    props.t('bind.Submit')
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default BindBank;