import { Container, Card, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { getPromotion as getPromotionInApi, applyPromotion as applyPromotionInApi } from "../../api";

function CenterModal(props) {
    const { data } = props
    return (
        <Modal
            {...props}
            size="lg"
            centered
            variant="dark"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {data.promosi_title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    dangerouslySetInnerHTML={{ __html: data.promosi_content }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" className="bg-primary" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const Promotion = () => {
    const [list, setList] = useState([]);
    const [modalId, setModalId] = useState(false);
    const handleClose = () => setModalId("");

    const handleSubmit = (promo) => {
        const applyPromotion = async () => {
            const response = await applyPromotionInApi(promo);
            if (response.code === "1") {
                toast.success("Claimed successfully")
            }
        }
        applyPromotion()
    }
    useEffect(() => {
        const getPromotion = async () => {
            const promo = await getPromotionInApi()
            if (promo.code === "1") {
                setList(promo.list)
            }
        }
        getPromotion()
    }, []);

    return (
        <>
            <Container>
                <div className="promo-box">
                    <div className="promo-list d-flex flex-wrap mt-4 justify-content-center">
                        {
                            list.map((p) => (
                                <div key={`p-${p.id_mod_promotions_design}`}>
                                    <Card className="promo-list-item" bg="dark" text="white">
                                        <Card.Img variant="top" src={`${p.promosi_image1}`} style={{ minHeight: "10rem" }} />
                                        <Card.Body>
                                            <Card.Title>{`${p.promosi_title}`}</Card.Title>
                                            <Card.Text>
                                            </Card.Text>
                                            <Button variant="light" className="bg-primary me-2" onClick={() => setModalId(`modal-p-${p.id_mod_promotions_design}`)}>More Info</Button>
                                            <Button variant="light" className="bg-primary" onClick={() => handleSubmit(`${p.promosi_code}`)}>Claim</Button>
                                        </Card.Body>
                                    </Card>
                                    <CenterModal
                                        aria-labelledby={`modal-p-${p.id_mod_promotions_design}`}
                                        show={modalId === `modal-p-${p.id_mod_promotions_design}`}
                                        onHide={handleClose}
                                        data={p}
                                        keyboard={true}
                                    />
                                </div>
                            ))
                        }
                        {
                            list.length % 3 !== 0 && <><div className="promo-list-item"></div><div className="promo-list-item"></div></>
                        }
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Promotion