import { Link } from "react-router-dom";
const BonusMenu = (props) => {
    return (
        <div className="d-flex">
            <Link className="text-white me-2" to="/bonus">
                {props.t("bonus.Bonus")}
            </Link>
            <Link className="text-white me-2" to="/referral-list">
                {props.t("bonus.Referral List")}
            </Link>
            <Link className="text-white me-2" to="/referral-bonus">
                {props.t("bonus.Referral Bonus")}
            </Link>
            <Link className="text-white me-2" to="/referral-bonus-record">
                {props.t("bonus.Referral Bonus Record")}
            </Link>
            <Link className="text-white me-2" to="/promo-record">
                {props.t("bonus.Promotion Record")}
            </Link>
            <Link className="text-white" to="/auto-promo-record">
                {props.t("bonus.Auto Promotion Record")}
            </Link>
        </div>
    );
};

export default BonusMenu;
