const Lottery2dFront = (props) => {
    return (
        <>
            <div className="lottery-info d-flex align-items-center mt-2 p-3">
                <div className="lottery-desc me-auto">2D FRONT</div>
                <div className="lottery-time d-flex align-items-center pt-1">
                    <p className="me-2 mb-0">
                        <span className="fw-bold">DISCOUNT</span> : <span>{props.currentSetting['2D Front'] && props.currentSetting['2D Front'].discount}%</span>
                    </p>
                    <p className="mb-0">
                        <span className="fw-bold">ODDS</span> : <span>{props.currentSetting['2D Front'] && props.currentSetting['2D Front'].factor}</span>
                    </p>
                </div>
            </div>
            <div className="lottery-bet d-flex">
                <div className="lottery-bet-info w-100">
                    <textarea name="" id="" rows="25" className="w-100 mt-2"></textarea>
                </div>
            </div>
            <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3 bg-light p-3">
                <p className="text-black">Number1 * Number2 * Number3 # Amount+Number1 * Number2 * Number3 # Amount Or Number1XAmount Number2XAmount</p>
                <p className="text-black mb-0">Ex: 12*23*34#5+45*56*78#10 Or 12X5 23X5 34X5</p>
            </div>
            <div className="lottery-footer my-3 d-flex align-items-center px-3">
                <p className="me-2 mb-0">
                    <span className="me-2">Total Amount:</span>
                    <span>{ }</span>
                </p>
                <p className="me-2 mb-0">
                    <span className="me-2">Total Net Amount:</span>
                    <span>{ }</span>
                </p>
                <a href="#" className="lottery-submit mx-2 px-4">
                    Submit
                </a>
            </div>
        </>
    )
}

export default Lottery2dFront;