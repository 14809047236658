import { Link } from "react-router-dom";
const LotteryMenu = (props) => {
    return (
        <div className="lottery-menu">
            {
                props.lotteryInfo.map((lott, index) =>
                    <Link
                        key={`lottery-menu-${lott.type2}`}
                        to={`/lottery/${lott.type2}`}
                        className={
                            parseInt(lott.type2) === parseInt(props.currentId) ||
                            (index <=0 && !props.currentId)
                            ? 'active' : ''
                        }
                        >
                        {lott.game_name}
                    </Link>
                )
            }
        </div >
    )
}

export default LotteryMenu;