import { useState } from "react";
import { Container, Row, Form, Button, Spinner, Table } from "react-bootstrap";
import {
    bonus as bonusInApi,
    bonusRecord as bonusRecordInApi,
} from "../../../../api";
import { toast } from "react-toastify";
import MemberNav from "../../MemberNav";
import BonusMenu from "../bonusMenu/BonusMenu";
import { useEffect } from "react";

const Bonus = (props) => {
    const [formData, setFormData] = useState({
        bonusType: "",
        amount: "",
    });
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState([]);
    const [recordStatus, setRecordStatus] = useState(1);

    useEffect(() => {
        const bonusRecord = async () => {
            const response = await bonusRecordInApi();
            if (response.data) {
                setRecord(response.data);
            }
            console.log(response);
        };
        bonusRecord();
    }, [recordStatus]);
    const bonus = async (data) => {
        setLoading(true);
        const response = await bonusInApi(data);
        if (response.code) {
            toast.success(`${props.t("t.You have claimed your bonus")}`);
        }
        setLoading(false);
    };
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setRecordStatus((prev) => prev + 1);
        if (!formData.bonusType) {
            alert(`${props.t("bonus.Please choose a bonus type")}`);
            return false;
        } else if (formData.amount <= 0) {
            alert(`${props.t("bonus.Amount cannot be empty")}`);
            return false;
        }
        bonus(formData);
    };

    return (
        <>
            <Container className="password-form d-flex">
                <MemberNav t={props.t} />
                <Row className="py-2 flex-grow-1 d-flex flex-column">
                    <BonusMenu t={props.t} />
                    <Form className="mt-4">
                        <Form.Group
                            className="mb-3 d-flex align-items-center"
                            controlId="bankaccount"
                        >
                            <Form.Label column sm="3" className="text-white">
                                {props.t("bonus.Bonus Type")}:
                            </Form.Label>
                            <Form.Select
                                aria-label="bank"
                                onChange={(e) => handleChange(e)}
                                name="bonusType"
                            >
                                <option>
                                    {props.t(
                                        "bonus.Please choose a bonus type"
                                    )}
                                </option>
                                <option value="5">
                                    {props.t("bonus.Transfer Bonus")}
                                </option>
                                <option value="4">
                                    {props.t("bonus.Withdraw Bonus")}
                                </option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group
                            className="mb-3 d-flex align-items-center"
                            controlId="amount"
                        >
                            <Form.Label column sm="3" className="text-white">
                                {props.t("bonus.Amount")}:
                            </Form.Label>
                            <Form.Control
                                type="number"
                                name="amount"
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex">
                            <Form.Label
                                column
                                sm="3"
                                className="text-white"
                            ></Form.Label>
                            <Button
                                type="submit"
                                variant="light"
                                className="btn bg-primary"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? (
                                    <Spinner
                                        variant="standard"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    props.t("bonus.Submit")
                                )}
                            </Button>
                        </Form.Group>
                    </Form>
                    <Table striped bordered hover variant="light">
                        <thead>
                            <tr>
                                <th>{props.t("bonus.No.")}</th>
                                <th>{props.t("bonus.Type")}</th>
                                <th>{props.t("bonus.Time")}</th>
                                <th>{props.t("bonus.Amount")}</th>
                                <th>{props.t("bonus.Category")}</th>
                                <th>{props.t("bonus.Status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <Spinner />
                            ) : record.length > 0 ? (
                                record.map((rec, index) => (
                                    <tr key={`d-record-${index}`}>
                                        <td>{index + 1}</td>
                                        <td>Bonus</td>
                                        <td>{rec.time}</td>
                                        <td>
                                            {parseFloat(rec.amount).toFixed(2)}
                                        </td>
                                        <td>{rec.bonus_type}</td>
                                        <td>{rec.status}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        {props.t("bonus.No record found")}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    );
};

export default Bonus;
