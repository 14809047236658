import TableStatement from "../../../components/table/TableStament";
import { lotteryResult as lotteryResultFromApi } from "../../../api";
import { useState, useEffect } from "react";

const Result = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const statement = async () => {
            setLoading(true);
            const response = await lotteryResultFromApi(props.currentLott.type2);
            if (response) {
                let responseData = response.data;
                console.log(response)
                if (response.data.report) {
                    responseData = response.data.report
                }
                setRecord(responseData);
            }

            setLoading(false);
        };
        statement();
    }, [props.currentLott.type2])
    return (
        <>
            <div className="lottery-bet d-flex mt-2">
                <TableStatement loading={loading} data={record} currentLott={props.currentLott} provider="Lottery Result" t={props.t} />
            </div>
        </>
    )
}

export default Result;