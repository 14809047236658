import { Link } from "react-router-dom"
const DepositMenu = (props) => {

    return (
        <div className="d-flex mb-2">
            <Link className="text-white me-2" to="/deposit">{props.t('deposit.Deposit')}</Link>
            <Link className="text-white" to="/deposit-record">{props.t('deposit.Deposit List')}</Link>
        </div>
    )
}

export default DepositMenu