import { gameSwitch as gameSwitchInApi } from "../../../api";
import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sport = (props) => {
	const [games, setGames] = useState([]);

	useEffect(() => {
		const gameInfo = async () => {
			const response = await gameSwitchInApi("Sport");
			//console.log(response)

			setGames(response);
		};
		gameInfo();
	}, []);

	return (
		<>
			<Container>
				<Row className="box">
					{
						games.length > 0 &&
						games.map((g, index) =>
							<Col key={`sport-${index}`} sm={4} className="position-relative">
								<div className="box-item">
									<div className="box-item-top">
										<img
											className="box-bg"
											src={`../../images/design1/game/sport/sport-bg.jpg`}
											alt={`sport-bg-${index}`}
										/>
										<img
											className="box-logo"
											src={`../../images/design1/game/sport/${g.img}-logo.png`}
											alt={`sport-logo-${g.img}-${index}`}
										/>
										<img
											className="box-logo-img"
											src={`../../images/design1/game/sport/${g.img}.png`}
											alt={`sport-${g.img}-${index}`}
										/>
									</div>
									<div className="box-item-bottom d-flex align-items-center justify-content-between px-3 py-1">
										<span className="text-white text-uppercase">{g.name}</span>
										<Link to={`/games/${g.url}`} className="btn">
											{props.t('game.Play Now')}
										</Link>
									</div>
								</div>
							</Col>
						)
					}
				</Row>
			</Container>
		</>
	);
};

export default Sport;
