import { Button, Form, Col, Row } from "react-bootstrap"
import moment from "moment"
import { useEffect } from "react";

const DateKg = (props) => {
    const formData = props.data;

    const today = moment().format('YYYY-MM-DD');
    const startDate = formData.startDate ? formData.startDate : today + "T00:00";
    const endDate = formData.endDate ? formData.endDate : today + "T23:59";

    // When start update the formData
    useEffect(() => {
        props.selectDate({ startDate: startDate, endDate: endDate })
    }, [])

    const changeDate = (e) => {
        const dateType = e.target.getAttribute('date-type')
        let startDate = ""
        let endDate = ""
        switch (dateType) {
            case "today":
                startDate = moment().format('YYYY-MM-DD');
                endDate = moment().format('YYYY-MM-DD');
                break;
            case "yesterday":
                const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
                startDate = yesterday;
                endDate = yesterday;
                break;
            case "this week":
                startDate = moment().startOf('weeks').add(1, 'days').format('YYYY-MM-DD')
                endDate = moment().endOf('weeks').add(1, 'days').format('YYYY-MM-DD')
                break;
            case "last week":
                startDate = moment().subtract(1, 'weeks').startOf('weeks').add(1, 'days').format('YYYY-MM-DD')
                endDate = moment().subtract(1, 'weeks').endOf('weeks').add(1, 'days').format('YYYY-MM-DD')
                break;
            case "this month":
                startDate = moment().startOf('months').format('YYYY-MM-DD')
                endDate = moment().endOf('months').format('YYYY-MM-DD')
                break;
            case "last month":
                startDate = moment().subtract(1, 'months').startOf('months').format('YYYY-MM-DD')
                endDate = moment().subtract(1, 'months').endOf('months').format('YYYY-MM-DD')
                break;
            default:
                break;
        }

        startDate = startDate + "T00:00";
        endDate = endDate + "T23:59";

        props.selectDate({ startDate: startDate, endDate: endDate })
    }
    return (
        <div className="d-flex flex-column">
            <Row className="mb-3 gx-2">
                <Col className="mb-3">
                    <Button onClick={(e) => changeDate(e)} date-type="today" className="btn btn-red-gradient w-100" variant="light">{props.t('date.Today')}</Button>
                </Col>
                <Col className="mb-3">
                    <Button onClick={(e) => changeDate(e)} date-type="yesterday" className="btn btn-red-gradient w-100" variant="light">{props.t('date.Yesterday')}</Button>
                </Col>
                <Col className="mb-3">
                    <Button onClick={(e) => changeDate(e)} date-type="this week" className="btn btn-red-gradient w-100" variant="light">{props.t('date.This Week')}</Button>
                </Col>
                <Col className="mb-3">
                    <Button onClick={(e) => changeDate(e)} date-type="last week" className="btn btn-red-gradient w-100" variant="light">{props.t('date.Last Week')}</Button>
                </Col>
                <Col className="mb-3">
                    <Button onClick={(e) => changeDate(e)} date-type="this month" className="btn btn-red-gradient w-100" variant="light">{props.t('date.This Month')}</Button>
                </Col>
                <Col className="mb-3">
                    <Button onClick={(e) => changeDate(e)} date-type="last month" className="btn btn-red-gradient w-100" variant="light">{props.t('date.Last Month')}</Button>
                </Col>

            </Row>
            <Row>
                <Col lg={4} className="d-flex flex-column">
                    <Form.Label>{props.t('date.Start Date')}:</Form.Label>
                    <Form.Control
                        type="datetime-local"
                        name="startDate"
                        placeholder="Start Date"
                        onChange={(e) => props.handleChange(e)}
                        value={startDate}
                        key="startDate-1"
                        className="w-100"
                    />
                </Col>
                <Col lg={4} className="d-flex flex-column">
                    <Form.Label>{props.t('date.End Date')}:</Form.Label>
                    <Form.Control
                        type="datetime-local"
                        name="endDate"
                        placeholder="End Date"
                        onChange={(e) => props.handleChange(e)}
                        value={endDate}
                        key="endDate-1"
                        className="w-100"
                    />
                </Col>
            </Row>
        </div >
    )
}

export default DateKg