import { useEffect, useState, useRef } from "react";
import { Container, Row, Form, Button, Spinner } from "react-bootstrap";
import { bankAccount, deposit as depositInApi } from "../../../api"
import { toast } from "react-toastify";
import MemberNav from "../MemberNav";
import DepositMenu from "./depositMenu/DeposiMenu";

function Deposit(props) {
	const [formData, setFormData] = useState({
		bankaccount: "",
		amount: "",
	});
	const [bank, setBank] = useState({});
	const [loading, setLoading] = useState(false);

	const myRef = useRef([]);

	useEffect(() => {
		bankAccount().then((res) => setBank(res));
	}, []);

	const deposit = async (data) => {
		setLoading(true);
		const response = await depositInApi(data);
		const code = parseInt(response.code);
		if (code === 1) {
			toast.success(`${props.t('deposit.Deposit successfully')}`);
		} else if (code === -2) {
			toast.error(`${props.t('default.Unauthorized access')}`);
		} else {
			toast.error(`${props.t('default.Request failed') + "-" + code}`);
		}
		setLoading(false);
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormData((prevFormData) => {
			return {
				...prevFormData,
				[name]: type === "checkbox" ? checked : value,
			};
		});
	};
	const changeBank = (event) => {
		handleChange(event);
		const bankInfo = event.target[event.target.selectedIndex]
			.getAttribute("data-id")
			.split("#");
		myRef.current.bankAccName.value = bankInfo[0];
		myRef.current.bankAccNum.value = bankInfo[1];
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const { bankaccount, amount } = event.target;
		const min = parseFloat(bankaccount[bankaccount.selectedIndex].getAttribute("data-min"));
		const max = parseFloat(bankaccount[bankaccount.selectedIndex].getAttribute("data-max"));
		console.log(amount.value, max);
		if (bankaccount.value === "") {
			alert(`${props.t('deposit.Please choose a bank')}`);
			bankaccount.focus();
			return false;
		}
		if (amount.value === "") {
			alert(`${props.t('deposit.Amount cannot be empty')}`);
			amount.focus();
			return false;
		}

		if (parseFloat(amount.value) < min) {
			alert(`${props.t('deposit.Amount must be more than') + " " + min}`);
			amount.focus();
			return false;
		}

		if (parseFloat(amount.value) > max) {
			alert(`${props.t('deposit.Amount cannot more than') + " " + max}`);
			amount.focus();
			return false;
		}

		deposit(formData);
	};

	//console.log(bank);
	return (
		<>
			<Container className="deposit-form d-flex">
				<MemberNav t={props.t} />
				<Row className="py-2 flex-grow-1 d-flex flex-column">
					<DepositMenu t={props.t} />
					<Form className="mt-4" onSubmit={handleSubmit}>
						<Form.Group
							className="mb-3 d-flex align-items-center"
							controlId="bankaccount"
						>
							<Form.Label column sm="3" className="text-white">
								{props.t('deposit.Bank')}:
							</Form.Label>
							<Form.Select
								aria-label="bank"
								onChange={changeBank}
								name="bankaccount"
							>
								<option value="">{props.t('deposit.Please choose a bank')}</option>
								{
									bank.length > 0 &&
									bank.map((item, index) =>
										<option
											key={index}
											value={item.id_mod_bank_account}
											data-id={item.account + "#" + item.no}
											data-min={item.min_deposit}
											data-max={item.max_deposit}
										>
											{item.bank_name}
										</option>
									)
								}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3 d-flex align-items-center">
							<Form.Label column sm="3" className="text-white">
								{props.t('deposit.Bank Account Name')}:
							</Form.Label>
							<Form.Control
								type="text"
								ref={(element) => {
									myRef.current["bankAccName"] = element;
								}}
								readOnly
							/>
						</Form.Group>
						<Form.Group className="mb-3 d-flex align-items-center">
							<Form.Label column sm="3" className="text-white">
								{props.t('deposit.Bank Account Number')}:
							</Form.Label>
							<Form.Control
								type="number"
								ref={(element) => {
									myRef.current["bankAccNum"] = element;
								}}
								readOnly
							/>
						</Form.Group>
						<Form.Group
							className="mb-3 d-flex align-items-center"
							controlId="amount"
						>
							<Form.Label column sm="3" className="text-white">
								{props.t('deposit.Amount')}:
							</Form.Label>
							<Form.Control
								type="number"
								onChange={handleChange}
								name="amount"
							/>
						</Form.Group>
						<Form.Group className="mb-3 d-flex align-items-center">
							<Form.Label column sm="3" className="text-white"></Form.Label>
							<Form.Control type="file" />
						</Form.Group>
						<Form.Group className="mb-3 d-flex">
							<Form.Label column sm="3" className="text-white"></Form.Label>
							<Button
								type="submit"
								variant="light"
								className="btn bg-primary"
								disabled={loading}
							>
								{loading ? (
									<Spinner
										variant="standard"
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									props.t('deposit.Submit')
								)}
							</Button>
						</Form.Group>
					</Form>
				</Row>
			</Container>
		</>
	);
}

export default Deposit;
