import { getSlot as getSlotInApi } from "../../api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

const SlotGame = () => {
  const [slot, setSlot] = useState([]);
  const { provider } = useParams();

  useEffect(() => {
    if (provider) {
      const getSlot = async () => {
        const response = await getSlotInApi(provider);
        setSlot(response);
      };
      getSlot();
    }

  }, [provider]);

  const openNewWindow = (url) => {
    window.open(url, 'slotWindow', 'width=1200,height=800');
  }

  return (
    <>
      <Container>
        <div className="d-flex flex-wrap mt-2 justify-content-center">
          {slot.map((s, index) => (
            <div key={`s-${index}`} onClick={() => openNewWindow(s.url)} className="text-decoration-none m-1" style={{ width: '16%', border: '1px solid rgb(255, 255, 255)' }}>
              <div
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <img
                  src={`${s.imageUrl}`}
                  alt={s.gname_en}
                  style={{ width: "100%", height: "11rem" }}
                />
                <span title={s.gname_en} className="bg-primary py-2 w-100 text-center" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                  {s.gname_en}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default SlotGame;
