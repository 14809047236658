import { useState, useEffect } from "react";
import { Container, Row, Form, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { bankAccount, withdraw as withdrawInApi } from "../../../api"
import { toast } from "react-toastify";
import MemberNav from "../MemberNav";
import WithdrawMenu from "./withdrawMenu/WithdrawMenu";

function Withdraw(props) {
	const [formData, setFormData] = useState({
		bankaccount: "",
		amount: "",
		min: 0,
		max: 0,
	});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const { id_mod_bank, bank_name, bank_acc_name, bank_acc_no } = JSON.parse(
		localStorage.getItem("token")
	);

	useEffect(() => {
		bankAccount()
			.then((res) =>
				res.forEach(bank => {
					if (bank.id_mod_bank === id_mod_bank) {
						setFormData(prev => {
							return { ...prev, min: bank.min_withdraw, max: bank.max_withdraw }
						});
					}
				}
				));
	}, []);

	const withdraw = async (data) => {
		setLoading(true);
		const response = await withdrawInApi(data);

		const code = parseInt(response.code);
		if (code === 1) {
			toast.success(`${props.t('with.Withdraw successfully')}`);
		} else if (code === -2) {
			toast.error(`${props.t('default.Unauthorized access')}`);
		} else {
			toast.error(`${props.t('default.Request failed') + "-" + code}`);
		}

		setLoading(false);
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormData((prevFormData) => {
			return {
				...prevFormData,
				[name]: type === "checkbox" ? checked : value,
			};
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const { amount } = event.target;

		if (amount.value === "") {
			alert(`${props.t('with.Amount cannot be empty')}`);
			amount.focus();
			return false;
		}

		if (parseFloat(amount.value) < formData.min) {
			alert(`${props.t('with.Amount must be more than') + " " + formData.min}`);
			amount.focus();
			return false;
		}

		if (parseFloat(amount.value) > formData.max) {
			alert(`${props.t('with.Amount cannot more than') + " " + formData.max}`);
			amount.focus();
			return false;
		}

		withdraw(formData, () => navigate("/withdraw", { replace: true }));
	};
	//console.log(formData);
	return (
		<>
			<Container className="withdraw-form d-flex">
				<MemberNav t={props.t} />
				<Row className="py-2 flex-grow-1 d-flex flex-column">
					<WithdrawMenu t={props.t} />
					<Form className="mt-4" onSubmit={handleSubmit}>
						<Form.Group
							className="mb-3 d-flex align-items-center"
							controlId="bankaccount"
						>
							<Form.Label column sm="3" className="text-white">
								{props.t('with.Bank')}:
							</Form.Label>
							<Form.Control type="text" value={bank_name} readOnly />
						</Form.Group>
						<Form.Group className="mb-3 d-flex align-items-center">
							<Form.Label column sm="3" className="text-white">
								{props.t('with.Bank Account Name')}:
							</Form.Label>
							<Form.Control type="text" value={bank_acc_name} readOnly />
						</Form.Group>
						<Form.Group className="mb-3 d-flex align-items-center">
							<Form.Label column sm="3" className="text-white">
								{props.t('with.Bank Account Number')}:
							</Form.Label>
							<Form.Control type="number" value={bank_acc_no} readOnly />
						</Form.Group>
						<Form.Group
							className="mb-3 d-flex align-items-center"
							controlId="amount"
						>
							<Form.Label column sm="3" className="text-white">
								{props.t('with.Amount')}:
							</Form.Label>
							<Form.Control
								type="number"
								onChange={handleChange}
								name="amount"
							/>
						</Form.Group>
						<Form.Group className="mb-3 d-flex">
							<Form.Label column sm="3" className="text-white"></Form.Label>
							<Button
								type="submit"
								variant="light"
								className="btn bg-primary"
								disabled={loading}
							>
								{loading ? (
									<Spinner
										variant="standard"
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									props.t('with.Submit')
								)}
							</Button>
						</Form.Group>
					</Form>
				</Row>
			</Container>
		</>
	);
}

export default Withdraw;
