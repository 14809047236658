import { Table, Spinner } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";

const TableStatement = (props) => {
    const tableBody = (provider) => {
        let headerName = ['No.', 'Game', 'Game ID', 'Bet Time', 'Bet Amount', 'WinLoss']
        let tbodyName = ['gameid', 'trs_id', 'bet_time', 'bet_amount', 'result_win_loss']
        switch (provider) {
            case "WM Casino":
                headerName = ['No.', 'Game', 'Bet Detail', 'Bet Time', 'Bet Amount', 'WinLoss', 'Result', 'Game ID'];
                break;
            case "CQ9 Slots":
            case "KA Gaming":
                tbodyName = ['game_id', 'bet_id', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "ICG Slots":
                tbodyName = ['game_id', 'transaction_id', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "PG Slots":
                tbodyName = ['gameid', 'bet_id', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "FC Slots":
                tbodyName = ['game_id', 'round_id', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "BestGamer":
                headerName = ['No.', 'Game', 'Pool', 'Bet Time', 'Type', 'WinLoss']
                tbodyName = ['game', 'pool', 'bet_time', 'bet_type', 'bet_amount']
                break
            case "PlayTech Slots":
                headerName = ['No.', 'Game', 'Pool', 'Game ID', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['bet_type', 'gamename', 'gameid', 'gamedate', 'bet', 'win_loss']
                break
            case "W88 Slots":
            case "W88 Casino":
            case "MICRO GAMING":
            case "Netent Slots":
                headerName = ['No.', 'Game', 'Pool', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game', 'pool', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "Haba Slots":
                headerName = ['No.', 'Bet Type', 'Game', 'Game ID', 'Bet Time', 'WinLoss']
                tbodyName = ['trs_type', 'gamename', 'game_no', 'bet_time', 'winorloss']
                break
            case "Joker123":
                headerName = ['No.', 'Game ID', 'TRS ID', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game_id', 'trs_id', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "Leap Gaming":
            case "Rich88":
            case "SA Gaming":
            case "SAgaming Slots":
                headerName = ['No.', 'Game Type', 'Game ID', 'Bet ID', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game_type', 'game_id', 'bet_id', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "EVO Gaming":
                headerName = ['No.', 'Game', 'Bet Detail', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game_type', 'table_id', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "DG99 Casino":
                headerName = ['No.', 'Game', 'Bet Detail', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game_name', 'tableName', 'bet_time', 'bet_amount', 'winloss']
                break
            case "N2live Casino":
                headerName = ['No.', 'Game', 'Bet Detail', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game_type', 'bet_detail', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "MIKI":
            case "HG Gaming":
            case "Sexy Casino":
            case "Horse Race":
                headerName = ['No.', 'Game', 'Result', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game_type', 'result', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "HongBo Casino":
                headerName = ['No.', 'Bet ID', 'Bet Detail', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['bet_id', 'table_id', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "AFB Casino":
                headerName = ['No.', 'Game', 'Bet ID', 'Bet Detail', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game_type', 'bet_id', 'bet_detail', 'bet_time', 'bet_amount', 'member_win_loss']
                break
            case "SBO Sports":
            case "IBC Sports":
            case "AFB2 Sports":
            case "AFB Sports":
                headerName = ['No.', 'Game', 'Bet Detail', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['sport_type', 'game_id', 'bet_time', 'bet_amount', 'winloss']
                break
            case "TF ESports":
                headerName = ['No.', 'Game', 'Bet Type', 'Bet Detail', 'Odds', 'Bet On', 'Bet Time', 'Bet Amount', 'WinLoss']
                tbodyName = ['game_type', 'bet_type', 'event_tf', 'odds_style', 'bet_on', 'bet_time', 'bet_amount', 'win_loss']
                break
            case "Lottery Result":
                headerName = ['No.', 'Game', 'Pool', 'Game ID', 'Open Time', 'Result']
                tbodyName = ['gameLottery', 'poolLottery', 'period', 'open_time', 'result']
                break
            case "Khmergaming":
                headerName = ['No.', 'Game', 'Pool', 'Type', 'Number', 'Rate', 'Discount', 'Bet Amount', 'Game ID', 'Bet Time']
                tbodyName = ['game', 'pool', 'type', 'number', 'factor', 'discount', 'bet_amount', 'period', 'bet_time']
                break
            case "Message":
                headerName = ['No.', 'From', 'Subject', 'Content', 'Date Time', 'Status']
                tbodyName = ['from_name', 'caption', 'content', 'date_time', 'status']
            default:
                break;

        }
        const tableHeader = headerName.map((name, index) => {
            return <th key={`table-header-${index}`}>{props.t('table.' + name)}</th>
        })
        return [tableHeader, tbodyName]
    }
    const tableBodyArr = tableBody(props.provider);
    const tHeader = tableBodyArr[0]
    const tBody = tableBodyArr[1]

    return (
        <Table striped bordered hover variant="light">
            <thead>
                <tr>
                    {tHeader}
                </tr>
            </thead>
            <tbody>
                {props.loading ?
                    <tr>
                        <td colSpan={tHeader.length}>
                            <Spinner
                                className="d-block mx-auto"
                                variant="standard"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true" />
                        </td>
                    </tr> :
                    props.data.length > 0 ?
                        props.data.map((rec, dataIndex) => (
                            <tr key={`statement-record-${dataIndex}`}>
                                <td>{dataIndex + 1}</td>
                                {
                                    tBody.map((val, bodyIndex) => {
                                        let displayVal = rec[val];
                                        let styleVal = ""
                                        if (val === 'bet_amount' || val.includes('win')) {
                                            displayVal = displayVal
                                                ? parseFloat(displayVal).toFixed(2)
                                                : "0.00"
                                            styleVal = displayVal >= 0 ? 'text-success' : 'text-danger'
                                        }
                                        if (val === 'sport_type' && (displayVal <= 1 || !displayVal)) {
                                            displayVal = props.provider
                                        } else if (val === 'event_tf') {
                                            const match = rec['match_name'].split("#")
                                            const betOn = rec['bet_on'].split("#")
                                            const leagueName = rec['league_name'].split("#")
                                            const betType = rec['bet_type']
                                            let betStr = ""
                                            if (betType === 'MixParlay') {
                                                match.forEach((m, index) => {
                                                    let team = m.split("vs")
                                                    if (betOn[index] === 'home') {
                                                        betStr = team[0]
                                                    } else if (betOn[index] === 'away') {
                                                        betStr = team[1]
                                                    } else {
                                                        betStr = betOn[index]
                                                    }
                                                    displayVal = leagueName[index] + "<br>" + match[index] + "<br>@" + betStr
                                                })
                                            } else {
                                                let team = match
                                                if (rec['bet_on'] === 'home') {
                                                    betStr = team[0]
                                                } else if (rec['bet_on'] === 'away') {
                                                    betStr = team[1]
                                                } else {
                                                    betStr = rec['bet_on']
                                                }
                                                displayVal = rec['league_name'] + "<br>" + rec['match_name'] + "<br>@" + betStr
                                            }
                                        } else if (val === 'gameLottery') {
                                            displayVal = "Lottery"
                                        } else if (val === 'poolLottery') {
                                            displayVal = props.currentLott.game_name
                                        } else if (props.provider === 'Message') {
                                            if (val === 'status') {
                                                const status = rec[val];
                                                displayVal = parseInt(status) === 1 ? "Read" : "Unread";
                                            } else if (val === 'content') {
                                                displayVal = HTMLReactParser(rec[val]);
                                            }

                                        }

                                        return (
                                            props.provider === 'Message' && val === 'status' ? (
                                                <td key={`td-${bodyIndex}`} className={styleVal} onClick={() => props.popUpModal(props.data[dataIndex].id_mod_msg_member)}>
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: displayVal }}
                                                    />
                                                </td>
                                            ) : (
                                                <td key={`td-${bodyIndex}`} className={styleVal} >
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: displayVal }}
                                                    />
                                                </td>
                                            )
                                        )
                                    })
                                }
                            </tr>
                        )) : <tr><td colSpan={tHeader.length} className="text-center">{props.t('table.No record found')}</td></tr>}
            </tbody>
        </Table >
    )
}

export default TableStatement
