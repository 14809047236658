import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { countryInfo } from "../../config";
import {
    fileDir as fileDirInConfig
} from "../../config";

const FileDir = fileDirInConfig('./components/header/Header');

const Header = (props) => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);

    const signInBtn = async (username, password) => {
        const response = await auth.signIn(username, password, () => navigate("/", { replace: true }));
        if (response === -1) {
            alert(props.t('login.User has been suspended'));
        } else if (response === -2 || response === -3) {
            alert(props.t('login.Username or password is incorrect'));
        } else if (response === -3) {
            alert(props.t('login.You have attempted to login too many times. Please try again in 1 minute'));
        }
    };
    const signOutBtn = () => {
        auth.signOut(() => navigate("/", { replace: true }));
    };

    const country = countryInfo(['gb', 'kh', 'vn', 'th', 'id', 'my', 'tw', 'hk', 'cn', 'ph', 'in', 'mm']);


    return (
        <FileDir t={props.t} changeLanguage={props.changeLanguage} auth={auth} signInBtn={signInBtn} signOutBtn={signOutBtn} country={country} />
    )

}

export default Header;