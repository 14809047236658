import { toast } from "react-toastify";
import axios from "axios";
import {
	slotInfo as slotInfoInConfig,
	gameInfo as gameInfoInConfig,
} from "./config";

const encodeFormData = (data) => {
	const webId = localStorage.getItem("webId");
	const newData = { ...data, web_id: webId };
	console.log(newData);
	return Object.keys(newData)
		.map(
			(key) => encodeURIComponent(key) + "=" + encodeURIComponent(newData[key])
		)
		.join("&");
};

export const request = (path, { data = null, token = null, method = "GET" }) => {
	const url = path.includes("http") ? path : "https://api-html.045bet.com/index.php?page=" + path
	// const url = path.includes("http") ? path : "http://app.info.dig88api.com/index.php?page=" + path

	let requestHeader = {
		method,
		headers: {
			Authorization: token ? token : "",
			"Content-Type": "application/x-www-form-urlencoded;",
			Accept: "application/json",
		},
		body: method !== "GET" ? encodeFormData(data) : null,
	}
	if (path.includes("http")) {
		requestHeader = {
			method,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;",
				Accept: "application/json",
			},
			body: method !== "GET" ? encodeFormData(data) : null,
		}
	}
	return fetch(url, requestHeader)
		.then((response) => {
			if (response.ok) {
				if (method === "DELETE") {
					// if delete nothing return
					return true;
				}
				return response.json().then((json) => {
					console.log(json, 'request');
					const errorCode = json.code !== "" ? json.code : json.msg;

					if (errorCode || errorCode === 0 || json.msg === 'success' || json.msg === 'Update Success') {
						return json;
					}
				});
			}
			return response
				.json()
				.then((json) => {
					if (response.status === 400) {
						const errors = Object.keys(json).map(
							(k) => `[${k}]: ${json[k].join(" ")}`
						);
						throw new Error(errors.json(" "));
					}
					throw new Error(JSON.stringify(json));
				})
				.catch((e) => {
					if (e.name === "SyntaxError") {
						throw new Error(response.statusText);
					}
					throw new Error(e);
				});
		})
		.catch((e) => {
			toast(e.message, { type: "error" });
		});
}
export function webInfo() {
	const webId = localStorage.getItem("webId");
	const dir = localStorage.getItem("dir");
	const data = { webId, dir };
	return data;
}
export function getDomain() {
	const domain = "855kg.com";

	const response = async () => {
		const getDomain = await request("get_domain&domain=" + domain, {});
		if (getDomain.code === "1") {
			localStorage.setItem("dir", getDomain.dir);
			localStorage.setItem("webId", getDomain.web_id);
		}
	};
	response();
}
export function getCurrentIp() {
	const getIp = async () => {
		const getBrowser = navigator.userAgent;
		const res = await axios("https://geolocation-db.com/json/");
		const resData = res.data;
		const data = { ...resData, browser: getBrowser };
		localStorage.setItem("memberIp", JSON.stringify(data));
	};
	getIp();
}
export function getIpInfo() {
	const ipInfo = JSON.parse(localStorage.getItem("memberIp"));

	return `${ipInfo.IPv4} - ${ipInfo.browser}`;
}
export function signIn(username, password) {
	const fullIp = getIpInfo();
	return request("login_submitter", {
		data: { username, password, IP: fullIp, from: "Desktop" },
		method: "POST",
	});
}

export function register(data) {
	function renameProperty(obj, oldName, newName) {
		obj[newName] = obj[oldName];
		delete obj[oldName];
		return obj;
	}
	const fullIp = getIpInfo();
	let newData = { ...data, ip: fullIp, captcha: "12345" };
	newData = renameProperty(newData, "phone", "tel");
	newData = renameProperty(newData, "username", "useracc");

	return request("signup_submitter", {
		data: { ...newData },
		method: "POST",
	});
}

export function deposit(data) {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		IP: fullIp,
		...data,
	};

	return request("depo_submitter", {
		data: newData,
		method: "POST",
	});
}

export function depositRecord(data) {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		...data,
	};
	return request("depo_list_submitter", {
		data: newData,
		method: "POST",
	});
}

export function withdrawRecord(data) {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		...data,
	};
	return request("with_list_submitter", {
		data: newData,
		method: "POST",
	});
}

export function withdraw(data) {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		IP: fullIp,
		...data,
	};

	return request("with_submitter", {
		data: newData,
		method: "POST",
	});
}
// Bind bank account
export function bindBank(data) {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();

	const newData = {
		user_id: user_id,
		session_id: session_id,
		email: data.email,
		email_type: data.emailType,
		captcha: data.captcha,
		tel: data.tel,
		bank: data.bank,
		bankaccount: data.bankAccName,
		bankno: data.bankAccNumber,
		full_name: data.fullName,
		address: data.address,
		referral: data.referral,
		currency: data.currency,
		ip: fullIp,
		ext3: data.ext3,
		ext4: data.ext4,
		profile: data.profile,
		fix_bank_type: data.fixBankType
	};
	console.log(newData)

	return request("update_mem_bank_submitter", {
		data: newData,
		method: "POST",
	});
}
// Get bank
export function bank() {
	return request("get_bank_submitter", {
		data: {},
		method: "POST",
	});
}

// Get bank account
export function bankAccount(type) {
	const bankType = type ? type : 2;
	const response = async () => {
		const bankAcc = await request("get_bankacc_submitter", {
			data: {},
			method: "POST",
		});
		const bankData = bankAcc.data;
		const { mem_level, currency_name } = JSON.parse(
			localStorage.getItem("token")
		);

		const filterBank = bankData.filter(
			(bank) =>
				parseInt(bank.type) === bankType &&
				bank.currency_name === currency_name &&
				bank.online === "0" &&
				(bank.bank_level === mem_level || type === "1") // Withdraw bank do not need to check bank level
		);

		return filterBank;
	};

	const returnData = response();

	return returnData;
}

export function gameSwitch(gametype = "") {
	const gameStatus = async () => {
		const response = await request("games_status_submitter", {
			data: {},
			method: "POST",
		});
		const data = response.data;

		var games = gameInfoInConfig();
		var result = games.filter((item) => {
			return data.some((item2) => {
				return (
					([item2.provider, item2.gametype].includes(item.gamesSwitch) &&
						(gametype === "" || item.category === gametype))
				);
			});
		});
		return result;
	};

	return gameStatus();
}

// Get slot game
export function getSlot(provider) {
	const response = async () => {
		const slot = await request("get_slots", {
			data: {
				provider: provider,
			},
			method: "POST",
		});
		if (slot.code === "1") {
			const slotData = slot.data[provider];
			const webId = localStorage.getItem("webId");
			const { session_id, useracc } = JSON.parse(localStorage.getItem("token"));

			let newData = [];
			if (slotData.length > 0) {
				newData = slotData.map((s) => {
					if (s.status === "0") {
						const postData = {
							webId: webId,
							provider: provider,
							language: "en",
							username: useracc,
							sessionId: session_id,
							gameId: s.gid,
							imageId: s.image,
							remark: s.remark,
						};
						const getSlotInfo = slotInfoInConfig(postData);
						return { ...s, ...getSlotInfo };
					}
					return {};
				});
				//console.log(newData)
			}
			return newData;
		}
	};
	const returnData = response();

	return returnData;
}

export function getPromotion() {
	const { user_id, currency } = JSON.parse(localStorage.getItem("token"));
	const webId = localStorage.getItem("webId");
	const domain = window.location.hostname;
	let data = "&web_id=" + webId + "&lang=en&domain=" + domain;
	if (user_id !== "") {
		data = data + "&user_id=" + user_id + "&currency=" + currency;
	}

	return request("promo_design_submitter" + data, {});
}

export function applyPromotion(promoCode) {
	const { user_id, session_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const data = {
		user_id: user_id,
		session_id: session_id,
		ip: fullIp,
		code: promoCode,
	};
	return request("promo_submitter", {
		data: data,
		method: "POST",
	});
}

export function checkTurnover(data) {
	const { game, startDate, endDate } = data;
	const gameText = game.join("#");
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		game: gameText,
		date_start: startDate.replace("T", " "),
		date_end: endDate.replace("T", " "),
	};
	return request("member_turnover_submitter", {
		data: newData,
		method: "POST",
	});
}

export function getMessage(data) {
	const { startDate, endDate, type } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		member_id: user_id,
		session_id: session_id,
		start_date: startDate.replace("T", " "),
		end_date: endDate.replace("T", " "),
		type: type,
	};
	console.log(newData)
	return request("msg_box", {
		data: newData,
		method: "POST",
	});
}

export function updateMessage(msgId) {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		member_id: user_id,
		session_id: session_id,
		type: 2,
		msg_id: msgId,
	};

	return request("msg_box_submitter", {
		data: newData,
		method: "POST",
	});
}

export function changePassword(data) {
	const { currentPass, newPass, confirmNew } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		OldPassword: currentPass,
		NewPassword: newPass,
		ConfirmPassword: confirmNew,
		IP: fullIp,
	};

	return request("pass_submitter", {
		data: newData,
		method: "POST",
	});
}

export function bonus(data) {
	const { bonusType, amount } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		bonus_type: bonusType,
		amount: amount,
		IP: fullIp,
	};

	return request("bonus_submitter", {
		data: newData,
		method: "POST",
	});
}
export function bonusRecord() {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
	};
	return request("bonus_list_submitter", {
		data: newData,
		method: "POST",
	});
}

export function referralList() {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
	};
	return request("member_ref_submitter", {
		data: newData,
		method: "POST",
	});
}

export function referralBonus(data) {
	const { startDate, endDate } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		date_start: startDate.replace("T", " "),
		date_end: endDate.replace("T", " "),
	};
	return false;
	return request("request_ref_list_submitter", {
		data: newData,
		method: "POST",
	});
}

export function referralBonusRecord(data) {
	const { startDate, endDate } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		date_start: startDate.replace("T", " "),
		date_end: endDate.replace("T", " "),
	};

	return request("request_ref_list_submitter", {
		data: newData,
		method: "POST",
	});
}

export function promoRecord(data) {
	const { startDate, endDate } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		start_date: startDate.replace("T", " "),
		end_date: endDate.replace("T", " "),
	};

	return request("promo_list_submitter", {
		data: newData,
		method: "POST",
	});
}
export function autoPromoRecord(data) {
	const { startDate, endDate } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		start_date: startDate.replace("T", " "),
		end_date: endDate.replace("T", " "),
	};

	return request("auto_promo_sumitter", {
		data: newData,
		method: "POST",
	});
}

export function betRecord(data) {
	const { startDate, endDate, url } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		start_date: startDate.replace("T", " "),
		end_date: endDate.replace("T", " "),
	};

	return request(url, {
		data: newData,
		method: "POST",
	});
}
export function statement(data) {
	const { startDate, endDate, url } = data;
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
		start_date: startDate.replace("T", " "),
		end_date: endDate.replace("T", " "),
	};

	return request(url, {
		data: newData,
		method: "POST",
	});
}

export function transfer(data) {
	const { amount, from, to } = data;
	const { session_id, useracc } = JSON.parse(localStorage.getItem("token"));
	const webId = localStorage.getItem("webId");
	const param = "transfer.php?web_id=" + webId + "&username=" + useracc + "&from=" + from + "&to=" + to + "&amount=" + amount
	const transfer = async () => {
		const games = await gameInfoInConfig()
		const filterGames = games.filter(game => game.transferId === parseInt(from) || game.transferId === parseInt(to))
		const game = filterGames[0]
		const api = game.api
		const url = api + param
		const response = await request(url, {});
		const transferId = from > 1 ? from : to;

		if (response && [0, 1].includes(response.code)) {
			const urlBal = api + game.transferBalUrl + "?token=" + session_id + "&web_id=" + webId + "&username=" + useracc;
			const responseBal = await request(urlBal, {});
			const currentBal = await getBalance()

			return { ...responseBal, transferId: transferId, currentBal: currentBal }
		}
	}
	return transfer()
}
export function transferRecord() {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
	};
	return request("transfer_list_submitter", {
		data: newData,
		method: "POST",
	});
}
export function transferBal() {
	const { session_id, useracc } = JSON.parse(localStorage.getItem("token"));
	const webId = localStorage.getItem("webId");
	const param = "?token=" + session_id + "&web_id=" + webId + "&username=" + useracc

	const game = async () => {
		const games = await gameSwitch()
		const filterGames = games.filter(game => game.transferId)
		return await Promise.all(filterGames.map(async (game) => {
			const url = game.api + game.transferBalUrl + param;
			const gameBal = await request(url, {});
			return {
				...gameBal,
				name: game.name,
				transferId: game.transferId,
				api: game.api,
			}
		}))
	}

	return game();
}

export function getBalance() {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
	};
	const bal = async () => {
		const response = await request("credit_submitter", {
			data: newData,
			method: "POST",
		});
		const currentBal = response.data[0].balance;
		const memberInfo = JSON.parse(localStorage.getItem("token"));
		if (parseInt(response.code) === 1) {
			memberInfo['balance'] = currentBal;
			localStorage.setItem('token', JSON.stringify(memberInfo));
		}
		return currentBal;
	}
	return bal();
}

export function gameSetting() {
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const newData = {
		user_id: user_id,
		session_id: session_id,
	};
	return request("game_set_submitter", {
		data: newData,
		method: "POST",
	});
}
export function lotteryInfo() {
	return request("lottery_status_submitter", {
		data: {},
		method: "POST",
	});
}

export function lotteryResult(type2) {
	const newData = {
		type2: type2,
		type1: 5,
	};
	return request("get_result_submitter", {
		data: newData,
		method: "POST",
	});
}

export function lottery4dSubmitter(data) {
	let betData = [];
	for (let i = 1; i <= data.numRow; i++) {
		const amt = data.betInfo["amount" + i];
		const num = parseInt(data.betInfo["num" + i]);
		if (amt && num && Number.isInteger(num)) {
			betData.push(num + '#' + amt);
		}
	}
	betData = betData.join('^');

	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		IP: fullIp,
		id_provider: data.lotteryId,
		from: 'PC',
		get_bet: betData
	};
	return request("4d_submitter", {
		data: newData,
		method: "POST",
	});
}

export function lotteryComSubmitter(data) {
	console.log(data);
	let betData = [];
	const lotteryTypeArr = { 1: "BigSmall", 2: "OddEven", 3: "BSOE" };
	for (let i = 1; i <= 3; i++) {
		const amt = data.betInfo["amount" + i];
		const type = lotteryTypeArr[i];
		const num = data.betInfo[type];
		if (type && amt && num) {
			betData.push(type + '#' + num + '#' + amt);
		}
	}
	betData = betData.join('^');

	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		IP: fullIp,
		id_provider: data.lotteryId,
		from: 'PC',
		get_bet: betData
	};
	return request("bsoe_submitter", {
		data: newData,
		method: "POST",
	});
}

export function lotteryShioSubmitter(data) {
	let betData = [];
	for (let i = 1; i <= 12; i++) {
		const amt = data.betInfo["amount" + i];
		const type = data.betInfo["type" + i];
		if (amt && type) {
			betData.push(type + '#' + amt);
		}
	}
	betData = betData.join('^');

	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		IP: fullIp,
		id_provider: data.lotteryId,
		from: 'PC',
		get_bet: betData
	};
	return request("shio_submitter", {
		data: newData,
		method: "POST",
	});
}

export function lotteryColokSubmitter(data) {
	const type = ["Angka", "Macau", "Naga", "Jitu"];
	let betData = [];
	for (let i = 1; i <= 25; i++) {
		const amt = data.betInfo["amount" + i];
		const num = data.betInfo["num" + i];
		if (!amt) continue;

		if (i <= 10) {
			let j = i - 1;
			betData.push(type[0] + "#" + j + '#' + amt);
		} else if (i >= 11 && i <= 15 && num.length === 2) {
			betData.push(type[1] + "#" + num + '#' + amt);
		} else if (i >= 16 && i <= 20 && num.length === 3) {
			betData.push(type[2] + "#" + num + '#' + amt);
		} else if (i >= 21 && i <= 25 && num.length === 1) {
			const location = data.betInfo["location" + i];
			if (!location) continue;
			betData.push(type[3] + "#" + num + location + '#' + amt);
		}
	}
	betData = betData.join('^');
	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		IP: fullIp,
		id_provider: data.lotteryId,
		from: 'PC',
		get_bet: betData
	};
	return request("colok_submitter", {
		data: newData,
		method: "POST",
	});
}
export function lotteryAlterSubmitter(data) {
	let betData = [];
	for (let i = 1; i <= data.numRow; i++) {
		const amt = data.betInfo["amount" + i];
		const num = parseInt(data.betInfo["num" + i]);
		if (amt && num && Number.isInteger(num)) {
			betData.push(num + '#' + amt);
		}
	}
	betData = betData.join('^');

	const { session_id, user_id } = JSON.parse(localStorage.getItem("token"));
	const fullIp = getIpInfo();
	const newData = {
		user_id: user_id,
		session_id: session_id,
		IP: fullIp,
		id_provider: data.lotteryId,
		from: 'PC',
		get_bet: betData
	};
	return request("multi_submitter", {
		data: newData,
		method: "POST",
	});
}