import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const FooterKg = (props) => {
    return (
        <>
            <div className="footer">
                <Container>
                    <div className="footer-menu">
                        <Link to="/">responsible gaming</Link>
                        <Link to="/">terms of use</Link>
                        <Link to="/">contact us</Link>
                        <Link to="/">game rules</Link>
                        <Link to="/">about us</Link>
                    </div>
                    <div className="footer-cont w-100 my-5">
                        <Row className="w-100 align-items-center">
                            <Col lg={1}>
                                <div className="footer-logo">
                                    <Link to="">
                                        <img src={`../images/kg/logo.svg`} alt="Footer Logo" />
                                    </Link>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <ul className="footer-list">
                                    <li>
                                        <h6>sportsbook</h6>
                                        <Link to="">Afb Sports</Link>
                                        <Link to="">Afb1188 Sports</Link>
                                        <Link to="">Sbo Sports</Link>
                                        <Link to="">Ibc Sports</Link>
                                        <Link to="">Tf E-Sports</Link>
                                        <Link to="">Horse Racing</Link>
                                        <Link to="">Leap Gaming</Link>
                                    </li>
                                    <li>
                                        <h6>casino</h6>
                                        <Link to="">Afb Casino</Link>
                                        <Link to="">Gd Casino</Link>
                                        <Link to="">Dg99 Casino</Link>
                                        <Link to="">Wm Casino</Link>
                                        <Link to="">Sexy Casino</Link>
                                        <Link to="">Ag Casino</Link>
                                        <Link to="">Allbet Casino</Link>
                                    </li>
                                    <li>
                                        <h6>slots</h6>
                                        <Link to="">Fishing Games</Link>
                                        <Link to="">Afb Gaming</Link>
                                        <Link to="">Pplay Slots</Link>
                                        <Link to="">Pt Slots</Link>
                                        <Link to="">Mg Slots</Link>
                                        <Link to="">Haba Slots</Link>
                                        <Link to="">W88 Slots</Link>
                                    </li>
                                    <li>
                                        <h6>lottery</h6>
                                        <Link to="">Lottery</Link>
                                        <Link to="">Thai Lottery</Link>
                                        <Link to="">Vn Lottery</Link>
                                        <Link to="">Vn Lottery2</Link>
                                        <Link to="">Vn Lottery3</Link>
                                        <Link to="">4D Lottery</Link>
                                        <Link to="">W88 Thai Lottery</Link>
                                    </li>
                                    <li>
                                        <h6>number</h6>
                                        <Link to="">Cockfighting</Link>
                                        <Link to="">Number</Link>
                                        <Link to="">Live Number</Link>
                                        <Link to="">Keno</Link>
                                        <Link to="">New Keno</Link>
                                        <Link to="">Wingo</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg={3}>
                                <div className="footer-contact ps-5">
                                    <h3>contact</h3>
                                    <ul>
                                        <li>
                                            <Link to="">
                                                <i className="bi bi-skype"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <i className="bi bi-telegram"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <i className="bi bi-twitter"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <i className="bi bi-wechat"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="">
                                                <i className="bi bi-facebook"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-copy">copyright &copy; 2022 kg.com all rights reserved</div>
                </Container>
            </div >
        </>
    )
}

export default FooterKg