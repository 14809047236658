import { Link } from "react-router-dom"
const TransferMenu = () => {

    return (
        <div className="d-flex">
            <Link className="text-white me-2" to="/transfer">Transfer</Link>
            <Link className="text-white" to="/transfer-record">Transfer List</Link>
        </div>
    )
}

export default TransferMenu