import { Container, Row, Table } from "react-bootstrap"
import MemberNav from "../MemberNav"

const MemberInfo = (props) => {
    const user = JSON.parse(localStorage.getItem("token"));
    return (
        <Container className="d-flex">
            <MemberNav t={props.t} />
            <Row className="py-2 flex-grow-1">
                <h4 className="text-white">{props.t('memberInfo.Member Information')}</h4>
                <Table borderless variant="light" className="info-table">
                    <tbody>
                        <tr>
                            <td>{props.t('memberInfo.Username')}:</td>
                            <td>{user.useracc}</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.Referral Link')}:</td>
                            <td>https://www.wwww.www</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.E-mail')}:</td>
                            <td>{user.email}</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.Phone')}:</td>
                            <td>{user.tel}</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.Bank')}:</td>
                            <td>{user.bank_name}</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.Bank Account Name')}:</td>
                            <td>{user.bank_acc_name}</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.Bank Account Number')}:</td>
                            <td>{user.bank_acc_no}</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.Total Bonus')}:</td>
                            <td>0.00</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.Last Bonus')}:</td>
                            <td>0.00</td>
                        </tr>
                        <tr>
                            <td>{props.t('memberInfo.Balance')}:</td>
                            <td>{parseFloat(user.balance).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    )
}

export default MemberInfo