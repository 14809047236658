import { useEffect } from "react";
import { useState } from "react";
import { Table, Container, Row, Spinner } from "react-bootstrap";
import { withdrawRecord as withdrawRecordInApi } from "../../../../api"
import MemberNav from "../../MemberNav";
import WithdrawMenu from "../withdrawMenu/WithdrawMenu";

const WithdrawRecord = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);

    const status = ['Submitting', 'Completed', 'Processing', 'Expired', 'Canceled', 'Pending'];
    useEffect(() => {
        const withdrawRecord = async () => {
            setLoading(true);
            const response = await withdrawRecordInApi();
            if (response.data) {
                setRecord(response.data);
            }
            setLoading(false);
        }
        withdrawRecord();
    }, [])

    return (
        <>
            <Container className="d-flex">
                <MemberNav t={props.t} />
                <Row className="py-2 flex-grow-1 d-flex flex-column">
                    <WithdrawMenu t={props.t} />
                    <Table striped bordered hover variant="light">
                        <thead>
                            <tr>
                                <th>{props.t('with.No')}.</th>
                                <th>{props.t('with.DateTime')}</th>
                                <th>{props.t('with.Bank Name')}</th>
                                <th>{props.t('with.Amount')}</th>
                                <th>{props.t('with.Status')}</th>
                                <th>{props.t('with.Remark')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td colSpan="6">
                                            <Spinner
                                                className="d-block mx-auto"
                                                variant="standard"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" />
                                        </td>
                                    </tr> :
                                    record.length > 0 ?
                                        record.map((rec, index) =>
                                            <tr key={`d-record-${index}`}>
                                                <td>{rec.id_mod_deposit_withdraw}</td>
                                                <td>{rec.date_time}</td>
                                                <td>{rec.bank_name}</td>
                                                <td>{rec.amount}</td>
                                                <td>{props.t('record.' + status[rec.status])}</td>
                                                <td>{rec.trs_info}</td>
                                            </tr>
                                        ) :
                                        <tr><td colSpan="6" className="text-center">No record found !</td></tr>
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )
}

export default WithdrawRecord