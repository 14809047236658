import { gameSwitch as gameSwitchInApi } from "../../../api";
import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import CustomCarousel from "../../../components/carousel/Carousel";

const LotteryLobbyKg = (props) => {
    const [games, setGames] = useState([]);

    useEffect(() => {
        const gameInfo = async () => {
            const response = await gameSwitchInApi("Lottery");
            //console.log(response)

            setGames(response);
        };
        gameInfo();
    }, []);

    return (
        <>
            <CustomCarousel />
            <Container className="container-lg" fluid>
                <Row className="game">
                    {
                        games.length > 0 &&
                        games.slice(0, 1).map((g, index) =>
                            <Col key={`lottery-${index}`} sm={4}>
                                <Link to={`/games/${g.url}`} className="position-relative game-cont">
                                    <span className="game-enter">Enter</span>
                                    <h5>{g.name}</h5>
                                    <img className="game-bg" src={`../images/kg/game/lottery/${g.img}.jpg`} alt={`${g.name}-${index}`} />
                                </Link>
                            </Col>
                        )
                    }
                    {
                        games.length > 1 &&
                        <Col sm={8}>
                            <Row>
                                {
                                    games.slice(1, 5).map((g, index) =>
                                        <Col key={`lottery-${index}`} sm={6}>
                                            <Link to={`/games/${g.url}`} className="position-relative game-cont">
                                                <span className="game-enter">Enter</span>
                                                <h5>{g.name}</h5>
                                                <img className="game-bg" src={`../images/kg/game/lottery/${g.img}.jpg`} alt={`${g.name}-${index}`} />
                                            </Link>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    }
                    {
                        games.length > 4 &&
                        games.slice(5).map((g, index) =>
                            <Col key={`lottery-${index}`} sm={4}>
                                <Link to={`/games/${g.url}`} className="position-relative game-cont">
                                    <span className="game-enter">Enter</span>
                                    <h5>{g.name}</h5>
                                    <img className="game-bg" src={`../images/kg/game/lottery/${g.img}.jpg`} alt={`${g.name}-${index}`} />
                                </Link>
                            </Col>
                        )
                    }
                </Row>
            </Container>
        </>
    );
};

export default LotteryLobbyKg;
