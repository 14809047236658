import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const MenuKg = (props) => {

    const user = JSON.parse(localStorage.getItem("token"));
    return (
        <>
            <div className="menu d-flex flex-column position-relative my-5">
                <img className="menu-logo position-absolute rounded-circle border-4 border-white border" src={`../images/kg/avatar.svg`} alt="Avatar" />
                <div className="menu-top d-flex flex-column align-items-center rounded-4 overflow-hidden">
                    <span className="text-lowercase">{user.useracc}</span>
                    <span>{user.email}</span>
                    <div className="menu-top-item mt-3 rounded-3">
                        <p className="mb-1 d-flex align-items-center"><span className="me-auto">Total Bonus: </span><span className="menu-primary-color">{parseFloat(user.total_bonus).toFixed(2)}</span></p>
                        <p className="mb-1 d-flex align-items-center"><span className="me-auto">Last Bonus: </span><span className="menu-primary-color">{parseFloat(user.last_bonus).toFixed(2)}</span></p>
                        <p className="mb-1 d-flex align-items-center"><span className="me-auto">Balance: </span><span className="menu-primary-color">{parseFloat(user.balance).toFixed(2)}</span></p>
                    </div>
                </div>
                <ListGroup className="menu-bottom px-2 pt-5 pb-5 rounded-4">
                    <ListGroup.Item as={Link} to="/member-info" action>
                        <i className="bi bi-person-circle text-white me-3"></i>
                        <span>{props.t("memberNav.Basic Information")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/change-password" action>
                        <i className="bi bi-key text-white me-3"></i>
                        <span>{props.t("memberNav.Change Password")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/deposit" action>
                        <i className="bi bi-cash-coin text-white me-3"></i>
                        <span>{props.t("memberNav.Deposit")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/withdraw" action>
                        <i className="bi bi-wallet2 text-white me-3"></i>
                        <span>{props.t("memberNav.Withdraw")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/bet-record" action>
                        <i className="bi bi-journal-text text-white me-3"></i>
                        <span>{props.t("memberNav.Bet Record")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/statement" action>
                        <i className="bi bi-newspaper text-white me-3"></i>
                        <span>{props.t("memberNav.Statement")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/bonus" action>
                        <i className="bi bi-gift text-white me-3"></i>
                        <span>{props.t("memberNav.Bonus")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/transfer" action>
                        <i className="bi bi-arrow-left-right text-white me-3"></i>
                        <span>{props.t("memberNav.Transfer")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/turnover" action>
                        <i className="bi bi-hdd-stack text-white me-3"></i>
                        <span>{props.t("memberNav.Turnover")}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as={Link} to="/message" action>
                        <i className="bi bi-hdd-stack text-white me-3"></i>
                        <span>{props.t("memberNav.Message")}</span>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </>

    );
};

export default MenuKg;
