import React from "react";
import ReactCountryFlag from "react-country-flag";
import CountryCodeList from "country-codes-list";
import i18n from "i18next";

export function countryInfo(data) {
    // countryNameEn
    // countryNameLocal
    // countryCode
    // currencyCode
    // currencyNameEn
    // tinType
    // tinName
    // officialLanguageCode
    // officialLanguageNameEn
    // officialLanguageNameLocal
    // countryCallingCode
    // region
    // globalSouth
    const countryList = CountryCodeList.customList(
        "countryCode",
        "{countryCode}##{currencyCode}##{officialLanguageNameLocal}##{countryNameEn}"
    );
    let newCountryList = [];
    //console.log(countryList)
    for (let country in countryList) {
        const split = countryList[country].split("##");

        const countryCode = split[0];
        const currency = split[1];
        const language = split[2].split(",")[0];
        const languageCode = countryCode.toLowerCase();
        const countryName = {
            hk: "香港",
            tw: "台湾",
            cn: "中文",
        };
        if (data.includes(languageCode)) {
            const flag = <ReactCountryFlag countryCode={countryCode} />;
            newCountryList.push({
                countryCode: countryCode,
                currency: currency,
                language: language ? language : countryName[languageCode],
                languageCode: languageCode,
                flag: flag,
                nameen: split[3],
            });
        }
    }
    newCountryList.sort(
        (a, b) => data.indexOf(a.languageCode) - data.indexOf(b.languageCode)
    );
    newCountryList.sort(
        (a, b) => data.indexOf(a.languageCode) - data.indexOf(b.languageCode)
    );

    newCountryList.forEach((list, index) => {
        if (list.languageCode === i18n.language) {
            newCountryList.splice(index, 1);
            newCountryList.unshift(list);
        }
    });

    return newCountryList;
}

export function gameInfo() {
    // gametype = Sport, Casino, Slot...
    const games = [
        // Khmergaming 
        {
            gamesSwitch: "Khmergaming",
            img: "khmergaming",
            name: "Khmergaming",
            name2: "Khmergaming",
            url: "",
            api: "",
            apiReturnData: 1, // Game api return array data
            category: "Default",
            turnoverId: 14,
            statementUrl: "bet_statements_submitter",
            betRecordUrl: "bet_records_submitter",
        },
        // Sport
        {
            gamesSwitch: "AFB2 Sports",
            img: "afb2",
            name: "AFB2 Sport",
            name2: "AFB2",
            url: "afb2-sports",
            api: "https://afbsport.k-api.com/api/",
            apiReturnData: 1, // Game api return array data
            gameId: 52,
            category: "Sport",
            turnoverId: 35,
            statementUrl: "afbsport2_statement_submitter",
        },
        {
            gamesSwitch: "AFB Sports",
            img: "afb",
            name: "AFB Sport",
            name2: "AFB",
            url: "sports",
            api: "http://afbsports.hk.dig88api.com/api/",
            apiReturnData: 1,
            gameId: 61,
            category: "Sport",
            turnoverId: 1,
            statementUrl: "afbsport_statement_submitter",
            betRecordUrl: "bet_records_afb",
        },
        {
            gamesSwitch: "IBC Sports",
            img: "ibc",
            name: "IBC Sport",
            name2: "IBC",
            url: "sports_saba",
            api: "https://sbsportsss.k-api.com/api/",
            apiReturnData: 1,
            gameId: 98,
            category: "Sport",
            turnoverId: 2,
            statementUrl: "saba_sport_statement_submitter",
            betRecordUrl: "bet_records_saba",
        },
        {
            gamesSwitch: "SBO Sports",
            img: "sbo",
            name: "SBO Sport",
            name2: "SBO",
            url: "sports_sb",
            api: "https://sbsport.k-api.com/api/",
            gameId: 100,
            category: "Sport",
            turnoverId: 3,
            statementUrl: "sbo_sport_statement_submitter",
            betRecordUrl: "bet_records_sbo",
        },
        {
            gamesSwitch: "TF ESports",
            img: "tf_esport",
            name: "TF E-Sport",
            name2: "TF E-Sport",
            url: "tf-esport",
            api: "http://tfgaming.k-api.com/api/",
            apiReturnData: 1,
            gameId: 353,
            category: "Sport",
            turnoverId: 47,
            statementUrl: "tfesport_report_list",
            betRecordUrl: "bet_records_tfesport",
        },
        {
            gamesSwitch: "Horse Race",
            img: "horse_racing",
            name: "Horse Racing",
            name2: "Horse Racing",
            url: "horse-racing",
            api: "https://newsexy.k-api.com/api/",
            gameId: 359,
            gameType: 1,
            category: "Sport",
            turnoverId: 24,
            statementUrl: "sexy_report_submitter",
        },
        // Casino
        {
            gamesSwitch: "AFB Casino",
            img: "afb_casino",
            name: "AFB Casino",
            name2: "AFB",
            url: "tga-casino",
            api: "https://tga.k-api.com/api/",
            gameId: 361,
            category: "Casino",
            turnoverId: 48,
            statementUrl: "tga_report_list",
        },
        {
            gamesSwitch: "GD Casino ",
            img: "gd",
            name: "GD Casino",
            name2: "GD",
            url: "gd-casino",
            gameId: 53,
            category: "Casino",
            turnoverId: 4,
        },
        {
            gamesSwitch: "PP Casino",
            img: "pplay",
            name: "PP Casino",
            name2: "PP",
            url: "pplay-casino",
            api: "https://ppslots.k-api.com/api/",
            gameId: 371,
            providerGameId: 101,
            category: "Casino",
            turnoverId: 57,
            statementUrl: "pplay_report_list",
        },
        {
            gamesSwitch: "DG99 Casino",
            img: "dg99",
            name: "DG99 Casino",
            name2: "DG99",
            url: "dg99-casino",
            api: "https://dg99.k-api.com/api/",
            apiReturnData: 1,
            gameId: 136,
            deviceType: 2,
            category: "Casino",
            turnoverId: 8,
            statementUrl: "dg99_statement_submitter",
        },
        {
            gamesSwitch: "WM Casino",
            img: "wm",
            name: "WM Casino",
            name2: "WM",
            url: "wm-casino",
            api: "https://wmapi.k-api.com/api/",
            gameId: 247,
            category: "Casino",
            turnoverId: 29,
            statementUrl: "wm_report_list",
        },
        {
            gamesSwitch: "Sexy Casino",
            img: "sexy",
            name: "Sexy Casino",
            name2: "Sexy",
            url: "casino_sexy",
            api: "https://newsexy.k-api.com/api/",
            gameId: 208,
            gameType: "0",
            category: "Casino",
            turnoverId: 24,
            statementUrl: "sexy_report_submitter",
        },
        {
            gamesSwitch: "AG Casino",
            img: "ag",
            name: "AG Casino",
            name2: "AG",
            url: "ag-casino",
            api: "https://agcasino.k-api.com/api/",
            gameId: 96,
            category: "Casino",
            turnoverId: 5,
            statementUrl: "ag_statement_submitter",
        },
        {
            gamesSwitch: "All Bet",
            img: "allbet",
            name: "ALLBET Casino",
            name2: "AllBet",
            url: "allbet-casino",
            api: "https://allbet.k-api.com/api/",
            apiReturnData: 1,
            gameId: 129,
            category: "Casino",
            turnoverId: 7,
            statementUrl: "allbet_statement_submitter",
        },
        {
            gamesSwitch: "EVO Gaming",
            img: "evo",
            name: "EVO Casino",
            name2: "EVO Gaming",
            url: "evo-casino",
            api: "https://evogaming.k-api.com/api/",
            gameId: 263,
            gameIp: 1,
            category: "Casino",
            turnoverId: 38,
            statementUrl: "evogaming_report",
        },
        {
            gamesSwitch: "HongBo Casino",
            img: "hbo",
            name: "HBO Casino",
            name2: "HongBo",
            url: "hbo_casino",
            api: "https://hongbo.k-api.com/api/",
            gameId: 326,
            category: "Casino",
            turnoverId: 42,
            statementUrl: "hbo_report_list",
        },
        {
            gamesSwitch: "MIKI",
            img: "miki",
            name: "MIKI Casino",
            name2: "MIKI",
            url: "casino_miki",
            api: "https://miki.k-api.com/api/",
            gameId: 343,
            category: "Casino",
            turnoverId: 44,
            statementUrl: "miki_report_list",
        },
        {
            gamesSwitch: "N2live Casino",
            img: "n2live",
            name: "N2live Casino",
            name2: "N2live",
            url: "n2live",
            api: "https://n2live.k-api.com/api/",
            apiReturnData: 1,
            gameId: 345,
            category: "Casino",
            turnoverId: 45,
            statementUrl: "n2live_report_list",
        },
        {
            gamesSwitch: "Yeebet",
            img: "yeebet",
            name: "Yeebet",
            name2: "Yeebet",
            url: "yeebet",
            api: "https://yeebet.k-api.com/api/",
            apiReturnData: 1,
            gameId: 344,
            category: "Casino",
            turnoverId: 58,
            statementUrl: "yeebet_report_list",
        },
        {
            gamesSwitch: "Supernowa",
            img: "supernowa",
            name: "Supernowa",
            name2: "Supernowa",
            url: "supernowa",
            api: "https://supernowa.k-api.com/api/",
            apiReturnData: 1,
            gameId: 386,
            category: "Casino",
            turnoverId: 59,
            statementUrl: "supernowa_report_list",
        },
        // Slot
        {
            gamesSwitch: "Fishing",
            img: "fishing",
            name: "Fishing",
            name2: "Fishing",
            url: "slots_fish",
            gameId: 134,
            category: "Slot",
            turnoverId: 24,
        },
        {
            gamesSwitch: "BestGamer",
            img: "afb_slot",
            name: "AFB Gaming",
            name2: "AFB Gaming",
            url: "slots_bestgamer",
            api: "https://www.slotsgamingonline.com/",
            gameId: 94,
            category: "Slot",
            turnoverId: 12,
            statementUrl: "slots_report_submitter&provider=94",
        },
        {
            gamesSwitch: "PPlay Slots",
            img: "pplay",
            name: "PPlay Slots",
            name2: "Pragmatic Play",
            url: "slots_pplay",
            api: "https://pplay.k-api.com/api/",
            gameId: 253,
            category: "Slot",
            turnoverId: 33,
            statementUrl: "pplay_report_list",
        },
        {
            gamesSwitch: "PlayTech Slots",
            img: "pt",
            name: "PT Slots",
            name2: "Playtech",
            url: "slots_pt",
            api: "https://www.slotsgamingonline.com/",
            gameId: 132,
            category: "Slot",
            turnoverId: 13,
            statementUrl: "pt_statement_submitter",
        },
        {
            gamesSwitch: "MICRO GAMING",
            img: "mg",
            name: "MG Slots",
            name2: "Microgaming",
            url: "slots_mg",
            api: "https://www.slotsgamingonline.com/",
            gameId: 51,
            category: "Slot",
            turnoverId: 11,
            statementUrl: "slots_report_submitter",
        },
        {
            gamesSwitch: "Haba Slots",
            img: "haba",
            name: "Haba Slots",
            name2: "Habanero",
            url: "slots_haba_index",
            gameId: 99,
            category: "Slot",
            turnoverId: 10,
            statementUrl: "haba_statement_submitter",
        },
        {
            gamesSwitch: "Joker123",
            img: "joker123",
            name: "Joker123 Slots",
            name2: "Joker123",
            url: "slots_joker123",
            api: "https://joker123.k-api.com/api/",
            gameId: 246,
            category: "Slot",
            turnoverId: 17,
            statementUrl: "joker123_report_list",
        },
        {
            gamesSwitch: "SAgaming Slots",
            img: "sa",
            name: "Sa Slots",
            name2: "Sagaming",
            url: "slots_sagaming",
            api: "https://sagming.khmergaming.com/api/",
            gameId: 249,
            category: "Slot",
            turnoverId: 26,
            statementUrl: "sagaming_statement_submitter",
        },
        {
            gamesSwitch: "Playstar Slots",
            img: "playstar",
            name: "Playstar Slots",
            name2: "Playstar",
            url: "slots_playstar",
            api: "https://playstar.k-api.com/api/",
            gameId: 268,
            category: "Slot",
            turnoverId: 40,
            statementUrl: "playstar_report_list",
        },
        {
            gamesSwitch: "Dragoon Soft",
            img: "dragoon",
            name: "Dragoon Soft",
            name2: "Dragoon Soft",
            url: "slots_dragoonsoft",
            api: "https://drangoon.k-api.com/api/",
            gameId: 269,
            category: "Slot",
            turnoverId: 41,
            statementUrl: "dragoon_report_list",
        },
        {
            gamesSwitch: "Netent Slots",
            img: "netent",
            name: "Netent Slots",
            name2: "Netent",
            url: "slots_netent",
            api: "https://www.slotsgamingonline.com/",
            gameId: 250,
            category: "Slot",
            turnoverId: 39,
            statementUrl: "slots_report_submitter",
        },
        {
            gamesSwitch: "PG Slots",
            img: "pg",
            name: "PG Slots",
            name2: "PG",
            url: "slots_pg",
            api: "https://pgslots.k-api.com/api/",
            gameId: 327,
            category: "Slot",
            turnoverId: 43,
            statementUrl: "pg_report_list",
        },
        {
            gamesSwitch: "CQ9 Slots",
            img: "cq9",
            name: "CQ9 Slots",
            name2: "CQ9",
            url: "slots_cq9",
            api: "https://cq9.k-api.com/api/",
            gameId: 363,
            category: "Slot",
            turnoverId: 49,
            statementUrl: "cq9_report_list",
        },
        {
            gamesSwitch: "ICG Slots",
            img: "icg",
            name: "ICG Slots",
            name2: "ICG",
            url: "slots_icg",
            api: "https://icg.k-api.com/api/",
            gameId: 365,
            category: "Slot",
            turnoverId: 51,
            statementUrl: "icg_report_list",
        },
        {
            gamesSwitch: "FC Slots",
            img: "fc",
            name: "FC Slots",
            name2: "FC",
            url: "slots_fc",
            api: "https://fc.k-api.com/api/",
            gameId: 370,
            category: "Slot",
            turnoverId: 52,
            statementUrl: "fc_report_list",
        },
        {
            gamesSwitch: "JiLi Slots",
            img: "jili",
            name: "JiLi Slots",
            name2: "JiLi",
            url: "slots_jili",
            api: "https://jili.k-api.com/api/",
            gameId: 374,
            category: "Slot",
            turnoverId: 53,
            statementUrl: "jili_report_list",
        },
        {
            gamesSwitch: "KA Gaming",
            img: "ka_slot",
            name: "Ka Gaming",
            name2: "Ka Gaming",
            url: "slots_kagaming",
            api: "https://kagaming.k-api.com/api/",
            gameId: 375,
            category: "Slot",
            turnoverId: 54,
            statementUrl: "kagaming_report_list",
        },
        {
            gamesSwitch: "Betsoft Slots",
            img: "betsoft",
            name: "Betsoft",
            name2: "Betsoft",
            url: "slots_betsoft",
            api: "https://www.slotsgamingonline.com/",
            gameId: 337,
            category: "Slot",
            turnoverId: 0,
            statementUrl: "slots_report_submitter",
        },
        {
            gamesSwitch: "BBIN Slots",
            img: "bbin",
            name: "BBIN",
            name2: "BBIN",
            url: "slots_bbin",
            api: "https://www.slotsgamingonline.com/",
            gameId: 338,
            category: "Slot",
            turnoverId: 0,
            statementUrl: "slots_report_submitter",
        },
        {
            gamesSwitch: "NEXTGEN Slots",
            img: "nextgen",
            name: "NEXTGEN",
            name2: "NEXTGEN",
            url: "slots_nextgen",
            api: "https://www.slotsgamingonline.com/",
            gameId: 339,
            category: "Slot",
            turnoverId: 0,
            statementUrl: "slots_report_submitter",
        },

        {
            gamesSwitch: "Rich88",
            img: "rich88",
            name: "Rich88",
            name2: "Rich88",
            url: "slots_rich88",
            api: "https://rich88.k-api.com/api/",
            gameId: 377,
            category: "Slot",
            turnoverId: 56,
            statementUrl: "rich88_report_list",
        },
        {
            gamesSwitch: "Spade",
            img: "spadegaming",
            name: "Spade",
            name2: "Spade",
            url: "slots_spadegaming",
            api: "https://spade.k-api.com/api/",
            gameId: 408,
            category: "Slot",
            turnoverId: 60,
            statementUrl: "spadegaming_report_list",
        },
        {
            gamesSwitch: "918Kiss",
            img: "918kiss",
            name: "918Kiss",
            name2: "918Kiss",
            url: "scr888_lobby",
            gameId: "",
            category: "Slot",
        },
        {
            gamesSwitch: "MEGA88",
            img: "mega88",
            name: "Mega88",
            name2: "Mega88",
            url: "",
            gameId: "",
            category: "Slot",
        },
        {
            gamesSwitch: "XE88",
            img: "xe88",
            name: "XE88",
            name2: "XE88",
            url: "",
            gameId: "",
            category: "Slot",
        },
        {
            gamesSwitch: "PUSSY88",
            img: "pussy88",
            name: "Pussy88",
            name2: "Pussy88",
            url: "",
            gameId: "",
            category: "Slot",
        },
        // Poker
        {
            gamesSwitch: "KaiYuan",
            img: "kaiyuan",
            name: "Kai Yuan",
            name2: "Kai Yuan",
            url: "kai-yuan",
            api: "https://kaiyuan.k-api.com/",
            gameId: 336,
            category: "Poker",
            transferId: 1036,
            transferBalUrl: "balance.php",
        },
        {
            gamesSwitch: "We1 Poker",
            img: "we1poker",
            name: "We1Poker",
            name2: "We1Poker",
            url: "we1poker",
            api: "https://we1poker.k-api.com/",
            gameId: 341,
            category: "Poker",
            transferId: 20,
            transferBalUrl: "getbalance.php",
            statementUrl: "we1poker_report_list",
        },
        //Ongdo
        {
            gamesSwitch: "KH Gaming3",
            img: "ongdo",
            name: "Ongdo",
            name2: "Ongdo",
            url: "ongdo",
            ongdoId: "1",
            api: "https://ongdo.k-api.com/",
            gameId: 264,
            category: "Poker",
            transferId: 24,
            transferBalUrl: "balance.php",
        },
        {
            gamesSwitch: "KH Gaming3",
            img: "tienlen",
            name: "Tien Len",
            name2: "Tien Len",
            url: "tien-len",
            ongdoId: "2",
            api: "https://ongdo.k-api.com/",
            gameId: 264,
            category: "Poker",
        },
        {
            gamesSwitch: "KH Gaming3",
            img: "poke",
            name: "Poke",
            name2: "Poke",
            url: "poke",
            ongdoId: "3",
            api: "https://ongdo.k-api.com/",
            gameId: 264,
            category: "Poker",
        },
        {
            gamesSwitch: "KH Gaming3",
            img: "klaklouk",
            name: "Kla Klouk",
            name2: "Kla Klouk",
            url: "kla-klouk",
            ongdoId: "4",
            api: "https://ongdo.k-api.com/",
            gameId: 264,
            category: "Poker",
        },
        {
            gamesSwitch: "KH Gaming3",
            img: "ham",
            name: "Ham",
            name2: "Ham",
            url: "ham",
            ongdoId: "5",
            api: "https://ongdo.k-api.com/",
            gameId: 264,
            category: "Poker",
        },
        {
            gamesSwitch: "KH Gaming3",
            img: "sikuthai",
            name: "Sikuthai",
            name2: "Sikuthai",
            url: "sikuthai",
            ongdoId: "6",
            api: "https://ongdo.k-api.com/",
            gameId: 264,
            category: "Poker",
        },
        {
            gamesSwitch: "KH Gaming3",
            img: "kate",
            name: "Kate",
            name2: "Kate",
            url: "kate",
            ongdoId: "7",
            api: "https://ongdo.k-api.com/",
            gameId: 264,
            category: "Poker",
        },
        {
            gamesSwitch: "KH Gaming3",
            img: "apoung",
            name: "Apoung",
            name2: "Apoung",
            url: "apoung",
            ongdoId: "8",
            api: "https://ongdo.k-api.com/",
            gameId: 264,
            category: "Poker",
        },
        // {
        //     gamesSwitch: "Tangkas",
        //     img: "tangkas",
        //     name: "Tangkas",
        //     name2: "Tangkas",
        //     url: "indo_poker",
        //     type: "VideoPoker",
        //     gameId: 59,
        //     category: "Poker",
        // },
        // {
        //     gamesSwitch: "JokerPoker500",
        //     img: "joker-poker",
        //     name: "Joker Poker",
        //     name2: "Joker Poker",
        //     url: "indo_poker",
        //     type: "JokerPoker",
        //     gameId: 66,
        //     category: "Poker",
        // },
        // {
        //     gamesSwitch: "JacksOrBetter",
        //     img: "jack-or-better",
        //     name: "JacksOrBetter",
        //     name2: "JacksOrBetter",
        //     url: "indo_poker",
        //     type: "JacksOrBetter",
        //     gameId: 79,
        //     category: "Poker",
        // },
        // {
        //     gamesSwitch: "CaribbeanPoker",
        //     img: "caribbean-poker",
        //     name: "Caribbean Poker",
        //     name2: "Caribbean Poker",
        //     url: "indo_poker",
        //     type: "CaribbeanPoker",
        //     gameId: 80,
        //     category: "Poker",
        // },
        // {
        //     gamesSwitch: "3Kings",
        //     img: "3kings",
        //     name: "3Kings",
        //     name2: "3Kings",
        //     url: "indo_poker",
        //     type: "3Kings",
        //     gameId: 160,
        //     category: "Poker",
        // },
        // {
        //     gamesSwitch: "AirRacing",
        //     img: "air-racing",
        //     name: "Air Racing",
        //     name2: "Air Racing",
        //     url: "indo_poker",
        //     type: "AirRacing",
        //     gameId: 161,
        //     category: "Poker",
        // },
        // {
        //     gamesSwitch: "CaribbeanDomino",
        //     img: "caribbean-domino",
        //     name: "Caribbean Domino",
        //     name2: "Caribbean Domino",
        //     url: "indo_poker",
        //     type: "CaribbeanDomino",
        //     gameId: 162,
        //     category: "Poker",
        // },
        // {
        //     gamesSwitch: "VideoDomino",
        //     img: "video-domino",
        //     name: "Video Domino",
        //     name2: "Video Domino",
        //     url: "indo_poker",
        //     type: "VideoDomino",
        //     gameId: 163,
        //     category: "Poker",
        // },
        // Lottery
        {
            gamesSwitch: "LOTTERY",
            img: "lottery",
            name: "Lottery",
            name2: "Lottery",
            url: "lott_list",
            gameId: "",
            category: "Lottery",
        },
        // {
        //     gamesSwitch: "THAI LOTTERY",
        //     img: "thai_lottery",
        //     name: "Thai Lottery",
        //     name2: "Thai Lottery",
        //     url: "th_lott_list",
        //     gameId: "",
        //     category: "Lottery",
        // },
        // {
        //     gamesSwitch: "VN LOTTERY",
        //     img: "vn_lottery",
        //     name: "VN Lottery",
        //     name2: "VN Lottery",
        //     url: "vn_lott_list",
        //     gameId: "",
        //     category: "Lottery",
        // },
        // {
        //     gamesSwitch: "VN LOTTERY2",
        //     img: "vn2_lottery",
        //     name: "VN Lottery 2",
        //     name2: "VN Lottery 2",
        //     url: "vn_lottery2",
        //     gameId: "",
        //     category: "Lottery",
        // },
        // {
        //     gamesSwitch: "VN LOTTERY3_",
        //     img: "vn3_lottery",
        //     name: "VN Lottery 3",
        //     name2: "VN Lottery 3",
        //     url: "vn_lottery3",
        //     gameId: "",
        //     category: "Lottery",
        // },
        // {
        //     gamesSwitch: "4D LOTTERY",
        //     img: "4d_lottery",
        //     name: "4D Lottery",
        //     name2: "4D Lottery",
        //     url: "4d_lott_list",
        //     gameId: "",
        //     category: "Lottery",
        // },
        // Number
        {
            gamesSwitch: "AUTO NUMBER",
            img: "number",
            name: "Number",
            name2: "Number",
            url: "autonumber_game",
            gameId: "",
            category: "Number",
        },
        {
            gamesSwitch: "LIVE NUMBER",
            img: "live",
            name: "Live Number",
            name2: "Live Number",
            url: "live_number",
            gameId: "",
            category: "Number",
        },
        {
            gamesSwitch: "KENO",
            img: "keno",
            name: "Keno",
            name2: "Keno",
            url: "keno_game",
            gameId: "",
            category: "Number",
        },
        {
            gamesSwitch: "NEW KENO",
            img: "new_keno",
            name: "New Keno",
            name2: "New Keno",
            url: "keno_new",
            api: "https://newkeno.k-api.com",
            gameId: "",
            category: "Number",
            statementUrl: "keno_report_list",
        },
        {
            gamesSwitch: "WINGO_",
            img: "wingo",
            name: "Wingo",
            name2: "Wingo",
            url: "wingo",
            gameId: "",
            category: "Number",
        },
        // Forex
        {
            gamesSwitch: "855 FOREX",
            img: "forex",
            name: "Forex",
            name2: "Forex",
            url: "forex",
            api: "http://forex.k-api.com/api/",
            gameId: 83,
            category: "Forex",
            statementUrl: "forex_report_submitter",
        },
        {
            gamesSwitch: "UK24",
            img: "uk24",
            name: "UK24",
            name2: "UK24",
            url: "uk24",
            api: "http://uk24.k-api.com/api/",
            gameId: 358,
            category: "Forex",
        },
        // Cockfight
        {
            gamesSwitch: "CF88",
            img: "sv388",
            name: "SV388",
            name2: "SV388",
            url: "sv388",
            api: "https://sv388.k-api.com/api/",
            gameId: 138,
            category: "Cockfight",
            statementUrl: "cockfight_statement_list",
        },
        {
            gamesSwitch: "S128",
            img: "s128",
            name: "S128",
            name2: "S128",
            url: "s128",
            api: "https://s1288api.k-api.com/api/",
            gameId: 256,
            category: "Cockfight",
        },
        // Custom game
        {
            gamesSwitch: "EZGame",
            img: "ezgame",
            name: "EZGame",
            name2: "EZGame",
            url: "ezgame",
            api: "http://ezgame.k-api.com/",
            gameId: "",
            category: "",
        },
    ];

    return games;
}

export function slotInfo({
    provider,
    language,
    gameId,
    webId,
    username,
    sessionId,
    imageId,
    remark,
    currency,
    domain,
}) {
    if (provider === "slots_fish") {
        provider = remark;
    }
    const slot = {
        url: "",
        urlFree: "",
        imageUrl: "",
    };
    const awsLink = "https://s3-ap-northeast-1.amazonaws.com/hcgames/content/";

    // Get games info without checking games switch
    const games = gameInfo();
    let api = "";
    for (let i = 0; i < games.length; i++) {
        if (games[i].url === provider) {
            api = games[i].api;
            break;
        }
    }

    switch (provider) {
        case "slots_bestgamer":
        case "slots_afbgaming":
        case "slots_pt":
        case "slots_mg":
        case "slots_netent":
            let path = "loader.php";
            let proId = "&id=5";
            if (remark === "New") {
                path = "launcher";
                proId = "&sid=441";
            }
            slot.url =
                api +
                path +
                "?casinoid=" +
                webId +
                "&username=" +
                username +
                "&game=" +
                gameId +
                "&token=" +
                sessionId +
                "&version=slots&mode=real&platfrom=PC&language=" +
                language +
                proId;
            slot.urlFree =
                api +
                path +
                "?casinoid=" +
                webId +
                "&username=" +
                username +
                "&game=" +
                gameId +
                "&version=slots&mode=real&test=true&token=" +
                sessionId +
                "&platfrom=PC&language=" +
                language +
                proId;
            if (
                provider === "slots_afbgaming" ||
                provider === "slots_bestgamer"
            ) {
                slot.imageUrl = awsLink + "images/afbgaming/" + imageId;
            } else if (provider === "slots_netent") {
                slot.imageUrl = awsLink + "images/netent/" + imageId;
            } else {
                slot.imageUrl = awsLink + "micgaming/img/ig/" + imageId;
            }
            break;
        case "slots_fish":
            slot.url = api + "";
            slot.urlFree = api + "";
            slot.imageUrl = "";
            break;
        case "slots_pplay":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&game_id=" +
                gameId +
                "&platfrom=PC";
            slot.urlFree =
                api +
                "login_free.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&game_id=" +
                gameId +
                "&platfrom=PC&lobbyUrl=" +
                domain +
                "&currency=" +
                currency;
            slot.imageUrl = awsLink + "images/pplay/" + imageId;
            break;
        case "slots_haba_index":
            slot.url = api + "";
            slot.urlFree = api + "";
            slot.imageUrl = awsLink + "micgaming/new_ig/haba/" + imageId;
            break;
        case "slots_joker123":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&game_id=" +
                gameId +
                "&platfrom=PC";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&game_id=" +
                gameId +
                "&platfrom=PC";
            slot.imageUrl = awsLink + "images/joker123_new/" + imageId;
            break;
        case "slots_playstar":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&free=0";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&free=1";
            slot.imageUrl =
                awsLink + "images/playstar/" + language + "/" + imageId;
            break;
        case "slots_dragoonsoft":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&game_id=" +
                gameId +
                "&platfrom=PC";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&game_id=" +
                gameId +
                "&platfrom=PC&isfree=1";
            slot.imageUrl =
                awsLink +
                "images/dragoonsoft/" +
                gameId +
                "_" +
                language +
                ".png";
            break;
        case "slots_pg":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&free=0";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&free=1";
            slot.imageUrl = awsLink + "images/pgslots/" + gameId + ".png";
            break;
        case "slots_cq9":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&test=true";
            slot.imageUrl = awsLink + "images/cq9/" + imageId;
            break;
        case "slots_icg":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&isFree=1";
            slot.imageUrl = awsLink + "images/icg_slots/" + imageId;
            break;
        case "slots_fc":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&game_id=" +
                gameId +
                "&platfrom=PC&isFree=0";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&game_id=" +
                gameId +
                "&platfrom=PC&isFree=1";
            slot.imageUrl = awsLink + "images/fc_slots/" + imageId;
            break;
        case "slots_jili":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&isFree=0";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&isFree=1";
            slot.imageUrl =
                awsLink +
                "images/jili_slots/200x200/" +
                gameId +
                "_" +
                language +
                ".png";
            break;
        case "slots_kagaming":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&isFree=0";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&isFree=1";
            slot.imageUrl = awsLink + "images/kagaming/" + imageId;
            break;
        case "slots_rich88":
            slot.url =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&isFree=0";
            slot.urlFree =
                api +
                "login.php?token=" +
                sessionId +
                "&web_id=" +
                webId +
                "&username=" +
                username +
                "&language=" +
                language +
                "&gameid=" +
                gameId +
                "&platfrom=PC&isFree=1";
            slot.imageUrl = awsLink + "images/rich88/" + imageId;
            break;
        default:
            break;
    }

    return slot;
}

export function template(webId) {

    const template = {
        'Kg': [
            995,
        ],
    }

    for (const tem in template) {
        if (template[tem].indexOf(webId)) {
            return tem;
        }
    }

    return '';
}
const tryRequire = (fullPath) => {
    try {
        return require(fullPath);
    } catch (err) {
        console.log(err)
        return null;
    }
};
export function fileDir(path) {
    const dir = template('995');
    const fullPath = `${path + dir}`;


    if (tryRequire(fullPath)) {
        return React.lazy(() => import(fullPath));
    }
    return React.lazy(() => import(`${path + 'Kg'}`));
}

