import { Form, Button, Container, FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

function Register(props) {
	const [formData, setFormData] = useState({
		username: "",
		password: "",
		cPassword: "",
		phone: "",
		currency: "",
		referral: "",
	});
	const [error, setError] = useState([]);
	const navigate = useNavigate();
	const auth = useContext(AuthContext);

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormData((prevFormData) => {
			return {
				...prevFormData,
				[name]: type === "checkbox" ? checked : value,
			};
		});
		setError((prevError) => {
			return {
				...prevError,
				[name]: "",
			};
		});
	};
	const validate = (event) => {
		const { username, password, cPassword, phone, currency } = event.target;
		let errors = {};
		if (!/^[a-zA-Z0-9]+$/.test(username.value) || username.value === "") {
			errors.username =
				props.t('register.Username cannot be empty and contain only alphanumeric');
			setError(errors);
			username.focus();
			return false;
		}
		if (password.value === "") {
			errors.password = props.t('register.Password cannot be empty');
			password.focus();
			setError(errors);
			return false;
		}
		if (
			password.value.length < 8 ||
			password.value.length > 19 ||
			password.value.indexOf(" ") >= 0
		) {
			errors.password = props.t('register.Password should be within 8-20 alphanumeric without spacing');
			password.focus();
			setError(errors);
			return false;
		}
		if (cPassword.value === "") {
			errors.cPassword = props.t('register.Confirm password must be the same as password');
			cPassword.focus();
			setError(errors);
			return false;
		}
		if (password.value !== cPassword.value) {
			errors.cPassword = props.t('register.Confirm password must be the same as password');
			cPassword.focus();
			setError(errors);
			return false;
		}
		if (!phone.value.match("([0-9]{6,12})$")) {
			errors.phone = props.t('register.Please enter a valid contact number with length between 6 to 12');
			phone.focus();
			setError(errors);
			return false;
		}
		if (currency.value === "") {
			errors.currency = props.t('register.Please choose a currency');
			currency.focus();
			setError(errors);
			return false;
		}

		return true;
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (!validate(event)) return false;

		auth.register(formData, () => navigate("/", { replace: true }));
	};

	return (
		<Container className="d-flex justify-content-center mt-5">
			<Form
				noValidate
				onSubmit={handleSubmit}
				className="form-register rounded-2"
			>
				<div className="p-3 bg-primary text-center title-register">
					{props.t('register.Register')}
				</div>
				<div className="p-4">
					<FloatingLabel controlId="username" label={props.t('register.Username')} className="mb-2">
						<Form.Control
							type="text"
							placeholder="Username"
							name="username"
							onChange={handleChange}
							value={formData.username}
							maxLength={15}
							required
						/>
						{error.username && (
							<div className="invalid-feedback d-block">{error.username}</div>
						)}
					</FloatingLabel>

					<FloatingLabel controlId="password" label={props.t('register.Password')} className="mb-1">
						<Form.Control
							type="password"
							placeholder="Password"
							name="password"
							onChange={handleChange}
							value={formData.passsword}
							required
						/>
						{error.password && (
							<div className="invalid-feedback d-block">{error.password}</div>
						)}
					</FloatingLabel>

					<FloatingLabel
						controlId="cPassword"
						label={props.t('register.Confirm Password')}
						className="mb-1"
					>
						<Form.Control
							type="password"
							placeholder="Confirm Password"
							name="cPassword"
							onChange={handleChange}
							value={formData.cPassword}
							required
						/>
						{error.cPassword && (
							<div className="invalid-feedback d-block">{error.cPassword}</div>
						)}
					</FloatingLabel>

					<FloatingLabel
						controlId="contact"
						label={props.t('register.Contact Number')}
						className="mb-1"
					>
						<Form.Control
							type="text"
							placeholder="Contact Number"
							name="phone"
							onChange={handleChange}
							value={formData.phone}
							required
						/>
						{error.phone && (
							<div className="invalid-feedback d-block">{error.phone}</div>
						)}
					</FloatingLabel>

					<FloatingLabel controlId="currency" label={props.t('register.Currency')} className="mb-1">
						<Form.Select
							aria-label="Currency"
							name="currency"
							onChange={handleChange}
							value={formData.currency}
						>
							<option value="">{props.t('register.Please choose a currency')}</option>
							<option value="1">USD</option>
							<option value="4">THB</option>
							<option value="11">IDR</option>
						</Form.Select>
						{error.currency && (
							<div className="invalid-feedback d-block">{error.currency}</div>
						)}
					</FloatingLabel>

					<FloatingLabel controlId="referral" label={props.t('register.Referral')} className="mb-1">
						<Form.Control
							type="text"
							name="referral"
							placeholder="Referral"
							onChange={handleChange}
							value={formData.referral}
						/>
					</FloatingLabel>
					<Button
						type="submit"
						className="w-100 bg-primary btn-reg"
						variant="light"
					>
						{props.t('register.Submit')}
					</Button>
				</div>
			</Form>
		</Container>
	);
}

export default Register;
