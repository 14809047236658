import { useEffect } from "react";
import { useState } from "react";
import { Table, Container, Row, Col, Spinner } from "react-bootstrap";
import { depositRecord as depositRecordInApi } from "../../../../api";

import MenuKg from "../../../../components/menu/MenuKg";
import DepositMenuKg from "../depositMenu/DeposiMenuKg";

const DepositRecordKg = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);

    const status = ['Submitting', 'Completed', 'Processing', 'Expired', 'Canceled', 'Pending'];
    useEffect(() => {
        const depositRecord = async () => {
            setLoading(true);
            const response = await depositRecordInApi();
            if (response.data) {
                setRecord(response.data);
            }
            setLoading(false);
        }
        depositRecord();
    }, [])

    return (
        <>
            <Container className="container-lg member-info my-3 mb-5" fluid>
                <Row>
                    <Col lg={3}>
                        <MenuKg t={props.t} />
                    </Col>
                    <Col lg={9}>
                        <Row className="py-2 px-3">
                            <DepositMenuKg t={props.t} />
                            <Table striped hover className="mt-4">
                                <thead>
                                    <tr className="thead-bg">
                                        <th>{props.t('deposit.No')}.</th>
                                        <th>{props.t('deposit.DateTime')}</th>
                                        <th>{props.t('deposit.Bank Name')}</th>
                                        <th>{props.t('deposit.Amount')}</th>
                                        <th>{props.t('deposit.Status')}</th>
                                        <th>{props.t('deposit.Remark')}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="6">
                                                    <Spinner
                                                        className="d-block mx-auto"
                                                        variant="standard"
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" />
                                                </td>
                                            </tr> :
                                            record.length > 0 ?
                                                record.map((rec, index) =>
                                                    <tr key={`d-record-${index}`}>
                                                        <td>{rec.id_mod_deposit_withdraw}</td>
                                                        <td>{rec.date_time}</td>
                                                        <td>{rec.bank_name}</td>
                                                        <td>{rec.amount}</td>
                                                        <td>{props.t('record.' + status[rec.status])}</td>
                                                        <td>{rec.trs_info}</td>
                                                    </tr>
                                                ) :
                                                <tr><td colSpan="6" className="text-center">No record found !</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DepositRecordKg;