import { useState } from "react";
import { Container, Row, Form, Button, Spinner } from "react-bootstrap";
import {
    referralBonus as referralBonusInApi,
} from "../../../../api";
import { gameSwitch as gameSwitchInApi } from "../../../../api";
import { toast } from "react-toastify";
import MemberNav from "../../MemberNav";
import BonusMenu from "../bonusMenu/BonusMenu";
import { useEffect } from "react";

const ReferralBonus = (props) => {
    const [formData, setFormData] = useState({
        bonusType: "",
        amount: "",
    });
    const [loading, setLoading] = useState(false);
    const [recordStatus, setRecordStatus] = useState(1);
    const [games, setGames] = useState([]);

    useEffect(() => {
        const gameSwitch = async () => {
            const response = await gameSwitchInApi()
            setGames(response);
        }
        gameSwitch()
    }, []);

    useEffect(() => {
        const referralBonus = async () => {
            const response = await referralBonusInApi();
            console.log(response);
        };
    }, [recordStatus]);

    // const bonus = async (data) => {
    //     setLoading(true);
    //     const response = await bonusInApi(data);
    //     if (response.code) {
    //         toast.success(`You have claimed your bonus.`);
    //     }
    //     setLoading(false);
    // };
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setRecordStatus((prev) => prev + 1);
        if (!formData.bonusType) {
            alert("Please choose a provider");
            return false;
        }
        // bonus(formData);

    };

    return (
        <>
            <Container className="password-form d-flex">
                <MemberNav />
                <Row className="py-2 flex-grow-1 d-flex flex-column">
                    <BonusMenu />
                    <Form className="mt-4">
                        <Form.Group
                            className="mb-3 d-flex align-items-center"
                            controlId="bankaccount"
                        >
                            <Form.Label column sm="3" className="text-white">
                                Provider:
                            </Form.Label>
                            <Form.Select
                                aria-label="bank"
                                onChange={(e) => handleChange(e)}
                                name="bonusType"
                            >
                                <option>Please choose a provider</option>
                                {
                                    games &&
                                    games.map((game, index) => <option value={`1`} key={`pro-ref-${index}`}>{game.name}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex">
                            <Form.Label column sm="3" className="text-white"></Form.Label>
                            <Button
                                type="submit"
                                variant="light"
                                className="btn bg-primary"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? (
                                    <Spinner
                                        variant="standard"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </Form.Group>
                    </Form>
                </Row>
            </Container>
        </>
    );
}

export default ReferralBonus;
