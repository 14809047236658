import { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner, Table } from "react-bootstrap";
import {
    bonus as bonusInApi,
    bonusRecord as bonusRecordInApi,
} from "../../../../api";
import { toast } from "react-toastify";

import MenuKg from "../../../../components/menu/MenuKg";
import BonusMenuKg from "../bonusMenu/BonusMenuKg";

import { useEffect } from "react";

const Bonus = (props) => {
    const [formData, setFormData] = useState({
        bonusType: "",
        amount: "",
    });
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState([]);
    const [recordStatus, setRecordStatus] = useState(1);

    useEffect(() => {
        const bonusRecord = async () => {
            const response = await bonusRecordInApi();
            if (response.data) {
                setRecord(response.data);
            }
            console.log(response);
        };
        bonusRecord();
    }, [recordStatus]);
    const bonus = async (data) => {
        setLoading(true);
        const response = await bonusInApi(data);
        if (response.code) {
            toast.success(`${props.t("t.You have claimed your bonus")}`);
        }
        setLoading(false);
    };
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setRecordStatus((prev) => prev + 1);
        if (!formData.bonusType) {
            alert(`${props.t("bonus.Please choose a bonus type")}`);
            return false;
        } else if (formData.amount <= 0) {
            alert(`${props.t("bonus.Amount cannot be empty")}`);
            return false;
        }
        bonus(formData);
    };

    return (
        <>
            <Container className="container-lg member-info my-3 mb-5" fluid>
                <Row>
                    <Col lg={3}>
                        <MenuKg t={props.t} />
                    </Col>
                    <Col lg={9}>
                        <Row className="py-2 px-3">
                            <BonusMenuKg t={props.t} />
                            <Form className="mt-4">
                                <Form.Group
                                    className="mb-3 d-flex align-items-center"
                                    controlId="bankaccount"
                                >
                                    <Form.Label column sm="3">
                                        {props.t("bonus.Bonus Type")}:
                                    </Form.Label>
                                    <Form.Select
                                        aria-label="bank"
                                        onChange={(e) => handleChange(e)}
                                        name="bonusType"
                                    >
                                        <option>
                                            {props.t(
                                                "bonus.Please choose a bonus type"
                                            )}
                                        </option>
                                        <option value="5">
                                            {props.t("bonus.Transfer Bonus")}
                                        </option>
                                        <option value="4">
                                            {props.t("bonus.Withdraw Bonus")}
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group
                                    className="mb-3 d-flex align-items-center"
                                    controlId="amount"
                                >
                                    <Form.Label column sm="3">
                                        {props.t("bonus.Amount")}:
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amount"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 d-flex">
                                    <Form.Label
                                        column
                                        sm="3"
                                        className="text-white"
                                    ></Form.Label>
                                    <Button
                                        type="submit"
                                        className="btn btn-red-gradient px-4 btn-shadow"
                                        onClick={handleSubmit}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <Spinner
                                                variant="standard"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            props.t("bonus.Submit")
                                        )}
                                    </Button>
                                </Form.Group>
                            </Form>
                            <Table striped hover>
                                <thead className="thead-bg">
                                    <tr>
                                        <th>{props.t("bonus.No.")}</th>
                                        <th>{props.t("bonus.Type")}</th>
                                        <th>{props.t("bonus.Time")}</th>
                                        <th>{props.t("bonus.Amount")}</th>
                                        <th>{props.t("bonus.Category")}</th>
                                        <th>{props.t("bonus.Status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <Spinner />
                                    ) : record.length > 0 ? (
                                        record.map((rec, index) => (
                                            <tr key={`d-record-${index}`}>
                                                <td>{index + 1}</td>
                                                <td>Bonus</td>
                                                <td>{rec.time}</td>
                                                <td>
                                                    {parseFloat(rec.amount).toFixed(2)}
                                                </td>
                                                <td>{rec.bonus_type}</td>
                                                <td>{rec.status}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                {props.t("bonus.No record found")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Bonus;
