import { Carousel, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom";

function Main(props) {
    return (
        <>
            <Carousel className="mb-4">
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="https://i.ibb.co/yhVB8Xg/03.jpg"
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="https://i.ibb.co/R4Xq7ht/2.jpg"
                        alt="Second slide"
                    />
                </Carousel.Item>
            </Carousel>
            <Container>
                <Row className="mb-4">
                    <Col><Link to="/game"><img className="w-100 rounded-3" src={`../images/design1/home/game.jpeg`} alt="Game" /></Link></Col>
                    <Col><Link to="/live"><img className="w-100 rounded-3" src={`../images/design1/home/live.jpeg`} alt="Live" /></Link></Col>
                </Row>
                <Row>
                    <Col><Link to="/lottery"><img className="w-100 rounded-3" src={`../images/design1/home/lottery.jpeg`} alt="Lottery" /></Link></Col>
                    <Col><Link to="/sport"><img className="w-100 rounded-3" src={`../images/design1/home/sport.jpeg`} alt="Sport" /></Link></Col>
                </Row>
            </Container>
        </>
    )
}

export default Main