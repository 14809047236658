import { useState } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Navbar,
    Nav,
    Spinner,
    Dropdown
} from "react-bootstrap";

const HeaderKg = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    let balance = parseFloat(props.auth.token.balance).toFixed(2);
    //console.log(props.auth.token);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { username, password } = event.target;

        if (username.value === '') {
            alert(props.t('login.Please enter your username'));
            username.focus();
            return false;
        } else if (password.value === '') {
            alert(props.t('login.Please enter your password'));
            password.focus();
            return false;
        }

        props.signInBtn(username.value, password.value);
    };

    return (
        <Container className="container-lg" fluid>
            <div className="header">
                <Row className="h-100">
                    <Col lg="4" className="d-flex align-items-center">
                        <Link to="/">
                            <img className="logo w-100" src={`../images/logo/logo-khmergaming.svg`} alt="Logo" />
                        </Link>
                    </Col>
                    <Col lg="8" className="d-none d-lg-flex align-items-center mt-4">
                        <Row className="g-2">
                            <Col lg={11} className="justify-content-end">
                                {
                                    props.auth.token.session_id ?
                                        <div className="d-flex align-items-center justify-content-end">
                                            <div className="me-2">
                                                <span className="me-2">{props.t('header.Welcome')}, {props.auth.token.useracc}</span>
                                                <span>{props.t('header.Balance')} ({props.auth.token.currency_name}): {balance}</span>
                                            </div>
                                            <Link to="/deposit" className="btn btn-red-gradient btn-shadow mx-1">
                                                {props.t('header.Deposit')}
                                            </Link>
                                            <Link to="/withdraw" className="btn btn-red-gradient btn-shadow mx-1">
                                                {props.t('header.Withdraw')}
                                            </Link>
                                            <Button
                                                className="btn btn-red-gradient btn-shadow mx-1"
                                                onClick={props.signOutBtn}
                                            >
                                                {props.t('header.Logout')}
                                            </Button>
                                        </div>
                                        :
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-2">
                                                <Col lg="8">
                                                    <Row className="g-2">
                                                        <Col lg={6}>
                                                            <Form.Group className="input-style" controlId="formBasicUsername">
                                                                <i className="bi bi-people"></i>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Username"
                                                                    value={username}
                                                                    name="username"
                                                                    onChange={(e) => setUsername(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <Form.Group className="input-style" controlId="formBasicPassword">
                                                                <i className="bi bi-key"></i>
                                                                <Form.Control
                                                                    type="password"
                                                                    placeholder="Password"
                                                                    value={password}
                                                                    name="password"
                                                                    autoComplete="on"
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col lg={4}>
                                                    <Form.Group className="input-style" controlId="formBasicCode">
                                                        <Form.Control type="text" placeholder="Code" />
                                                    </Form.Group>
                                                </Col> */}
                                                    </Row>
                                                </Col>
                                                <Col lg="2">
                                                    <Button
                                                        variant="light"
                                                        className="btn btn-gold-gradient btn-shadow w-100"
                                                        type="submit"
                                                        disabled={props.auth.loading}
                                                    >
                                                        {props.auth.loading ? (
                                                            <Spinner
                                                                variant="standard"
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            "Login"
                                                        )}
                                                    </Button>
                                                </Col>
                                                <Col lg="2">
                                                    <Link to="/register" className="btn btn-red-gradient btn-shadow w-100">
                                                        {props.t('header.Register')}
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Form>
                                }
                            </Col>
                            <Col lg={1}>
                                <Dropdown className="lang">
                                    <Dropdown.Toggle id="dropdown-basic" className="bg-transparent">
                                        <div className="flag">{props.country[0].flag}</div>
                                        <span className="ms-1">{props.country[0].language}</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {props.country.map((c, index) => index > 0
                                            && <Dropdown.Item
                                                href="#"
                                                onClick={() =>
                                                    props.changeLanguage(c.languageCode === 'hk'
                                                        ? 'tw'
                                                        : c.languageCode)} key={`language-dropdown-${index}`}>{c.flag} <span>{c.language}</span>
                                            </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </div>
            <div className="header-bottom d-none d-lg-block">
                <Navbar variant="light" className="p-0">
                    <Nav className="w-100 d-flex justify-content-center">
                        <Nav.Link as={Link} to="/">
                            {props.t('header.Home')}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/sport">
                            {props.t('header.Sports')}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/casino">
                            {props.t('header.Casino')}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/poker">
                            {props.t('header.Poker')}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/lottery-lobby">{props.t('header.Lottery')}</Nav.Link>
                        <Nav.Link as={Link} to="/slot">
                            {props.t('header.Slots')}
                        </Nav.Link>
                        <Nav.Link as={Link} to="/games/sv388">{props.t('header.Cockfight')}</Nav.Link>
                        {/* <Nav.Link as={Link} to="/games/sv388">{props.t('header.Forex')}</Nav.Link> */}
                        <Nav.Link as={Link} to="/promotion">{props.t('header.Promotions')}</Nav.Link>
                    </Nav>
                </Navbar>
            </div>
        </Container>
    )
}

export default HeaderKg