import { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { signIn as signInApi, register as registerInApi, getBalance as getBalanceInApi } from "../api";
import moment from "moment";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const loginData = JSON.parse(localStorage.getItem("token") || "{}");
	const [token, setToken] = useState(loginData);
	const [loading, setLoading] = useState(false);

	const getBalance = async () => {
		await getBalanceInApi();
	};

	useEffect(() => {
		if (Object.keys(token).length > 0) {
			getBalance();
			const interval = setInterval(() => {
				getBalance();
			}, 120000);
			return () => clearInterval(interval);
		}

	}, [token])

	const signIn = async (username, password, callback) => {
		setLoading(true);
		const response = await signInApi(username, password);
		console.log(response);
		let data = response.data;
		const code = parseInt(response.code);
		const msg = parseInt(response.msg);
		if (code === 1) {
			data['login_time'] = moment().format('YYYY-MM-DD hh:mm:ss');
			localStorage.setItem("token", JSON.stringify(data));
			setToken(data);
			callback();
		}

		setLoading(false);
		return code === 0 ? msg : code;
	};

	const register = async (data, callback) => {
		const response = await registerInApi(data);
		const regData = response.data;
		if (parseInt(response.code) !== 1) {
			toast(response.errMsg, { type: "error" });
			return false;
		}
		if (regData.username === data.username) {
			signIn(data.username, data.password, callback);
		}
	};

	const signOut = (callback) => {
		localStorage.removeItem("token");
		setToken("");
		callback();
	};

	const value = {
		token,
		loading,
		signIn,
		signOut,
		register,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
