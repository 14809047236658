import { gameSwitch as gameSwitchInApi } from "../../../api";
import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const Slot = (props) => {
	const [games, setGames] = useState([]);

	useEffect(() => {
		const gameInfo = async () => {
			const response = await gameSwitchInApi("Slot");
			//console.log(response)

			setGames(response);
		};
		gameInfo();
	}, []);

	return (
		<>
			<Container>
				<Row className="box">
					{
						games.length > 0 &&
						games.map((g, index) =>
							<Col key={`slot-${index}`} sm={4} className="position-relative">
								<div className="box-item">
									<div className="box-item-top">
										<img
											className="box-bg"
											src={`../../images/design1/game/slot/slot-bg.jpg`}
											alt={`slot-bg-${index}`}
										/>
										<img
											className="box-logo"
											src={`../../images/design1/game/slot/logo-${g.img}.png`}
											alt={`slot-logo-${g.img}-${index}`}
										/>
										<img
											className="box-logo-img"
											src={`../../images/design1/game/slot/${g.img}.png`}
											alt={`slot-${g.img}-${index}`}
										/>
									</div>
									<div className="box-item-bottom d-flex align-items-center justify-content-between px-3 py-1">
										<span className="text-white text-uppercase">{g.name}</span>
										<Link to={`/slots/${g.url}`} className="btn">
											{props.t('game.Play Now')}
										</Link>
									</div>
								</div>
							</Col>
						)
					}
				</Row>
			</Container>
		</>
	);
};

export default Slot;
