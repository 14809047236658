import { useEffect } from "react";
import { useState } from "react";
import { Table, Container, Row, Spinner } from "react-bootstrap";
import { transferRecord as transferRecordInApi } from "../../../../api"
import MemberNav from "../../MemberNav";
import TransferMenu from "../transferMenu/TransferMenu";

const TransferRecord = () => {
    const [record, setRecord] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const transferRecord = async () => {
            setLoading(true);
            const response = await transferRecordInApi();
            setRecord(response.data)
            setLoading(false);
        }
        transferRecord();
    }, [])

    const transferId = { 1: "Main Balance", 5: "918Kiss", 20: "We1Poker", 24: "KH Gaming", 25: "XE88", 28: "Mega888", 1036: "KaiYuan" };
    const status = ["Progressing", "Success", "Cancel"];
    return (
        <>
            <Container className="d-flex align-items-start">
                <MemberNav />
                <Row className="py-2 flex-grow-1">
                    <TransferMenu />
                    <Table striped bordered hover variant="light" className="mt-3">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Date & Time</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td colSpan="6">
                                            <Spinner
                                                className="d-block mx-auto"
                                                variant="standard"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" />
                                        </td>
                                    </tr> :
                                    record.length > 0 ?
                                        record.map((rec, index) =>
                                            <tr key={`d-record-${index}`}>
                                                <td>{index + 1}</td>
                                                <td>{rec.insert_time}</td>
                                                <td>{transferId[rec.from_gameid]}</td>
                                                <td>{transferId[rec.to_gameid]}</td>
                                                <td className={rec.amount > 0 ? "text-success" : "text-danger"}>{rec.amount}</td>
                                                <td>{status[rec.status]}</td>
                                            </tr>
                                        ) : <tr><td colSpan="6" className="text-center">No record found !</td></tr>
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>

        </>
    )
}

export default TransferRecord