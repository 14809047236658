import { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Spinner, Table } from "react-bootstrap";
import { transfer as transferInApi, transferBal as transferBalInApi } from "../../../api"
import { toast } from "react-toastify";

import MenuKg from "../../../components/menu/MenuKg";
import TransferMenuKg from "./transferMenu/TransferMenuKg";

function TransferKg(props) {
    const [balance, setBalance] = useState(localStorage.getItem("balance") || 0.00);
    const [formData, setFormData] = useState({
        from: 1,
        to: 1,
        amount: "",
    });
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTransfer, setloadingTransfer] = useState(false);
    const myRef = useRef([]);

    useEffect(() => {
        const transferBal = async () => {
            setLoading(true);
            const response = await transferBalInApi()
            if (response) {
                setGames(response);
            }
            setLoading(false);
        }

        transferBal()
    }, []);
    console.log(games)
    const transfer = async (data) => {
        setloadingTransfer(true);
        const response = await transferInApi(data);
        const code = parseInt(response.code);
        if ([0, 1].includes(code)) {
            toast.success(props.t('transfer.Transfer successfully'));
            setGames((prevGames) => {
                return prevGames.map(game => {
                    if (game.transferId == response.transferId) {
                        if (response.currentBal) {
                            setBalance(response.currentBal)
                        }
                        return { ...game, ...response }
                    }
                    return { ...game }
                })
            })
        } else {
            toast.error(props.t('transfer.Transfer failed'));
        }

        setloadingTransfer(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let newValue = value
        if (['to', 'from'].includes(name)) {
            newValue = parseInt(value)
        }
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: newValue,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { from, to, amount } = event.target;
        const currentBal = parseFloat(balance).toFixed(2);

        if (from.value === to.value) {
            alert(`${props.t('transfer.FROM and TO cannot be the same')}`);
            return false;
        } else if (amount.value <= 0) {
            alert(`${props.t('transfer.Amount cannot be empty')}`);
            amount.focus();
            return false;
        } else if (parseInt(from.value) === 1 && parseFloat(amount.value) > currentBal) {
            alert(`${props.t('transfer.Amount cannot more than Main Balance:') + " " + currentBal}`);
            amount.focus();
            return false;
        } else if (parseInt(from.value) > 1) {
            const gameBal = myRef.current[from.value].getAttribute("data-balance");
            const providerName = myRef.current[from.value].getAttribute("data-name");

            if (parseFloat(amount.value) > parseFloat(gameBal)) {
                alert(`${props.t('transfer.Amount cannot more than') + " " + providerName + ": " + gameBal}`);
                amount.focus();
                return false;
            }

        }
        transfer(formData);
    };
    const balFilter = (data) => {
        let bal = data.balance
        let balText = data.balance
        if (data.m_balance) {
            balText = data.balance + "(" + data.m_balance + ")"
        } else if (data.mem_balance) {
            bal = data.mem_balance
            balText = data.mem_balance
        }

        return [bal, balText];
    }
    return (
        <>
            <Container className="container-lg member-info my-3 mb-5" fluid>
                <Row>
                    <Col lg={3}>
                        <MenuKg t={props.t} />
                    </Col>
                    <Col lg={9}>
                        <Row className="py-2 px-3">
                            <TransferMenuKg t={props.t} />
                            <Row className="mt-4">
                                <Col lg={12}>
                                    <Row className="align-items-center">
                                        <Col lg={3}>
                                            {props.t('transfer.Main Balance')} :
                                        </Col>
                                        <Col lg={6}>
                                            <div className="w-100 form-control mb-2 border-bottom bg-light">
                                                {parseFloat(balance).toFixed(2)}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                {
                                    loading ?
                                        <tr>
                                            <td colSpan="2">
                                                <Spinner
                                                    className="d-block mx-auto"
                                                    variant="standard"
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </td>
                                        </tr> :
                                        games.length > 0 &&
                                        games.map((game, index) => {
                                            const gameBal = balFilter(game)
                                            return (
                                                <Col lg={12}
                                                    className="game_balance"
                                                    data-balance={gameBal[0]}
                                                    data-name={game.name}
                                                    key={`game-transfer-${index}`}
                                                    ref={(e) => {
                                                        myRef.current[game.transferId] = e;
                                                    }}
                                                >
                                                    <Row className="align-items-center">
                                                        <Col lg={3}>
                                                            {game.name} :
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="w-100 form-control mb-2 border-bottom bg-light">
                                                                {gameBal[1]}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })
                                }
                            </Row>
                            <Form className="mt-1 row" onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={3}>
                                            <Form.Label>
                                                {props.t('transfer.From')}:
                                            </Form.Label>
                                        </Col>
                                        <Col lg={6} className="mx-0">
                                            <Form.Select className="w-100" name="from" onChange={(e) => handleChange(e)} value={formData.from}>
                                                <option value="1">{props.t('transfer.Main Balance')}</option>
                                                {
                                                    games.length > 0 &&
                                                    games.map((game, index) =>
                                                        <option key={`from-transfer-${index}`} value={game.transferId}>{game.name}</option>
                                                    )
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={3}>
                                            <Form.Label>
                                                {props.t('transfer.To')}:
                                            </Form.Label>
                                        </Col>
                                        <Col lg={6} className="mx-0">
                                            <Form.Select className="w-100" name="to" onChange={(e) => handleChange(e)} value={formData.to}>
                                                <option value="1">{props.t('transfer.Main Balance')}</option>
                                                {
                                                    games.length > 0 &&
                                                    games.map((game, index) =>
                                                        <option key={`to-transfer-${index}`} value={game.transferId}>{game.name}</option>
                                                    )
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={3}>
                                            <Form.Label>
                                                {props.t('transfer.Amount')}:
                                            </Form.Label>
                                        </Col>
                                        <Col lg={6} className="mx-0">
                                            <Form.Control className="w-100" type="number" name="amount" onChange={(e) => handleChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row className="align-items-center">
                                        <Col lg={3}>
                                            <Form.Label>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={6} className="mx-0">
                                            <Button
                                                type="submit"
                                                variant="light"
                                                className="btn btn-red-gradient w-50"
                                                disabled={loadingTransfer || loading}
                                            >
                                                {loadingTransfer || loading ? (
                                                    <Spinner
                                                        variant="standard"
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    props.t('transfer.Submit')
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default TransferKg;
