import { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { bankAccount, deposit as depositInApi } from "../../../api"
import { toast } from "react-toastify";
import MenuKg from "../../../components/menu/MenuKg";
import DepositMenuKg from "./depositMenu/DeposiMenuKg";

function Deposit(props) {
	const [formData, setFormData] = useState({
		bankaccount: "",
		amount: "",
	});
	const [bank, setBank] = useState({});
	const [loading, setLoading] = useState(false);

	const myRef = useRef([]);

	useEffect(() => {
		bankAccount().then((res) => setBank(res));
	}, []);

	const deposit = async (data) => {
		setLoading(true);
		const response = await depositInApi(data);
		const code = parseInt(response.code);
		if (code === 1) {
			toast.success(`${props.t('deposit.Deposit successfully')}`);
		} else if (code === -2) {
			toast.error(`${props.t('default.Unauthorized access')}`);
		} else {
			toast.error(`${props.t('default.Request failed') + "-" + code}`);
		}
		setLoading(false);
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormData((prevFormData) => {
			return {
				...prevFormData,
				[name]: type === "checkbox" ? checked : value,
			};
		});
	};
	const changeBank = (event) => {
		handleChange(event);
		const bankInfo = event.target[event.target.selectedIndex]
			.getAttribute("data-id")
			.split("#");
		myRef.current.bankAccName.value = bankInfo[0];
		myRef.current.bankAccNum.value = bankInfo[1];
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const { bankaccount, amount } = event.target;
		const min = parseFloat(bankaccount[bankaccount.selectedIndex].getAttribute("data-min"));
		const max = parseFloat(bankaccount[bankaccount.selectedIndex].getAttribute("data-max"));
		console.log(amount.value, max);
		if (bankaccount.value === "") {
			alert(`${props.t('deposit.Please choose a bank')}`);
			bankaccount.focus();
			return false;
		}
		if (amount.value === "") {
			alert(`${props.t('deposit.Amount cannot be empty')}`);
			amount.focus();
			return false;
		}

		if (parseFloat(amount.value) < min) {
			alert(`${props.t('deposit.Amount must be more than') + " " + min}`);
			amount.focus();
			return false;
		}

		if (parseFloat(amount.value) > max) {
			alert(`${props.t('deposit.Amount cannot more than') + " " + max}`);
			amount.focus();
			return false;
		}

		deposit(formData);
	};

	//console.log(bank);
	return (
		<>
			<Container className="container-lg member-info my-3 mb-5" fluid>
				<Row>
					<Col lg={3}>
						<MenuKg t={props.t} />
					</Col>
					<Col lg={9}>
						<Row className="py-2 px-3">
							<DepositMenuKg t={props.t} />
							<Form className="mt-4" onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="bankaccount">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label>
												{props.t('deposit.Bank')}:
											</Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Select
												aria-label="bank"
												onChange={changeBank}
												name="bankaccount"
											>
												<option value="">{props.t('deposit.Please choose a bank')}</option>
												{
													bank.length > 0 &&
													bank.map((item, index) =>
														<option
															key={index}
															value={item.id_mod_bank_account}
															data-id={item.account + "#" + item.no}
															data-min={item.min_deposit}
															data-max={item.max_deposit}
														>
															{item.bank_name}
														</option>
													)
												}
											</Form.Select>
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label>
												{props.t('deposit.Bank Account Name')}:
											</Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Control
												type="text"
												ref={(element) => {
													myRef.current["bankAccName"] = element;
												}}
												readOnly
											/>
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label>
												{props.t('deposit.Bank Account Number')}:
											</Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Control
												type="number"
												ref={(element) => {
													myRef.current["bankAccNum"] = element;
												}}
												readOnly
											/>
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3" controlId="amount">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label>
												{props.t('deposit.Amount')}:
											</Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Control
												type="number"
												onChange={handleChange}
												name="amount"
											/>
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label column xs="3"></Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Form.Control type="file" />
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3">
									<Row className="align-items-center">
										<Col xs={4} sm={3}>
											<Form.Label column xs="3"></Form.Label>
										</Col>
										<Col xs={8} sm={9}>
											<Button
												type="submit"
												className="btn btn-red-gradient px-4 btn-shadow"
												disabled={loading}
											>
												{loading ? (
													<Spinner
														variant="standard"
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/>
												) : (
													props.t('deposit.Submit')
												)}
											</Button>
										</Col>
									</Row>
								</Form.Group>
							</Form>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Deposit;
