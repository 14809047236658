import { Carousel, Container } from "react-bootstrap"

const CustomCarousel = () => {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={`../images/kg/banner/home.jpg`}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={`../images/kg/banner/home.jpg`}
                        alt="Second slide"
                    />
                </Carousel.Item>
            </Carousel>
            <Container className="container-lg" fluid>
                <div className="notice center mb-5">
                    <i className="bi bi-megaphone"></i>
                    <marquee><a href="">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ...</a></marquee>
                </div>
            </Container>
        </>
    )
}

export default CustomCarousel;