import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    header: {
                        Welcome: "Welcome",
                        Balance: "Balance",
                        Deposit: "Deposit",
                        Withdraw: "Withdraw",
                        Logout: "Logout",
                        Home: "Home",
                        Sports: "Sports",
                        Casino: "Casino",
                        Poker: "Poker",
                        Lottery: "Lottery",
                        Slots: "Slots",
                        Cockfight: "Cockfight",
                        Forex: "Forex",
                        Promotions: "Promotions",
                        Register: "Register",
                        Affilliate: "Affilliate",
                    },
                    game: {
                        "Play Now": "Play Now",
                    },
                    memberNav: {
                        "Basic Information": "Basic Information",
                        Deposit: "Deposit",
                        Withdraw: "Withdraw",
                        "Bet Record": "Bet Record",
                        Statement: "Statement",
                        "Change Password": "Change Password",
                        Bonus: "Bonus",
                        Transfer: "Transfer",
                        Turnover: "Turnover",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "Please choose a bank",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount must be more than": "Amount must be more than",
                        "Amount cannot more than": "Amount cannot more than",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Deposit successfully": "Deposit successfully",
                        Amount: "Amount",
                        Submit: "Submit",
                        Deposit: "Deposit",
                        "Deposit List": "Deposit List",
                        No: "No",
                        DateTime: "Date & Time",
                        "Bank Name": "Bank Name",
                        Status: "Status",
                        Remark: "Remark",
                    },
                    with: {
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount must be more than": "Amount must be more than",
                        "Amount cannot more than": "Amount cannot more than",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Withdraw successfully": "Withdraw successfully",
                        Amount: "Amount",
                        Submit: "Submit",
                        Withdraw: "Withdraw",
                        "Withdraw List": "Withdraw List",
                        No: "No",
                        DateTime: "Date & Time",
                        "Bank Name": "Bank Name",
                        Status: "Status",
                        Remark: "Remark",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "Submitting",
                        Completed: "Completed",
                        Processing: "Processing",
                        Expired: "Expired",
                        Canceled: "Canceled",
                        Pending: "Pending",
                    },
                    memberInfo: {
                        "Member Information": "Member Information",
                        Username: "Username",
                        "Referral Link": "Referral Link",
                        "E-mail": "E-mail",
                        Phone: "Phone",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Total Bonus": "Total Bonus",
                        "Last Bonus": "Last Bonus",
                        Balance: "Balance",
                    },
                    betRecord: {
                        Provider: "Provider",
                        Submit: "Submit",
                    },
                    statement: {
                        Provider: "Provider",
                        Submit: "Submit",
                    },
                    date: {
                        Today: "Today",
                        Yesterday: "Yesterday",
                        "This Week": "This Week",
                        "Last Week": "Last Week",
                        "This Month": "This Month",
                        "Last Month": "Last Month",
                        "Start Date": "Start Date",
                        "End Date": "End Date",
                    },
                    table: {
                        "No.": "No.",
                        Game: "Game",
                        "Game ID": "Game ID",
                        "Bet Detail": "Bet Detail",
                        "Bet Time": "Bet Time",
                        "Bet Amount": "Bet Amount",
                        "WinLoss": "WinLoss",
                        "Bet Type": "Bet Type",
                        TypeL: "Type",
                        Pool: "Pool",
                        Result: "Result",
                        Odds: "Odds",
                        "Bet On": "Bet On",
                        "TRS ID": "TRS ID",
                        "No record found": "No record found!",
                    },
                    password: {
                        "Your password has been changed": "Your password has been changed.",
                        "Please enter your current password": "Please enter your current password.",
                        "Please enter your new password": "Please enter your new password.",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "Password can only contain alphabet and numbers ,length must be more than 8.",
                        "Confirm password cannot be empty": "Confirm password cannot be empty.",
                        "New password and confirm password must be the same": "New password and confirm password must be the same.",
                        "Change Password": "Change Password",
                        "Current Password": "Current Password",
                        "New Password": "New Password",
                        "Confirm Password": "Confirm Password",
                        "Submit": "Submit",
                    },
                    turnover: {
                        "Please select a provider": "Please select a provider",
                        Turnover: "Turnover",
                        Provider: "Provider",
                        "Select All": "Select All",
                        Casino: "Casino",
                        Sports: "Sports",
                        Slots: "Slots",
                        Lottery: "Lottery",
                        Submit: "Submit",
                        "No.": "No.",
                        "Start Time": "Start Time",
                        "End Time": "End Time",
                        "No record found": "No record found",
                    },
                    transfer: {
                        "Transfer successfully": "Transfer successfully",
                        "Transfer failed": "Transfer failed",
                        "FROM and TO cannot be the same": "FROM and TO cannot be the same",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount cannot more than Main Balance": "Amount cannot more than Main Balance",
                        "Amount cannot more than": "Amount cannot more than",
                        "Main Balance": "Main Balance",
                        From: "From",
                        To: "To",
                        Amount: "Amount",
                        Submit: "Submit",
                    },
                    bonus: {
                        Bonus: "Bonus",
                        "Referral List": "Referral List",
                        "Referral Bonus": "Referral Bonus",
                        "Referral Bonus Record": "Referral Bonus Record",
                        "Promotion Record": "Promotion Record",
                        "Auto Promotion Record": "Auto Promotion Record",
                        "You have claimed your bonus": "You have claimed your bonus.",
                        "Please choose a bonus type": "Please choose a bonus type",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Bonus Type": "Bonus Type",
                        "Transfer Bonus": "Transfer Bonus",
                        "Withdraw Bonus": "Withdraw Bonus",
                        "No.": "No.",
                        Type: "Type",
                        Time: "Time",
                        Amount: "Amount",
                        Category: "Category",
                        Status: "Status",
                        Submit: "Submit",
                        Title: "Title",
                        "Join Date": "Join Date",
                        "Start Date": "Start Date",
                        "End Date": "End Date",
                        Turnover: "Turnover",
                        "No record found": "No record found",
                        Submitting: "Submitting",
                        Completed: "Completed",
                        Closed: "Closed",
                        Expired: "Expired",
                        Processing: "Processing",
                        "End Time": "End Time",
                        "Transfer Count": "Transfer Count",
                        "Game name": "Game name",
                        Username: "Username",
                        Currency: "Currency",
                        "Register Time": "Register Time",
                        "Auto Promotion Turnover": "Auto Promotion Turnover",
                        "Auto Promotion Win Loss": "Auto Promotion Win Loss",
                    },
                    login: {
                        "Please enter your username": "Please enter your username",
                        "Please enter your password": "Please enter your password",
                        "Username or password is incorrect": "Username or password is incorrect",
                        "User has been suspended": "User has been suspended",
                        "You have attempted to login too many times. Please try again in 1 minute": "You have attempted to login too many times. Please try again in 1 minute.",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "Username cannot be empty and contain only alphanumeric",
                        "Password cannot be empty": "Password cannot be empty",
                        "Password should be within 8-20 alphanumeric without spacing": "Password should be within 8-20 alphanumeric without spacing",
                        "Confirm password must be the same as password": "Confirm password must be the same as password",
                        "Please enter a valid contact number with length between 6 to 12": "Please enter a valid contact number with length between 6 to 12",
                        "Please choose a currency": "Please choose a currency",
                        Register: "Register",
                        Username: "Username",
                        Password: "Password",
                        "Confirm Password": "Confirm Password",
                        "Contact Number": "Contact Number",
                        Currency: "Currency",
                        Referral: "Referral",
                        Submit: "Submit",
                    },
                    default: {
                        "Unauthorized access": "Unauthorized access",
                        "Request failed": "Request failed",
                    }
                }
            },
            cn: {
                translation: {
                    header: {
                        Welcome: "欢迎",
                        Balance: "余额",
                        Deposit: "存款",
                        Withdraw: "取款",
                        Logout: "登出",
                        Home: "首页",
                        Sports: "体育博彩",
                        Casino: "真人娱乐",
                        Poker: "扑克游戏",
                        Lottery: "彩票",
                        Slots: "电子游戏",
                        Cockfight: "斗鸡",
                        Forex: "Forex",
                        Promotions: "优惠",
                        Register: "注册",
                        Affilliate: "代理",
                    },
                    game: {
                        "Play Now": "进入游戏",
                    },
                    memberNav: {
                        "Basic Information": "会员信息",
                        Deposit: "存款",
                        Withdraw: "取款",
                        "Bet Record": "下注记录",
                        Statement: "完成记录",
                        "Change Password": "修改密码",
                        Bonus: "红利",
                        Transfer: "转账",
                        Turnover: "检查洗码",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "请选择银行",
                        "Amount cannot be empty": "金额不能为空",
                        "Amount must be more than": "金额不能小于",
                        "Amount cannot more than": "金额不能多过",
                        Bank: "银行",
                        "Bank Account Name": "银行账号名",
                        "Bank Account Number": "银行账号",
                        "Deposit successfully": "存款成功",
                        Amount: "金额",
                        Submit: "提交",
                        Deposit: "存款",
                        "Deposit List": "存款列表",
                        No: "编号",
                        DateTime: "时间",
                        "Bank Name": "银行名",
                        Status: "状态",
                        Remark: "备注",
                    },
                    with: {
                        "Amount cannot be empty": "金额不能为空",
                        "Amount must be more than": "金额不能小于",
                        "Amount cannot more than": "金额不能多过",
                        Bank: "银行",
                        "Bank Account Name": "银行账号名",
                        "Bank Account Number": "银行账号",
                        "Withdraw successfully": "取款成功",
                        Amount: "金额",
                        Submit: "提交",
                        Withdraw: "取款",
                        "Withdraw List": "取款列表",
                        No: "编号",
                        DateTime: "时间",
                        "Bank Name": "银行名",
                        Status: "状态",
                        Remark: "备注",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "提交中",
                        Completed: "已完成",
                        Processing: "进行中",
                        Expired: "无效",
                        Canceled: "取消",
                        Pending: "进心中",
                    },
                    memberInfo: {
                        "Member Information": "会员信息",
                        Username: "用户名",
                        "Referral Link": "推荐人链接",
                        "E-mail": "邮件",
                        Phone: "电话号码",
                        Bank: "银行",
                        "Bank Account Name": "银行账号名",
                        "Bank Account Number": "银行账号",
                        "Total Bonus": "总红利",
                        "Last Bonus": "红利",
                        Balance: "余额",
                    },
                    betRecord: {
                        Provider: "厂商",
                        Submit: "提交",
                    },
                    statement: {
                        Provider: "厂商",
                        Submit: "提交",
                    },
                    date: {
                        Today: "今天",
                        Yesterday: "昨天",
                        "This Week": "本周",
                        "Last Week": "上周",
                        "This Month": "本月",
                        "Last Month": "上月",
                        "Start Date": "开始日期",
                        "End Date": "结束日期",
                    },
                    table: {
                        "No.": "编号.",
                        Game: "游戏",
                        "Game ID": "游戏 ID",
                        "Bet Detail": "下注详细",
                        "Bet Time": "下注时间",
                        "Bet Amount": "下注金额",
                        "WinLoss": "输赢",
                        "Bet Type": "下注类型",
                        TypeL: "类型",
                        Pool: "池",
                        Result: "结果",
                        Odds: "赔率",
                        "Bet On": "下注",
                        "TRS ID": "TRS ID",
                        "No record found": "没有找到记录!",
                    },
                    password: {
                        "Your password has been changed": "您的密码已被更改。",
                        "Please enter your current password": "请输入您的当前密码。",
                        "Please enter your new password": "请输入您的新密码。",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "密码只能包含字母和数字，长度必须大于8。",
                        "Confirm password cannot be empty": "确认密码不能为空。",
                        "New password and confirm password must be the same": "新密码和确认密码必须相同。",
                        "Change Password": "更改密码",
                        "Current Password": "当前密码",
                        "New Password": "新密码",
                        "Confirm Password": "确认密码",
                        Submit: "提交",
                    },
                    turnover: {
                        "Please select a provider": "请选着厂商",
                        Turnover: "流水",
                        Provider: "厂商",
                        "Select All": "全部",
                        Casino: "真人娱乐",
                        Sports: "体育博彩",
                        Slots: "电子游戏",
                        Lottery: "彩票",
                        Submit: "提交",
                        "No.": "编号",
                        "Start Time": "开始日期",
                        "End Time": "结束日期",
                        "No record found": "没有找到记录!",
                    },
                    transfer: {
                        "Transfer successfully": "转账成功",
                        "Transfer failed": "转账失败",
                        "FROM and TO cannot be the same": "从和至不能一样",
                        "Amount cannot be empty": "金额不能为空",
                        "Amount cannot more than Main Balance": "金额不能多过余额",
                        "Amount cannot more than": "金额不能多过",
                        "Main Balance": "余额",
                        From: "从",
                        To: "至",
                        Amount: "金额",
                        Submit: "提交",
                    },
                    bonus: {
                        Bonus: "红利",
                        "Referral List": "推荐人列表",
                        "Referral Bonus": "推荐人红利",
                        "Referral Bonus Record": "推荐人红利列表",
                        "Promotion Record": "优惠列表",
                        "Auto Promotion Record": "自动优惠列表",
                        "You have claimed your bonus": "您已领取奖金。",
                        "Please choose a bonus type": "请选择红利类型",
                        "Amount cannot be empty": "金额不能为空",
                        "Bonus Type": "红利类型",
                        "Transfer Bonus": "转账红利",
                        "Withdraw Bonus": "取款红利",
                        "No.": "编号",
                        Type: "类型",
                        Time: "时间",
                        Amount: "金额",
                        Category: "类别",
                        Status: "状态",
                        Submit: "提交",
                        Title: "标题",
                        "Join Date": "领取时间",
                        "Start Date": "开始日期",
                        "End Date": "结束日期",
                        Turnover: "流水",
                        "No record found": "没有找到记录!",
                        Submitting: "提交中",
                        Completed: "已完成",
                        Processing: "进行中",
                        Expired: "无效",
                        Closed: "已关闭",
                        "End Time": "结束时间",
                        "Transfer Count": "转账次数",
                        "Game name": "游戏名",
                        Username: "用户名",
                        Currency: "币种",
                        "Register Time": "注册时间",
                        "Auto Promotion Turnover": "自动优惠的流水",
                        "Auto Promotion Win Loss": "自动优惠的输赢",
                    },
                    login: {
                        "Please enter your username": "请输入你的用户名",
                        "Please enter your password": "请输入你的密码",
                        "Username or password is incorrect": "用户名或密码不正确",
                        "User has been suspended": "你的账号已冻结",
                        "You have attempted to login too many times. Please try again in 1 minute": "您尝试登录的次数过多。 请在 1 分钟后重试。",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "用户名不能为空且仅包含字母数字",
                        "Password cannot be empty": "密码不能为空",
                        "Password should be within 8-20 alphanumeric without spacing": "密码应在 8-20 位字母数字以内，无空格",
                        "Confirm password must be the same as password": "确认密码必须与密码相同",
                        "Please enter a valid contact number with length between 6 to 12": "请输入长度在 6 到 12 之间的有效电话号码",
                        "Please choose a currency": "请选择币别",
                        Register: "注册",
                        Username: "用户名",
                        Password: "密码",
                        "Confirm Password": "确认密码",
                        "Contact Number": "电话号码",
                        Currency: "币别",
                        Referral: "推荐人",
                        Submit: "提交",
                    },
                    default: {
                        "Unauthorized access": "会员已经登出",
                        "Request failed": "请求失败",
                    }
                }
            },
            tw: {
                translation: {
                    header: {
                        Welcome: "歡迎",
                        Balance: "餘額",
                        Deposit: "存款",
                        Withdraw: "取款",
                        Logout: "登出",
                        Home: "首頁",
                        Sports: "體育博彩",
                        Casino: "真人娛樂",
                        Poker: "撲克遊戲",
                        Lottery: "彩票",
                        Slots: "電子遊戲",
                        Cockfight: "鬥雞",
                        Forex: "Forex",
                        Promotions: "優惠",
                        Register: "註冊",
                        Affilliate: "代理",
                    },
                    game: {
                        "Play Now": "進入遊戲",
                    },
                    memberNav: {
                        "Basic Information": "會員信息",
                        Deposit: "存款",
                        Withdraw: "取款",
                        "Bet Record": "下註記錄",
                        Statement: "完成記錄",
                        "Change Password": "修改密碼",
                        Bonus: "紅利",
                        Transfer: "轉賬",
                        Turnover: "檢查洗碼",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "請選擇銀行",
                        "Amount cannot be empty": "金額不能為空",
                        "Amount must be more than": "金額不能小於",
                        "Amount cannot more than": "金額不能多過",
                        Bank: "銀行",
                        "Bank Account Name": "銀行賬號名",
                        "Bank Account Number": "銀行賬號",
                        "Deposit successfully": "存款成功",
                        Amount: "金額",
                        Submit: "提交",
                        Deposit: "存款",
                        "Deposit List": "存款列表",
                        No: "編號",
                        DateTime: "時間",
                        "Bank Name": "銀行名",
                        Status: "狀態",
                        Remark: "備註",
                    },
                    with: {
                        "Amount cannot be empty": "金額不能為空",
                        "Amount must be more than": "金額不能小於",
                        "Amount cannot more than": "金額不能多過",
                        Bank: "銀行",
                        "Bank Account Name": "銀行賬號名",
                        "Bank Account Number": "銀行賬號",
                        "Withdraw successfully": "取款成功",
                        Amount: "金額",
                        Submit: "提交",
                        Withdraw: "取款",
                        "Withdraw List": "取款列表",
                        No: "編號",
                        DateTime: "時間",
                        "Bank Name": "銀行名",
                        Status: "狀態",
                        Remark: "備註",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "提交中",
                        Completed: "已完成",
                        Processing: "進行中",
                        Expired: "無效",
                        Canceled: "取消",
                        Pending: "進心中",
                    },
                    memberInfo: {
                        "Member Information": "會員信息",
                        Username: "用戶名",
                        "Referral Link": "推薦人鏈接",
                        "E-mail": "郵件",
                        Phone: "電話號碼",
                        Bank: "銀行",
                        "Bank Account Name": "銀行賬號名",
                        "Bank Account Number": "銀行賬號",
                        "Total Bonus": "總紅利",
                        "Last Bonus": "紅利",
                        Balance: "餘額",
                    },
                    betRecord: {
                        Provider: "廠商",
                        Submit: "提交",
                    },
                    statement: {
                        Provider: "廠商",
                        Submit: "提交",
                    },
                    date: {
                        Today: "今天",
                        Yesterday: "昨天",
                        "This Week": "本週",
                        "Last Week": "上週",
                        "This Month": "本月",
                        "Last Month": "上月",
                        "Start Date": "開始日期",
                        "End Date": "結束日期",
                    },
                    table: {
                        "No.": "編號.",
                        Game: "遊戲",
                        "Game ID": "遊戲 ID",
                        "Bet Detail": "下注詳細",
                        "Bet Time": "下注時間",
                        "Bet Amount": "下注金額",
                        "WinLoss": "輸贏",
                        "Bet Type": "下注類型",
                        TypeL: "類型",
                        Pool: "池",
                        Result: "結果",
                        Odds: "賠率",
                        "Bet On": "下注",
                        "TRS ID": "TRS ID",
                        "No record found": "沒有找到記錄!",
                    },
                    password: {
                        "Your password has been changed": "您的密碼已被更改。",
                        "Please enter your current password": "請輸入您的當前密碼。",
                        "Please enter your new password": "請輸入您的新密碼。",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "密碼只能包含字母和數字，長度必須大於8。",
                        "Confirm password cannot be empty": "確認密碼不能為空。",
                        "New password and confirm password must be the same": "新密碼和確認密碼必須相同。",
                        "Change Password": "更改密碼",
                        "Current Password": "當前密碼",
                        "New Password": "新密碼",
                        "Confirm Password": "確認密碼",
                        Submit: "提交",
                    },
                    turnover: {
                        "Please select a provider": "請選著廠商",
                        Turnover: "流水",
                        Provider: "廠商",
                        "Select All": "全部",
                        Casino: "真人娛樂",
                        Sports: "體育博彩",
                        Slots: "電子遊戲",
                        Lottery: "彩票",
                        Submit: "提交",
                        "No.": "編號",
                        "Start Time": "開始日期",
                        "End Time": "結束日期",
                        "No record found": "沒有找到記錄!",
                    },
                    transfer: {
                        "Transfer successfully": "轉賬成功",
                        "Transfer failed": "轉賬失敗",
                        "FROM and TO cannot be the same": "從和至不能一樣",
                        "Amount cannot be empty": "金額不能為空",
                        "Amount cannot more than Main Balance": "金額不能多過餘額",
                        "Amount cannot more than": "金額不能多過",
                        "Main Balance": "餘額",
                        From: "從",
                        To: "至",
                        Amount: "金額",
                        Submit: "提交",
                    },
                    bonus: {
                        Bonus: "紅利",
                        "Referral List": "推薦人列表",
                        "Referral Bonus": "推薦人紅利",
                        "Referral Bonus Record": "推薦人紅利列表",
                        "Promotion Record": "優惠列表",
                        "Auto Promotion Record": "自動優惠列表",
                        "You have claimed your bonus": "您已領取獎金。",
                        "Please choose a bonus type": "請選擇紅利類型",
                        "Amount cannot be empty": "金額不能為空",
                        "Bonus Type": "紅利類型",
                        "Transfer Bonus": "轉賬紅利",
                        "Withdraw Bonus": "取款紅利",
                        "No.": "編號",
                        Type: "類型",
                        Time: "時間",
                        Amount: "金額",
                        Category: "類別",
                        Status: "狀態",
                        Submit: "提交",
                        Title: "標題",
                        "Join Date": "領取時間",
                        "Start Date": "開始日期",
                        "End Date": "結束日期",
                        Turnover: "流水",
                        "No record found": "沒有找到記錄!",
                        Submitting: "提交中",
                        Completed: "已完成",
                        Processing: "進行中",
                        Expired: "無效",
                        Closed: "已關閉",
                        "End Time": "結束時間",
                        "Transfer Count": "轉賬次數",
                        "Game name": "遊戲名",
                        Username: "用戶名",
                        Currency: "幣種",
                        "Register Time": "註冊時間",
                        "Auto Promotion Turnover": "自動優惠的流水",
                        "Auto Promotion Win Loss": "自動優惠的輸贏",
                    },
                    login: {
                        "Please enter your username": "請輸入你的用戶名",
                        "Please enter your password": "請輸入你的密碼",
                        "Username or password is incorrect": "用戶名或密碼不正確",
                        "User has been suspended": "你的賬號已凍結",
                        "You have attempted to login too many times. Please try again in 1 minute": "您嘗試登錄的次數過多。 請在 1 分鐘後重試。",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "用戶名不能為空且僅包含字母數字",
                        "Password cannot be empty": "密碼不能為空",
                        "Password should be within 8-20 alphanumeric without spacing": "密碼應在 8-20 位字母數字以內，無空格",
                        "Confirm password must be the same as password": "確認密碼必須與密碼相同",
                        "Please enter a valid contact number with length between 6 to 12": "請輸入長度在 6 到 12 之間的有效電話號碼",
                        "Please choose a currency": "請選擇幣別",
                        Register: "註冊",
                        Username: "用戶名",
                        Password: "密碼",
                        "Confirm Password": "確認密碼",
                        "Contact Number": "電話號碼",
                        Currency: "幣別",
                        Referral: "推薦人",
                        Submit: "提交",
                    },
                    default: {
                        "Unauthorized access": "會員已經登出",
                        "Request failed": "請求失敗",
                    }
                }
            },
            th: {
                translation: {
                    header: {
                        Welcome: "ยินดีต้อนรับ",
                        Balance: "ยอดคงเหลือ",
                        Deposit: "เงินฝาก",
                        Withdraw: "ถอน",
                        Logout: "ออกจากระบบ",
                        Home: "บ้าน",
                        Sports: "กีฬา",
                        Casino: "คาสิโน",
                        Poker: "โป๊กเกอร์",
                        Lottery: "ลอตเตอรี",
                        Slots: "สล็อต",
                        Cockfight: "ชนไก่",
                        Forex: "Forex",
                        Promotions: "โปรโมชั่น",
                        Register: "ลงทะเบียน",
                        Affilliate: "พันธมิตร",
                    }, game: {
                        "Play Now": "เล่นเลย",
                    },
                    memberNav: {
                        "Basic Information": "ข้อมูลพื้นฐาน",
                        Deposit: "เงินฝาก",
                        Withdraw: "ถอน",
                        "Bet Record": "บันทึกการเดิมพัน",
                        Statement: "คำแถลง",
                        "Change Password": "เปลี่ยนรหัสผ่าน",
                        Bonus: "โบนัส",
                        Transfer: "โอนย้าย",
                        Turnover: "ยอดเทิร์นโอเวอร์",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "กรุณาเลือกธนาคาร",
                        "Amount cannot be empty": "ต้องระบุจำนวนเงิน",
                        "Amount must be more than": "จำนวนเงินต้องมากกว่า",
                        "Amount cannot more than": "จำนวนเงินไม่เกิน",
                        Bank: "ธนาคาร",
                        "Bank Account Name": "ชื่อบัญชีธนาคาร",
                        "Bank Account Number": "หมายเลขบัญชีธนาคาร",
                        "Deposit successfully": "ฝากสำเร็จ",
                        Amount: "จำนวน",
                        Submit: "ส่ง",
                        Deposit: "เงินฝาก",
                        "Deposit List": "รายการฝากเงิน",
                        No: "ไม่",
                        DateTime: "วันเวลา",
                        "Bank Name": "ชื่อธนาคาร",
                        Status: "สถานะ",
                        Remark: "ข้อสังเกต",
                    },
                    with: {
                        "Amount cannot be empty": "ต้องระบุจำนวนเงิน",
                        "Amount must be more than": "จำนวนเงินต้องมากกว่า",
                        "Amount cannot more than": "จำนวนเงินไม่เกิน",
                        Bank: "ธนาคาร",
                        "Bank Account Name": "ชื่อบัญชีธนาคาร",
                        "Bank Account Number": "หมายเลขบัญชีธนาคาร",
                        "Withdraw successfully": "ถอนสำเร็จ",
                        Amount: "จำนวน",
                        Submit: "ส่ง",
                        Withdraw: "ถอน",
                        "Withdraw List": "รายการถอน",
                        No: "ไม่",
                        DateTime: "วันเวลา",
                        "Bank Name": "ชื่อธนาคาร",
                        Status: "สถานะ",
                        Remark: "ข้อสังเกต",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "กำลังส่ง",
                        Completed: "สมบูรณ์",
                        Processing: "กำลังประมวลผล",
                        Expired: "หมดอายุ",
                        Canceled: "ยกเลิก",
                        Pending: "รอดำเนินการ",
                    },
                    memberInfo: {
                        "Member Information": "ข้อมูลสมาชิก",
                        Username: "ชื่อผู้ใช้",
                        "Referral Link": "ลิงค์ผู้แนะนำ",
                        "E-mail": "อีเมล",
                        Phone: "โทรศัพท์",
                        Bank: "ธนาคาร",
                        "Bank Account Name": "ชื่อบัญชีธนาคาร",
                        "Bank Account Number": "หมายเลขบัญชีธนาคาร",
                        "Total Bonus": "โบนัสรวม",
                        "Last Bonus": "โบนัสสุดท้าย",
                        Balance: "สมดุล",
                    },
                    betRecord: {
                        Provider: "ผู้ให้บริการ",
                        Submit: "ส่ง",
                    },
                    statement: {
                        Provider: "ผู้ให้บริการ",
                        Submit: "ส่ง",
                    },
                    date: {
                        Today: "วันนี้",
                        Yesterday: "เมื่อวาน",
                        "This Week": "ในสัปดาห์นี้",
                        "Last Week": "อาทิตย์ที่แล้ว",
                        "This Month": "เดือนนี้",
                        "Last Month": "เดือนที่แล้ว",
                        "Start Date": "วันที่เริ่มต้น",
                        "End Date": "วันที่สิ้นสุด",
                    },
                    table: {
                        "No.": "ไม่.",
                        Game: "เกม",
                        "Game ID": "รหัสเกม",
                        "Bet Detail": "รายละเอียดการเดิมพัน",
                        "Bet Time": "เวลาเดิมพัน",
                        "Bet Amount": "จำนวนเงินเดิมพัน",
                        "WinLoss": "วินลอส",
                        "Bet Type": "ประเภทการเดิมพัน",
                        TypeL: "พิมพ์",
                        Pool: "สระน้ำ",
                        Result: "ผลลัพธ์",
                        Odds: "อัตราต่อรอง",
                        "Bet On": "เดิมพัน",
                        "TRS ID": "TRS ID",
                        "No record found": "ไม่พบบันทึก!",
                    },
                    password: {
                        "Your password has been changed": "รหัสผ่านของคุณถูกเปลี่ยน",
                        "Please enter your current password": "กรุณาใส่รหัสผ่านปัจจุบันของคุณ",
                        "Please enter your new password": "กรุณาใส่รหัสผ่านใหม่ของคุณ",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "รหัสผ่านมีได้เฉพาะตัวอักษรและตัวเลขเท่านั้น ความยาวต้องมากกว่า 8",
                        "Confirm password cannot be empty": "ยืนยันรหัสผ่านต้องไม่เว้นว่าง",
                        "New password and confirm password must be the same": "รหัสผ่านใหม่และยืนยันรหัสผ่านต้องเหมือนกัน",
                        "Change Password": "เปลี่ยนรหัสผ่าน",
                        "Current Password": "รหัสผ่านปัจจุบัน",
                        "New Password": "รหัสผ่านใหม่",
                        "Confirm Password": "ยืนยันรหัสผ่าน",
                        "Submit": "ส่ง",
                    },
                    turnover: {
                        "Please select a provider": "กรุณาเลือกผู้ให้บริการ",
                        Turnover: "ยอดเทิร์นโอเวอร์",
                        Provider: "ผู้ให้บริการ",
                        "Select All": "เลือกทั้งหมด",
                        Casino: "คาสิโน",
                        Sports: "กีฬา",
                        Slots: "สล็อต",
                        Lottery: "หวย",
                        Submit: "ส่ง",
                        "No.": "ไม่.",
                        "Start Time": "เวลาเริ่มต้น",
                        "End Time": "เวลาสิ้นสุด",
                        "No record found": "ไม่พบบันทึก",
                    },
                    transfer: {
                        "Transfer successfully": "โอนเรียบร้อย",
                        "Transfer failed": "การโอนล้มเหลว",
                        "FROM and TO cannot be the same": "FROM และ TO ไม่สามารถเหมือนกันได้",
                        "Amount cannot be empty": "ต้องระบุจำนวนเงิน",
                        "Amount cannot more than Main Balance": "จำนวนเงินไม่เกินยอดคงเหลือหลัก",
                        "Amount cannot more than": "จำนวนเงินไม่เกิน",
                        "Main Balance": "ยอดคงเหลือหลัก",
                        From: "จาก",
                        To: "ถึง",
                        Amount: "จำนวน",
                        Submit: "ส่ง",
                    },
                    bonus: {
                        Bonus: "โบนัส",
                        "Referral List": "รายชื่อผู้อ้างอิง",
                        "Referral Bonus": "โบนัสผู้อ้างอิง",
                        "Referral Bonus Record": "บันทึกโบนัสผู้อ้างอิง",
                        "Promotion Record": "บันทึกโปรโมชั่น",
                        "Auto Promotion Record": "บันทึกโปรโมชั่นอัตโนมัติ",
                        "You have claimed your bonus": "คุณได้รับโบนัสแล้ว",
                        "Please choose a bonus type": "กรุณาเลือกประเภทโบนัส",
                        "Amount cannot be empty": "ต้องระบุจำนวนเงิน",
                        "Bonus Type": "ประเภทโบนัส",
                        "Transfer Bonus": "โบนัสโอน",
                        "Withdraw Bonus": "ถอนโบนัส",
                        "No.": "ไม่.",
                        Type: "พิมพ์",
                        Time: "เวลา",
                        Amount: "จำนวน",
                        Category: "หมวดหมู่",
                        Status: "สถานะ",
                        Submit: "ส่ง",
                        Title: "ชื่อ",
                        "Join Date": "วันที่เข้าร่วม",
                        "Start Date": "วันที่เริ่มต้น",
                        "End Date": "วันที่สิ้นสุด",
                        Turnover: "ยอดเทิร์นโอเวอร์",
                        "No record found": "ไม่พบบันทึก",
                        Submitting: "กำลังส่ง",
                        Completed: "สมบูรณ์",
                        Closed: "ปิด",
                        Expired: "หมดอายุ",
                        Processing: "กำลังประมวลผล",
                        "End Time": "เวลาสิ้นสุด",
                        "Transfer Count": "ยอดโอน",
                        "Game name": "ชื่อเกม",
                        Username: "ชื่อผู้ใช้",
                        Currency: "สกุลเงิน",
                        "Register Time": "ลงทะเบียนเวลา",
                        "Auto Promotion Turnover": "การหมุนเวียนโปรโมชั่นอัตโนมัติ",
                        "Auto Promotion Win Loss": "ออโต้ โปร วิน ลอส",
                    },
                    login: {
                        "Please enter your username": "กรุณาใส่ชื่อผู้ใช้ของคุณ",
                        "Please enter your password": "กรุณาใส่รหัสผ่านของคุณ",
                        "Username or password is incorrect": "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
                        "User has been suspended": "ผู้ใช้ถูกระงับ",
                        "You have attempted to login too many times. Please try again in 1 minute": "คุณพยายามเข้าสู่ระบบหลายครั้งเกินไป โปรดลองอีกครั้งใน 1 นาที",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "ต้องระบุชื่อผู้ใช้และมีเพียงตัวอักษรและตัวเลขเท่านั้น",
                        "Password cannot be empty": "รหัสผ่านต้องไม่เว้นว่าง",
                        "Password should be within 8-20 alphanumeric without spacing": "รหัสผ่านควรอยู่ภายใน 8-20 ตัวอักษรและตัวเลขโดยไม่มีการเว้นวรรค",
                        "Confirm password must be the same as password": "ยืนยันรหัสผ่านต้องเหมือนกับรหัสผ่าน",
                        "Please enter a valid contact number with length between 6 to 12": "โปรดป้อนหมายเลขติดต่อที่ถูกต้องโดยมีความยาวระหว่าง 6 ถึง 12",
                        "Please choose a currency": "กรุณาเลือกสกุลเงิน",
                        Register: "ลงทะเบียน",
                        Username: "ชื่อผู้ใช้",
                        Password: "รหัสผ่าน",
                        "Confirm Password": "ยืนยันรหัสผ่าน",
                        "Contact Number": "เบอร์ติดต่อ",
                        Currency: "สกุลเงิน",
                        Referral: "การอ้างอิง",
                        Submit: "ส่ง",
                    },
                    default: {
                        "Unauthorized access": "การเข้าถึงโดยไม่ได้รับอนุญาต",
                        "Request failed": "คำขอล้มเหลว",
                    }
                }
            },
            id: {
                translation: {
                    header: {
                        Welcome: "Selamat datang",
                        Balance: "Keseimbangan",
                        Deposit: "Menyetorkan",
                        Withdraw: "Menarik",
                        Logout: "Keluar",
                        Home: "Rumah",
                        Sports: "Olahraga",
                        Casino: "Kasino",
                        Poker: "Poker",
                        Lottery: "Lotre",
                        Slots: "Slot",
                        Cockfight: "Adu Ayam",
                        Forex: "Forex",
                        Promotions: "Promosi",
                        Register: "Daftar",
                        Affilliate: "Afiliasi",
                    },
                    game: {
                        "Play Now": "Main sekarang",
                    },
                    memberNav: {
                        "Basic Information": "Informasi dasar",
                        Deposit: "Menyetorkan",
                        Withdraw: "Menarik",
                        "Bet Record": "Rekor Taruhan",
                        Statement: "Penyataan",
                        "Change Password": "Ganti kata sandi",
                        Bonus: "Bonus",
                        Transfer: "Transfer",
                        Turnover: "Perputaran",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "Silahkan pilih bank",
                        "Amount cannot be empty": "Jumlah tidak boleh kosong",
                        "Amount must be more than": "Jumlah harus lebih dari",
                        "Amount cannot more than": "Jumlah tidak boleh lebih dari",
                        Bank: "Bank",
                        "Bank Account Name": "Nama akun bank",
                        "Bank Account Number": "Nomor rekening bank",
                        "Deposit successfully": "Setoran berhasil",
                        Amount: "Jumlah",
                        Submit: "Kirim",
                        Deposit: "Menyetorkan",
                        "Deposit List": "Daftar Setoran",
                        No: "Tidak",
                        DateTime: "Tanggal Waktu",
                        "Bank Name": "Nama Bank",
                        Status: "Status",
                        Remark: "Komentar",
                    },
                    with: {
                        "Amount cannot be empty": "Jumlah tidak boleh kosong",
                        "Amount must be more than": "Jumlah harus lebih dari",
                        "Amount cannot more than": "Jumlah tidak boleh lebih dari",
                        Bank: "Bank",
                        "Bank Account Name": "Nama akun bank",
                        "Bank Account Number": "Nomor rekening bank",
                        "Withdraw successfully": "Penarikan berhasil",
                        Amount: "Jumlah",
                        Submit: "Kirim",
                        Withdraw: "Menarik",
                        "Withdraw List": "Daftar Penarikan",
                        No: "Tidak",
                        DateTime: "Tanggal Waktu",
                        "Bank Name": "Nama Bank",
                        Status: "Status",
                        Remark: "Komentar",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "Mengirim",
                        Completed: "Lengkap",
                        Processing: "Memproses",
                        Expired: "Kedaluwarsa",
                        Canceled: "Dibatalkan",
                        Pending: "Tertunda",
                    },
                    memberInfo: {
                        "Member Information": "Informasi Anggota",
                        Username: "Nama belakang",
                        "Referral Link": "Tautan Rujukan",
                        "E-mail": "Surel",
                        Phone: "Telepon",
                        Bank: "Bank",
                        "Bank Account Name": "Nama akun bank",
                        "Bank Account Number": "Nomor rekening bank",
                        "Total Bonus": "Total Bonus",
                        "Last Bonus": "Bonus Terakhir",
                        Balance: "Keseimbangan",
                    },
                    betRecord: {
                        Provider: "Pemberi",
                        Submit: "Kirim",
                    },
                    statement: {
                        Provider: "Pemberi",
                        Submit: "Kirim",
                    },
                    date: {
                        Today: "Hari ini",
                        Yesterday: "Kemarin",
                        "This Week": "Minggu ini",
                        "Last Week": "Minggu lalu",
                        "This Month": "Bulan ini",
                        "Last Month": "Bulan lalu",
                        "Start Date": "Mulai tanggal",
                        "End Date": "Tanggal Akhir",
                    },
                    table: {
                        "No.": "Tidak.",
                        Game: "Permainan",
                        "Game ID": "ID Permainan",
                        "Bet Detail": "Rincian Taruhan",
                        "Bet Time": "Waktu Taruhan",
                        "Bet Amount": "Jumlah Taruhan",
                        "WinLoss": "Menang Kalah",
                        "Bet Type": "Jenis Taruhan",
                        TypeL: "Jenis",
                        Pool: "Kolam",
                        Result: "Hasil",
                        Odds: "Kemungkinan",
                        "Bet On": "Bertaruh",
                        "TRS ID": "ID TRS",
                        "No record found": "Tidak ada catatan yang ditemukan!",
                    },
                    password: {
                        "Your password has been changed": "Kata sandi Anda telah diubah.",
                        "Please enter your current password": "Silakan masukkan kata sandi Anda saat ini.",
                        "Please enter your new password": "Silakan masukkan kata sandi baru Anda.",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "Password hanya boleh berisi abjad dan angka, panjangnya harus lebih dari 8.",
                        "Confirm password cannot be empty": "Konfirmasi sandi tidak boleh kosong.",
                        "New password and confirm password must be the same": "Kata sandi baru dan kata sandi konfirmasi harus sama.",
                        "Change Password": "Ganti kata sandi",
                        "Current Password": "Kata sandi saat ini",
                        "New Password": "Kata sandi baru",
                        "Confirm Password": "Konfirmasi sandi",
                        "Submit": "Kirim",
                    },
                    turnover: {
                        "Please select a provider": "Silakan pilih penyedia",
                        Turnover: "Perputaran",
                        Provider: "Pemberi",
                        "Select All": "Pilih Semua",
                        Casino: "Kasino",
                        Sports: "Olahraga",
                        Slots: "Slot",
                        Lottery: "Lotre",
                        Submit: "Kirim",
                        "No.": "Tidak.",
                        "Start Time": "Waktu mulai",
                        "End Time": "Akhir waktu",
                        "No record found": "Tidak ada catatan yang ditemukan",
                    },
                    transfer: {
                        "Transfer successfully": "Transfer berhasil",
                        "Transfer failed": "Transfer gagal",
                        "FROM and TO cannot be the same": "FROM dan TO tidak boleh sama",
                        "Amount cannot be empty": "Jumlah tidak boleh kosong",
                        "Amount cannot more than Main Balance": "Jumlah tidak boleh lebih dari Saldo Utama",
                        "Amount cannot more than": "Jumlah tidak boleh lebih dari",
                        "Main Balance": "Saldo utama",
                        From: "Dari",
                        To: "Ke",
                        Amount: "Jumlah",
                        Submit: "Kirim",
                    },
                    bonus: {
                        Bonus: "Bonus",
                        "Referral List": "Daftar Rujukan",
                        "Referral Bonus": "Bonus Rujukan",
                        "Referral Bonus Record": "Catatan Bonus Rujukan",
                        "Promotion Record": "Catatan Promosi",
                        "Auto Promotion Record": "Catatan Promosi Otomatis",
                        "You have claimed your bonus": "Anda telah mengklaim bonus Anda.",
                        "Please choose a bonus type": "Silakan pilih jenis bonus",
                        "Amount cannot be empty": "Jumlah tidak boleh kosong",
                        "Bonus Type": "Jenis Bonus",
                        "Transfer Bonus": "Bonus Transfer",
                        "Withdraw Bonus": "Bonus Penarikan",
                        "No.": "Tidak.",
                        Type: "Jenis",
                        Time: "Waktu",
                        Amount: "Jumlah",
                        Category: "Kategori",
                        Status: "Status",
                        Submit: "Kirim",
                        Title: "Judul",
                        "Join Date": "Tanggal Bergabung",
                        "Start Date": "Mulai tanggal",
                        "End Date": "Tanggal Akhir",
                        Turnover: "Perputaran",
                        "No record found": "Tidak ada catatan yang ditemukan",
                        Submitting: "Mengirim",
                        Completed: "Lengkap",
                        Closed: "Tertutup",
                        Expired: "Kedaluwarsa",
                        Processing: "Memproses",
                        "End Time": "Akhir waktu",
                        "Transfer Count": "Jumlah Transfer",
                        "Game name": "Nama permainan",
                        Username: "Nama belakang",
                        Currency: "Mata uang",
                        "Register Time": "Waktu Pendaftaran",
                        "Auto Promotion Turnover": "Perputaran Promosi Otomatis",
                        "Auto Promotion Win Loss": "Promosi Otomatis Menang Kalah",
                    },
                    login: {
                        "Please enter your username": "Silakan masukkan nama pengguna Anda",
                        "Please enter your password": "Silakan masukkan kata sandi Anda",
                        "Username or password is incorrect": "Username atau password salah",
                        "User has been suspended": "Pengguna telah ditangguhkan",
                        "You have attempted to login too many times. Please try again in 1 minute": "Anda terlalu sering mencoba login. Harap coba lagi dalam 1 menit.",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "Nama pengguna tidak boleh kosong dan hanya berisi alfanumerik",
                        "Password cannot be empty": "Password tidak boleh kosong",
                        "Password should be within 8-20 alphanumeric without spacing": "Kata sandi harus dalam 8-20 alfanumerik tanpa spasi",
                        "Confirm password must be the same as password": "Konfirmasi kata sandi harus sama dengan kata sandi",
                        "Please enter a valid contact number with length between 6 to 12": "Silakan masukkan nomor kontak yang valid dengan panjang antara 6 sampai 12",
                        "Please choose a currency": "Silakan pilih mata uang",
                        Register: "Daftar",
                        Username: "Nama belakang",
                        Password: "Kata Sandi",
                        "Confirm Password": "Konfirmasi sandi",
                        "Contact Number": "Nomor kontak",
                        Currency: "Mata uang",
                        Referral: "Rujukan",
                        Submit: "Kirim",
                    },
                    default: {
                        "Unauthorized access": "Akses tidak sah",
                        "Request failed": "Permintaan gagal",
                    }
                }
            },
            my: {
                translation: {
                    header: {
                        Welcome: "Selamat Datang",
                        Balance: "Seimbang",
                        Deposit: "Deposit",
                        Withdraw: "Tarik diri",
                        Logout: "Log keluar",
                        Home: "Rumah",
                        Sports: "Sukan",
                        Casino: "Kasino",
                        Poker: "Poker",
                        Lottery: "Loteri",
                        Slots: "Slot",
                        Cockfight: "Sabung ayam",
                        Forex: "Forex",
                        Promotions: "Promosi",
                        Register: "Daftar",
                        Affilliate: "Afiliasi",
                    },
                    game: {
                        "Play Now": "Main sekarang",
                    },
                    memberNav: {
                        "Basic Information": "Maklumat asas",
                        Deposit: "Deposit",
                        Withdraw: "Tarik diri",
                        "Bet Record": "Rekod Pertaruhan",
                        Statement: "Kenyataan",
                        "Change Password": "Tukar kata laluan",
                        Bonus: "Bonus",
                        Transfer: "Pemindahan",
                        Turnover: "Perolehan",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "Sila pilih bank",
                        "Amount cannot be empty": "Jumlah tidak boleh kosong",
                        "Amount must be more than": "Jumlah mesti lebih daripada",
                        "Amount cannot more than": "Jumlah tidak boleh lebih daripada",
                        Bank: "Bank",
                        "Bank Account Name": "Nama Akaun Bank",
                        "Bank Account Number": "Nombor akaun bank",
                        "Deposit successfully": "Deposit berjaya",
                        Amount: "Jumlah",
                        Submit: "Serahkan",
                        Deposit: "Deposit",
                        "Deposit List": "Senarai Deposit",
                        No: "Tidak",
                        DateTime: "Masa tarikh",
                        "Bank Name": "Nama bank",
                        Status: "Status",
                        Remark: "Catatan",
                    },
                    with: {
                        "Amount cannot be empty": "Jumlah tidak boleh kosong",
                        "Amount must be more than": "Jumlah mesti lebih daripada",
                        "Amount cannot more than": "Jumlah tidak boleh lebih daripada",
                        Bank: "Bank",
                        "Bank Account Name": "Nama Akaun Bank",
                        "Bank Account Number": "Nombor akaun bank",
                        "Withdraw successfully": "Berjaya menarik diri",
                        Amount: "Jumlah",
                        Submit: "Serahkan",
                        Withdraw: "Tarik diri",
                        "Withdraw List": "Senarai Tarik Balik",
                        No: "Tidak",
                        DateTime: "Masa tarikh",
                        "Bank Name": "Nama bank",
                        Status: "Status",
                        Remark: "Catatan",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "Menyerahkan",
                        Completed: "Selesai",
                        Processing: "Memproses",
                        Expired: "Tamat tempoh",
                        Canceled: "Dibatalkan",
                        Pending: "Yang belum selesai",
                    },
                    memberInfo: {
                        "Member Information": "Maklumat Ahli",
                        Username: "Nama pengguna",
                        "Referral Link": "Pautan Rujukan",
                        "E-mail": "E-mel",
                        Phone: "Telefon",
                        Bank: "Bank",
                        "Bank Account Name": "Nama Akaun Bank",
                        "Bank Account Number": "Nombor akaun bank",
                        "Total Bonus": "Jumlah Bonus",
                        "Last Bonus": "Bonus Terakhir",
                        Balance: "Seimbang",
                    },
                    betRecord: {
                        Provider: "Penyedia",
                        Submit: "Serahkan",
                    },
                    statement: {
                        Provider: "Penyedia",
                        Submit: "Serahkan",
                    },
                    date: {
                        Today: "Hari ini",
                        Yesterday: "Semalam",
                        "This Week": "Minggu ini",
                        "Last Week": "Minggu lepas",
                        "This Month": "Bulan ini",
                        "Last Month": "Bulan lepas",
                        "Start Date": "Tarikh mula",
                        "End Date": "Tarikh tamat",
                    },
                    table: {
                        "No.": "Tidak.",
                        Game: "Permainan",
                        "Game ID": "ID Permainan",
                        "Bet Detail": "Butiran Pertaruhan",
                        "Bet Time": "Masa Pertaruhan",
                        "Bet Amount": "Jumlah Pertaruhan",
                        "WinLoss": "WinLoss",
                        "Bet Type": "Jenis Pertaruhan",
                        TypeL: "Jenis",
                        Pool: "Kolam",
                        Result: "Hasil",
                        Odds: "Kemungkinan",
                        "Bet On": "Pertaruhan",
                        "TRS ID": "ID TRS",
                        "No record found": "Tiada rekod ditemui!",
                    },
                    password: {
                        "Your password has been changed": "Kata laluan anda telah ditukar.",
                        "Please enter your current password": "Sila masukkan kata laluan semasa anda.",
                        "Please enter your new password": "Sila masukkan kata laluan baharu anda.",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "Kata laluan hanya boleh mengandungi abjad dan nombor , panjang mestilah lebih daripada 8.",
                        "Confirm password cannot be empty": "Sahkan kata laluan tidak boleh kosong.",
                        "New password and confirm password must be the same": "Kata laluan baharu dan mengesahkan kata laluan mestilah sama.",
                        "Change Password": "Tukar kata laluan",
                        "Current Password": "Kata Laluan Semasa",
                        "New Password": "Kata laluan baharu",
                        "Confirm Password": "Sahkan Kata Laluan",
                        "Submit": "Serahkan",
                    },
                    turnover: {
                        "Please select a provider": "Sila pilih pembekal",
                        Turnover: "Perolehan",
                        Provider: "Penyedia",
                        "Select All": "Pilih semua",
                        Casino: "Kasino",
                        Sports: "Sukan",
                        Slots: "Slot",
                        Lottery: "Loteri",
                        Submit: "Serahkan",
                        "No.": "Tidak.",
                        "Start Time": "Masa mula",
                        "End Time": "Masa tamat",
                        "No record found": "Tiada rekod ditemui",
                    },
                    transfer: {
                        "Transfer successfully": "Berjaya dipindahkan",
                        "Transfer failed": "Pemindahan gagal",
                        "FROM and TO cannot be the same": "DARI dan KEPADA tidak boleh sama",
                        "Amount cannot be empty": "Jumlah tidak boleh kosong",
                        "Amount cannot more than Main Balance": "Jumlah tidak boleh melebihi Baki Utama",
                        "Amount cannot more than": "Jumlah tidak boleh lebih daripada",
                        "Main Balance": "Imbangan Utama",
                        From: "Daripada",
                        To: "Kepada",
                        Amount: "Jumlah",
                        Submit: "Serahkan",
                    },
                    bonus: {
                        Bonus: "Bonus",
                        "Referral List": "Senarai Rujukan",
                        "Referral Bonus": "Bonus Rujukan",
                        "Referral Bonus Record": "Rekod Bonus Rujukan",
                        "Promotion Record": "Rekod Kenaikan Pangkat",
                        "Auto Promotion Record": "Rekod Promosi Auto",
                        "You have claimed your bonus": "Anda telah menuntut bonus anda.",
                        "Please choose a bonus type": "Sila pilih jenis bonus",
                        "Amount cannot be empty": "Jumlah tidak boleh kosong",
                        "Bonus Type": "Jenis Bonus",
                        "Transfer Bonus": "Bonus Pemindahan",
                        "Withdraw Bonus": "Keluarkan Bonus",
                        "No.": "Tidak.",
                        Type: "Jenis",
                        Time: "Masa",
                        Amount: "Jumlah",
                        Category: "Kategori",
                        Status: "Status",
                        Submit: "Serahkan",
                        Title: "Tajuk",
                        "Join Date": "Tarikh menyertai",
                        "Start Date": "Tarikh mula",
                        "End Date": "Tarikh tamat",
                        Turnover: "Perolehan",
                        "No record found": "Tiada rekod ditemui",
                        Submitting: "Menyerahkan",
                        Completed: "Selesai",
                        Closed: "Tertutup",
                        Expired: "Tamat tempoh",
                        Processing: "Memproses",
                        "End Time": "Masa tamat",
                        "Transfer Count": "Kiraan Pemindahan",
                        "Game name": "Nama permainan",
                        Username: "Nama pengguna",
                        Currency: "Mata wang",
                        "Register Time": "Masa Daftar",
                        "Auto Promotion Turnover": "Perolehan Promosi Automatik",
                        "Auto Promotion Win Loss": "Promosi Auto Menang Kalah",
                    },
                    login: {
                        "Please enter your username": "Sila masukkan nama pengguna anda",
                        "Please enter your password": "Sila masukkan kata laluan anda",
                        "Username or password is incorrect": "Nama pengguna atau kata laluan tidak betul",
                        "User has been suspended": "Pengguna telah digantung",
                        "You have attempted to login too many times. Please try again in 1 minute": "Anda telah mencuba untuk log masuk terlalu banyak kali. Sila cuba lagi dalam 1 minit.",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "Nama pengguna tidak boleh kosong dan mengandungi hanya abjad angka",
                        "Password cannot be empty": "Kata laluan tidak boleh kosong",
                        "Password should be within 8-20 alphanumeric without spacing": "Kata laluan hendaklah dalam lingkungan 8-20 abjad angka tanpa jarak",
                        "Confirm password must be the same as password": "Sahkan kata laluan mestilah sama dengan kata laluan",
                        "Please enter a valid contact number with length between 6 to 12": "Sila masukkan nombor hubungan yang sah dengan panjang antara 6 hingga 12",
                        "Please choose a currency": "Sila pilih mata wang",
                        Register: "Daftar",
                        Username: "Nama pengguna",
                        Password: "Kata Laluan",
                        "Confirm Password": "Sahkan Kata Laluan",
                        "Contact Number": "Nombor telefon",
                        Currency: "Mata wang",
                        Referral: "Rujukan",
                        Submit: "Serahkan",
                    },
                    default: {
                        "Unauthorized access": "Akses tidak dibenarkan",
                        "Request failed": "Permintaan gagal",
                    }
                }
            },
            kh: {
                translation: {
                    header: {
                        Welcome: "សូមស្វាគមន៍",
                        Balance: "តុល្យភាព",
                        Deposit: "ដាក់ប្រាក់",
                        Withdraw: "ដក",
                        Logout: "ចាកចេញ",
                        Home: "ផ្ទះ",
                        Sports: "កីឡា",
                        Casino: "កាស៊ីណូ",
                        Poker: "បៀរ",
                        Lottery: "ឆ្នោត",
                        Slots: "រន្ធ",
                        Cockfight: "ជល់មាន់",
                        Forex: "Forex",
                        Promotions: "ការផ្សព្វផ្សាយ",
                        Register: "ចុះឈ្មោះ",
                        Affilliate: "សម្ព័ន្ធ",
                    },
                    game: {
                        "Play Now": "លេង​ឥឡូវ",
                    },
                    memberNav: {
                        "Basic Information": "ព័ត៌មានមូលដ្ឋាន",
                        Deposit: "ដាក់ប្រាក់",
                        Withdraw: "ដក",
                        "Bet Record": "កំណត់ត្រាភ្នាល់",
                        Statement: "សេចក្តីថ្លែងការណ៍",
                        "Change Password": "ប្តូរលេខសម្ងាត់",
                        Bonus: "ប្រាក់រង្វាន់",
                        Transfer: "ផ្ទេរ",
                        Turnover: "ចំណូល",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "សូមជ្រើសរើសធនាគារ",
                        "Amount cannot be empty": "ចំនួនទឹកប្រាក់មិនអាចទទេ",
                        "Amount must be more than": "ចំនួនទឹកប្រាក់ត្រូវតែច្រើនជាង",
                        "Amount cannot more than": "ចំនួនទឹកប្រាក់មិនអាចលើសពី",
                        Bank: "ធនាគារ",
                        "Bank Account Name": "ឈ្មោះគណនីធនាគារ",
                        "Bank Account Number": "លេខ​គណនី​ធនាគារ",
                        "Deposit successfully": "ដាក់ប្រាក់ដោយជោគជ័យ",
                        Amount: "ចំនួន",
                        Submit: "ដាក់ស្នើ",
                        Deposit: "ដាក់ប្រាក់",
                        "Deposit List": "បញ្ជីប្រាក់បញ្ញើ",
                        No: "ទេ",
                        DateTime: "ពេល​ណាត់ជួប",
                        "Bank Name": "ឈ្មោះ​របស់​ធនាគារ",
                        Status: "ស្ថានភាព",
                        Remark: "ចំណាំ",
                    },
                    with: {
                        "Amount cannot be empty": "ចំនួនទឹកប្រាក់មិនអាចទទេ",
                        "Amount must be more than": "ចំនួនទឹកប្រាក់ត្រូវតែច្រើនជាង",
                        "Amount cannot more than": "ចំនួនទឹកប្រាក់មិនអាចលើសពី",
                        Bank: "ធនាគារ",
                        "Bank Account Name": "ឈ្មោះគណនីធនាគារ",
                        "Bank Account Number": "លេខ​គណនី​ធនាគារ",
                        "Withdraw successfully": "ដកប្រាក់ដោយជោគជ័យ",
                        Amount: "ចំនួន",
                        Submit: "ដាក់ស្នើ",
                        Withdraw: "ដក",
                        "Withdraw List": "បញ្ជីដកប្រាក់",
                        No: "ទេ",
                        DateTime: "ពេល​ណាត់ជួប",
                        "Bank Name": "ឈ្មោះ​របស់​ធនាគារ",
                        Status: "ស្ថានភាព",
                        Remark: "ចំណាំ",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "ការបញ្ជូន",
                        Completed: "បានបញ្ចប់",
                        Processing: "ដំណើរការ",
                        Expired: "ផុតកំណត់",
                        Canceled: "បោះបង់",
                        Pending: "កំពុងរង់ចាំ",
                    },
                    memberInfo: {
                        "Member Information": "ព័ត៌មានសមាជិក",
                        Username: "ឈ្មោះ​អ្នកប្រើប្រាស់",
                        "Referral Link": "តំណយោង",
                        "E-mail": "អ៊ីមែល",
                        Phone: "ទូរស័ព្ទ",
                        Bank: "ធនាគារ",
                        "Bank Account Name": "ឈ្មោះគណនីធនាគារ",
                        "Bank Account Number": "លេខ​គណនី​ធនាគារ",
                        "Total Bonus": "ប្រាក់រង្វាន់សរុប",
                        "Last Bonus": "ប្រាក់រង្វាន់ចុងក្រោយ",
                        Balance: "តុល្យភាព",
                    },
                    betRecord: {
                        Provider: "អ្នកផ្តល់",
                        Submit: "ដាក់ស្នើ",
                    },
                    statement: {
                        Provider: "អ្នកផ្តល់",
                        Submit: "ដាក់ស្នើ",
                    },
                    date: {
                        Today: "ថ្ងៃនេះ",
                        Yesterday: "ម្សិលមិញ",
                        "This Week": "ស​ប្តា​ហ៍​នេះ",
                        "Last Week": "សប្ដាហ៍​មុន",
                        "This Month": "ខែ​នេះ",
                        "Last Month": "ខែមុន",
                        "Start Date": "ថ្ងៃ​ចាប់ផ្តើម",
                        "End Date": "កាលបរិច្ឆេទបញ្ចប់",
                    },
                    table: {
                        "No.": "ទេ",
                        Game: "ហ្គេម",
                        "Game ID": "លេខសម្គាល់ហ្គេម",
                        "Bet Detail": "ព័ត៌មានលម្អិតភ្នាល់",
                        "Bet Time": "ម៉ោងភ្នាល់",
                        "Bet Amount": "ចំនួនភ្នាល់",
                        "WinLoss": "WinLoss",
                        "Bet Type": "ប្រភេទភ្នាល់",
                        TypeL: "ប្រភេទ",
                        Pool: "អាង",
                        Result: "លទ្ធផល",
                        Odds: "ហាងឆេង",
                        "Bet On": "ភ្នាល់",
                        "TRS ID": "លេខសម្គាល់ TRS",
                        "No record found": "រកមិនឃើញកំណត់ត្រាទេ!",
                    },
                    password: {
                        "Your password has been changed": "ពាក្យសម្ងាត់របស់អ្នកត្រូវបានផ្លាស់ប្តូរ។",
                        "Please enter your current password": "សូមបញ្ចូលពាក្យសម្ងាត់បច្ចុប្បន្នរបស់អ្នក។",
                        "Please enter your new password": "សូមបញ្ចូលពាក្យសម្ងាត់ថ្មីរបស់អ្នក។",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "ពាក្យ​សម្ងាត់​អាច​មាន​តែ​អក្ខរក្រម និង​លេខ ប្រវែង​ត្រូវ​តែ​មាន​ច្រើន​ជាង 8 ។",
                        "Confirm password cannot be empty": "បញ្ជាក់ពាក្យសម្ងាត់មិនអាចទទេបានទេ។",
                        "New password and confirm password must be the same": "ពាក្យសម្ងាត់ថ្មី និងបញ្ជាក់ពាក្យសម្ងាត់ត្រូវតែដូចគ្នា",
                        "Change Password": "ប្តូរលេខសម្ងាត់",
                        "Current Password": "លេខសំងាត់​បច្ចុប្បន្ន",
                        "New Password": "ពាក្យសម្ងាត់​ថ្មី",
                        "Confirm Password": "បញ្ជាក់​ពាក្យ​សម្ងាត់",
                        "Submit": "ដាក់ស្នើ",
                    },
                    turnover: {
                        "Please select a provider": "សូមជ្រើសរើសអ្នកផ្តល់សេវា",
                        Turnover: "ចំណូល",
                        Provider: "អ្នកផ្តល់",
                        "Select All": "ជ្រើសរើស​ទាំងអស់",
                        Casino: "កាស៊ីណូ",
                        Sports: "កីឡា",
                        Slots: "រន្ធ",
                        Lottery: "ឆ្នោត",
                        Submit: "ដាក់ស្នើ",
                        "No.": "ទេ",
                        "Start Time": "ពេលវេលាចាប់ផ្តើម",
                        "End Time": "ពេលវេលាបញ្ចប់",
                        "No record found": "រកមិនឃើញកំណត់ត្រា",
                    },
                    transfer: {
                        "Transfer successfully": "ផ្ទេរដោយជោគជ័យ",
                        "Transfer failed": "ការផ្ទេរប្រាក់បរាជ័យ",
                        "FROM and TO cannot be the same": "FROM និង TO មិនអាចដូចគ្នាបានទេ",
                        "Amount cannot be empty": "ចំនួនទឹកប្រាក់មិនអាចទទេ",
                        "Amount cannot more than Main Balance": "ចំនួនទឹកប្រាក់មិនអាចលើសពីសមតុល្យចម្បង",
                        "Amount cannot more than": "ចំនួនទឹកប្រាក់មិនអាចលើសពី",
                        "Main Balance": "សមតុល្យចម្បង",
                        From: "ពី",
                        To: "ទៅ",
                        Amount: "ចំនួន",
                        Submit: "ដាក់ស្នើ",
                    },
                    bonus: {
                        Bonus: "ប្រាក់រង្វាន់",
                        "Referral List": "បញ្ជីរាយនាម",
                        "Referral Bonus": "ប្រាក់រង្វាន់យោង",
                        "Referral Bonus Record": "កំណត់ត្រាប្រាក់រង្វាន់យោង",
                        "Promotion Record": "កំណត់ត្រាផ្សព្វផ្សាយ",
                        "Auto Promotion Record": "កំណត់ត្រាការផ្សព្វផ្សាយដោយស្វ័យប្រវត្តិ",
                        "You have claimed your bonus": "អ្នកបានទាមទារប្រាក់រង្វាន់របស់អ្នក។",
                        "Please choose a bonus type": "សូមជ្រើសរើសប្រភេទប្រាក់រង្វាន់",
                        "Amount cannot be empty": "ចំនួនទឹកប្រាក់មិនអាចទទេ",
                        "Bonus Type": "ប្រភេទប្រាក់រង្វាន់",
                        "Transfer Bonus": "ប្រាក់រង្វាន់ផ្ទេរប្រាក់",
                        "Withdraw Bonus": "ដកប្រាក់រង្វាន់",
                        "No.": "ទេ",
                        Type: "ប្រភេទ",
                        Time: "ពេលវេលា",
                        Amount: "ចំនួន",
                        Category: "ប្រភេទ",
                        Status: "ស្ថានភាព",
                        Submit: "ដាក់ស្នើ",
                        Title: "ចំណងជើង",
                        "Join Date": "កាលបរិច្ឆេទចូលរួម",
                        "Start Date": "ថ្ងៃ​ចាប់ផ្តើម",
                        "End Date": "កាលបរិច្ឆេទបញ្ចប់",
                        Turnover: "ចំណូល",
                        "No record found": "រកមិនឃើញកំណត់ត្រា",
                        Submitting: "ការបញ្ជូន",
                        Completed: "បានបញ្ចប់",
                        Closed: "បិទ",
                        Expired: "ផុតកំណត់",
                        Processing: "ដំណើរការ",
                        "End Time": "ពេលវេលាបញ្ចប់",
                        "Transfer Count": "ចំនួនផ្ទេរ",
                        "Game name": "ឈ្មោះហ្គេម",
                        Username: "ឈ្មោះ​អ្នកប្រើប្រាស់",
                        Currency: "រូបិយប័ណ្ណ",
                        "Register Time": "ពេលវេលាចុះឈ្មោះ",
                        "Auto Promotion Turnover": "ការផ្សព្វផ្សាយដោយស្វ័យប្រវត្តិ",
                        "Auto Promotion Win Loss": "ការផ្សព្វផ្សាយដោយស្វ័យប្រវត្តិឈ្នះចាញ់",
                    },
                    login: {
                        "Please enter your username": "សូមបញ្ចូលឈ្មោះអ្នកប្រើប្រាស់របស់អ្នក",
                        "Please enter your password": "សូម​បញ្ចូល​ពាក្យ​សម្ងាត់​របស់​អ្នក",
                        "Username or password is incorrect": "ឈ្មោះ​អ្នក​ប្រើ ឬ​ពាក្យ​សម្ងាត់​មិន​ត្រឹមត្រូវ",
                        "User has been suspended": "អ្នកប្រើប្រាស់ត្រូវបានផ្អាក",
                        "You have attempted to login too many times. Please try again in 1 minute": "អ្នក​បាន​ព្យាយាម​ចូល​ច្រើន​ដង​ពេក។ សូម​ព្យាយាម​ម្ដង​ទៀត​ក្នុង​រយៈ​ពេល 1 នាទី",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "ឈ្មោះអ្នកប្រើប្រាស់មិនអាចទទេ ហើយមានតែអក្សរក្រមលេខប៉ុណ្ណោះ",
                        "Password cannot be empty": "ពាក្យសម្ងាត់មិនអាចទទេបានទេ",
                        "Password should be within 8-20 alphanumeric without spacing": "ពាក្យ​សម្ងាត់​គួរ​ស្ថិត​ក្នុង​ចន្លោះ 8-20 អក្សរ​លេខ​ដោយ​មិន​មាន​គម្លាត",
                        "Confirm password must be the same as password": "បញ្ជាក់ពាក្យសម្ងាត់ត្រូវតែដូចគ្នានឹងពាក្យសម្ងាត់",
                        "Please enter a valid contact number with length between 6 to 12": "សូម​បញ្ចូល​លេខ​ទំនាក់ទំនង​ដែល​មាន​ប្រវែង​ចន្លោះ​ពី 6 ទៅ 12",
                        "Please choose a currency": "សូមជ្រើសរើសរូបិយប័ណ្ណ",
                        Register: "ចុះឈ្មោះ",
                        Username: "ឈ្មោះ​អ្នកប្រើប្រាស់",
                        Password: "ពាក្យសម្ងាត់",
                        "Confirm Password": "បញ្ជាក់​ពាក្យ​សម្ងាត់",
                        "Contact Number": "លេខ​ទំនាក់​ទំនង",
                        Currency: "រូបិយប័ណ្ណ",
                        Referral: "យោង",
                        Submit: "ដាក់ស្នើ",
                    },
                    default: {
                        "Unauthorized access": "ការ​ចូល​ដំណើរការ​ដោយ​គ្មាន​ការ​អនុញ្ញាត",
                        "Request failed": "សំណើ​បាន​បរាជ័យ",
                    }
                }
            },
            vn: {
                translation: {
                    header: {
                        Welcome: "Chào mừng",
                        Balance: "THĂNG BẰNG",
                        Deposit: "Tiền gửi",
                        Withdraw: "Rút",
                        Logout: "Đăng xuất",
                        Home: "Nhà",
                        Sports: "Các môn thể thao",
                        Casino: "Sòng bạc",
                        Poker: "Xì phé",
                        Lottery: "Vé số",
                        Slots: "Slots",
                        Cockfight: "Chọi gà",
                        Forex: "Forex",
                        Promotions: "Khuyến mại",
                        Register: "Đăng ký",
                        Affilliate: "Đơn vị liên kết",
                    },
                    game: {
                        "Play Now": "Bắt đầu chơi",
                    },
                    memberNav: {
                        "Basic Information": "Thông tin cơ bản",
                        Deposit: "Tiền gửi",
                        Withdraw: "Rút",
                        "Bet Record": "Bản ghi cược",
                        Statement: "Bản tường trình",
                        "Change Password": "Đổi mật khẩu",
                        Bonus: "Thưởng",
                        Transfer: "Chuyển khoản",
                        Turnover: "Doanh số",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "Vui lòng chọn một ngân hàng",
                        "Amount cannot be empty": "Số tiền không được để trống",
                        "Amount must be more than": "Số tiền phải nhiều hơn",
                        "Amount cannot more than": "Số tiền không được nhiều hơn",
                        Bank: "Ngân hàng",
                        "Bank Account Name": "Tên tài khoản ngân hàng",
                        "Bank Account Number": "Số tài khoản ngân hàng",
                        "Deposit successfully": "Gửi tiền thành công",
                        Amount: "Số lượng",
                        Submit: "Nộp",
                        Deposit: "Tiền gửi",
                        "Deposit List": "Danh sách tiền gửi",
                        No: "Không",
                        DateTime: "Ngày giờ",
                        "Bank Name": "Tên ngân hàng",
                        Status: "Trạng thái",
                        Remark: "Nhận xét",
                    },
                    with: {
                        "Amount cannot be empty": "Số tiền không được để trống",
                        "Amount must be more than": "Số tiền phải nhiều hơn",
                        "Amount cannot more than": "Số tiền không được nhiều hơn",
                        Bank: "Ngân hàng",
                        "Bank Account Name": "Tên tài khoản ngân hàng",
                        "Bank Account Number": "Số tài khoản ngân hàng",
                        "Withdraw successfully": "Rút tiền thành công",
                        Amount: "Số lượng",
                        Submit: "Nộp",
                        Withdraw: "Rút",
                        "Withdraw List": "Danh sách Rút tiền",
                        No: "Không",
                        DateTime: "Ngày giờ",
                        "Bank Name": "Tên ngân hàng",
                        Status: "Trạng thái",
                        Remark: "Nhận xét",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "Đang gửi",
                        Completed: "Hoàn thành",
                        Processing: "Xử lý",
                        Expired: "Hết hạn",
                        Canceled: "Đã hủy",
                        Pending: "Chưa giải quyết",
                    },
                    memberInfo: {
                        "Member Information": "Thông tin thành viên",
                        Username: "Tên tài khoản",
                        "Referral Link": "Liên kết giới thiệu",
                        "E-mail": "E-mail",
                        Phone: "Điện thoại",
                        Bank: "Ngân hàng",
                        "Bank Account Name": "Tên tài khoản ngân hàng",
                        "Bank Account Number": "Số tài khoản ngân hàng",
                        "Total Bonus": "Tổng tiền thưởng",
                        "Last Bonus": "Phần thưởng cuối cùng",
                        Balance: "THĂNG BẰNG",
                    },
                    betRecord: {
                        Provider: "Các nhà cung cấp",
                        Submit: "Nộp",
                    },
                    statement: {
                        Provider: "Các nhà cung cấp",
                        Submit: "Nộp",
                    },
                    date: {
                        Today: "Hôm nay",
                        Yesterday: "Hôm qua",
                        "This Week": "Tuần này",
                        "Last Week": "Tuần trước",
                        "This Month": "Tháng này",
                        "Last Month": "Tháng trước",
                        "Start Date": "Ngày bắt đầu",
                        "End Date": "Ngày cuối",
                    },
                    table: {
                        "No.": "Không.",
                        Game: "Trò chơi",
                        "Game ID": "Mã số game",
                        "Bet Detail": "Chi tiết Đặt cược",
                        "Bet Time": "Thời gian đặt cược",
                        "Bet Amount": "Số tiền đặt cược",
                        "WinLoss": "Thắng thua",
                        "Bet Type": "Loại đặt cược",
                        TypeL: "Loại hình",
                        Pool: "Hồ bơi",
                        Result: "Kết quả",
                        Odds: "Tỷ lệ cược",
                        "Bet On": "Đặt cược vào",
                        "TRS ID": "TRS ID",
                        "No record found": "Không tìm thấy bản ghi!",
                    },
                    password: {
                        "Your password has been changed": "Mật khẩu của bạn đã được thay đổi.",
                        "Please enter your current password": "Vui lòng nhập mật khẩu hiện tại của bạn.",
                        "Please enter your new password": "Vui lòng nhập mật khẩu mới của bạn.",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "Mật khẩu chỉ có thể chứa bảng chữ cái và số, độ dài phải lớn hơn 8.",
                        "Confirm password cannot be empty": "Mật khẩu xác nhận không được để trống.",
                        "New password and confirm password must be the same": "Mật khẩu mới và mật khẩu xác nhận phải giống nhau.",
                        "Change Password": "Đổi mật khẩu",
                        "Current Password": "Mật khẩu hiện tại",
                        "New Password": "Mật khẩu mới",
                        "Confirm Password": "Xác nhận mật khẩu",
                        "Submit": "Nộp",
                    },
                    turnover: {
                        "Please select a provider": "Vui lòng chọn nhà cung cấp",
                        Turnover: "Doanh số",
                        Provider: "Các nhà cung cấp",
                        "Select All": "Chọn tất cả",
                        Casino: "Sòng bạc",
                        Sports: "Các môn thể thao",
                        Slots: "Slots",
                        Lottery: "Vé số",
                        Submit: "Nộp",
                        "No.": "Không.",
                        "Start Time": "Thời gian bắt đầu",
                        "End Time": "Thời gian kết thúc",
                        "No record found": "Không tìm thấy bản ghi",
                    },
                    transfer: {
                        "Transfer successfully": "Chuyển thành công",
                        "Transfer failed": "Chuyển không thành công",
                        "FROM and TO cannot be the same": "FROM và TO không được giống nhau",
                        "Amount cannot be empty": "Số tiền không được để trống",
                        "Amount cannot more than Main Balance": "Số tiền không được nhiều hơn Số dư Chính",
                        "Amount cannot more than": "Số tiền không được nhiều hơn",
                        "Main Balance": "Cân bằng chính",
                        From: "Từ",
                        To: "Đến",
                        Amount: "Số lượng",
                        Submit: "Nộp",
                    },
                    bonus: {
                        Bonus: "Thưởng",
                        "Referral List": "Danh sách giới thiệu",
                        "Referral Bonus": "Tiền thưởng giới thiệu",
                        "Referral Bonus Record": "Bản ghi tiền thưởng giới thiệu",
                        "Promotion Record": "Hồ sơ Quảng cáo",
                        "Auto Promotion Record": "Bản ghi quảng cáo tự động",
                        "You have claimed your bonus": "Bạn đã nhận được phần thưởng của mình.",
                        "Please choose a bonus type": "Vui lòng chọn loại tiền thưởng",
                        "Amount cannot be empty": "Số tiền không được để trống",
                        "Bonus Type": "Loại tiền thưởng",
                        "Transfer Bonus": "Chuyển tiền thưởng",
                        "Withdraw Bonus": "Rút tiền thưởng",
                        "No.": "Không.",
                        Type: "Loại hình",
                        Time: "Thời gian",
                        Amount: "Số lượng",
                        Category: "Loại",
                        Status: "Trạng thái",
                        Submit: "Nộp",
                        Title: "Tiêu đề",
                        "Join Date": "Ngày tham gia",
                        "Start Date": "Ngày bắt đầu",
                        "End Date": "Ngày cuối",
                        Turnover: "Doanh số",
                        "No record found": "Không tìm thấy bản ghi",
                        Submitting: "Đang gửi",
                        Completed: "Hoàn thành",
                        Closed: "Đã đóng",
                        Expired: "Hết hạn",
                        Processing: "Xử lý",
                        "End Time": "Thời gian kết thúc",
                        "Transfer Count": "Số lần chuyển tiền",
                        "Game name": "Tên trò chơi",
                        Username: "Tên tài khoản",
                        Currency: "Tiền tệ",
                        "Register Time": "Thời gian Đăng ký",
                        "Auto Promotion Turnover": "Doanh thu Quảng cáo Tự động",
                        "Auto Promotion Win Loss": "Quảng cáo Tự động Thắng lỗ",
                    },
                    login: {
                        "Please enter your username": "Xin hãy điền tên đăng nhập",
                        "Please enter your password": "Vui lòng nhập mật khẩu của bạn",
                        "Username or password is incorrect": "Tên đăng nhập hoặc tài khoản của bạn không chính xác",
                        "User has been suspended": "Người dùng đã bị tạm ngưng",
                        "You have attempted to login too many times. Please try again in 1 minute": "Bạn đã cố gắng đăng nhập quá nhiều lần. Vui lòng thử lại sau 1 phút.",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "Tên người dùng không được để trống và chỉ chứa chữ và số",
                        "Password cannot be empty": "Mật khẩu không được để trống",
                        "Password should be within 8-20 alphanumeric without spacing": "Mật khẩu phải trong 8-20 chữ và số không có khoảng cách",
                        "Confirm password must be the same as password": "Mật khẩu xác nhận phải giống mật khẩu",
                        "Please enter a valid contact number with length between 6 to 12": "Vui lòng nhập một số liên hệ hợp lệ có độ dài từ 6 đến 12",
                        "Please choose a currency": "Vui lòng chọn đơn vị tiền tệ",
                        Register: "Đăng ký",
                        Username: "Tên tài khoản",
                        Password: "Mật khẩu",
                        "Confirm Password": "Xác nhận mật khẩu",
                        "Contact Number": "Số liên lạc",
                        Currency: "Tiền tệ",
                        Referral: "Giới thiệu",
                        Submit: "Nộp",
                    },
                    default: {
                        "Unauthorized access": "Truy cập trái phép",
                        "Request failed": "Yêu cầu không thành công",
                    }
                }
            },
            ph: {
                translation: {
                    header: {
                        Welcome: "Welcome",
                        Balance: "Balance",
                        Deposit: "Deposit",
                        Withdraw: "Withdraw",
                        Logout: "Logout",
                        Home: "Home",
                        Sports: "Sports",
                        Casino: "Casino",
                        Poker: "Poker",
                        Lottery: "Lottery",
                        Slots: "Slots",
                        Cockfight: "Cockfight",
                        Forex: "Forex",
                        Promotions: "Promotions",
                        Register: "Register",
                        Affilliate: "Affilliate",
                    },
                    game: {
                        "Play Now": "Play Now",
                    },
                    memberNav: {
                        "Basic Information": "Basic Information",
                        Deposit: "Deposit",
                        Withdraw: "Withdraw",
                        "Bet Record": "Bet Record",
                        Statement: "Statement",
                        "Change Password": "Change Password",
                        Bonus: "Bonus",
                        Transfer: "Transfer",
                        Turnover: "Turnover",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "Please choose a bank",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount must be more than": "Amount must be more than",
                        "Amount cannot more than": "Amount cannot more than",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Deposit successfully": "Deposit successfully",
                        Amount: "Amount",
                        Submit: "Submit",
                        Deposit: "Deposit",
                        "Deposit List": "Deposit List",
                        No: "No",
                        DateTime: "Date & Time",
                        "Bank Name": "Bank Name",
                        Status: "Status",
                        Remark: "Remark",
                    },
                    with: {
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount must be more than": "Amount must be more than",
                        "Amount cannot more than": "Amount cannot more than",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Withdraw successfully": "Withdraw successfully",
                        Amount: "Amount",
                        Submit: "Submit",
                        Withdraw: "Withdraw",
                        "Withdraw List": "Withdraw List",
                        No: "No",
                        DateTime: "Date & Time",
                        "Bank Name": "Bank Name",
                        Status: "Status",
                        Remark: "Remark",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "Submitting",
                        Completed: "Completed",
                        Processing: "Processing",
                        Expired: "Expired",
                        Canceled: "Canceled",
                        Pending: "Pending",
                    },
                    memberInfo: {
                        "Member Information": "Member Information",
                        Username: "Username",
                        "Referral Link": "Referral Link",
                        "E-mail": "E-mail",
                        Phone: "Phone",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Total Bonus": "Total Bonus",
                        "Last Bonus": "Last Bonus",
                        Balance: "Balance",
                    },
                    betRecord: {
                        Provider: "Provider",
                        Submit: "Submit",
                    },
                    statement: {
                        Provider: "Provider",
                        Submit: "Submit",
                    },
                    date: {
                        Today: "Today",
                        Yesterday: "Yesterday",
                        "This Week": "This Week",
                        "Last Week": "Last Week",
                        "This Month": "This Month",
                        "Last Month": "Last Month",
                        "Start Date": "Start Date",
                        "End Date": "End Date",
                    },
                    table: {
                        "No.": "No.",
                        Game: "Game",
                        "Game ID": "Game ID",
                        "Bet Detail": "Bet Detail",
                        "Bet Time": "Bet Time",
                        "Bet Amount": "Bet Amount",
                        "WinLoss": "WinLoss",
                        "Bet Type": "Bet Type",
                        TypeL: "Type",
                        Pool: "Pool",
                        Result: "Result",
                        Odds: "Odds",
                        "Bet On": "Bet On",
                        "TRS ID": "TRS ID",
                        "No record found": "No record found!",
                    },
                    password: {
                        "Your password has been changed": "Your password has been changed.",
                        "Please enter your current password": "Please enter your current password.",
                        "Please enter your new password": "Please enter your new password.",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "Password can only contain alphabet and numbers ,length must be more than 8.",
                        "Confirm password cannot be empty": "Confirm password cannot be empty.",
                        "New password and confirm password must be the same": "New password and confirm password must be the same.",
                        "Change Password": "Change Password",
                        "Current Password": "Current Password",
                        "New Password": "New Password",
                        "Confirm Password": "Confirm Password",
                        "Submit": "Submit",
                    },
                    turnover: {
                        "Please select a provider": "Please select a provider",
                        Turnover: "Turnover",
                        Provider: "Provider",
                        "Select All": "Select All",
                        Casino: "Casino",
                        Sports: "Sports",
                        Slots: "Slots",
                        Lottery: "Lottery",
                        Submit: "Submit",
                        "No.": "No.",
                        "Start Time": "Start Time",
                        "End Time": "End Time",
                        "No record found": "No record found",
                    },
                    transfer: {
                        "Transfer successfully": "Transfer successfully",
                        "Transfer failed": "Transfer failed",
                        "FROM and TO cannot be the same": "FROM and TO cannot be the same",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount cannot more than Main Balance": "Amount cannot more than Main Balance",
                        "Amount cannot more than": "Amount cannot more than",
                        "Main Balance": "Main Balance",
                        From: "From",
                        To: "To",
                        Amount: "Amount",
                        Submit: "Submit",
                    },
                    bonus: {
                        Bonus: "Bonus",
                        "Referral List": "Referral List",
                        "Referral Bonus": "Referral Bonus",
                        "Referral Bonus Record": "Referral Bonus Record",
                        "Promotion Record": "Promotion Record",
                        "Auto Promotion Record": "Auto Promotion Record",
                        "You have claimed your bonus": "You have claimed your bonus.",
                        "Please choose a bonus type": "Please choose a bonus type",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Bonus Type": "Bonus Type",
                        "Transfer Bonus": "Transfer Bonus",
                        "Withdraw Bonus": "Withdraw Bonus",
                        "No.": "No.",
                        Type: "Type",
                        Time: "Time",
                        Amount: "Amount",
                        Category: "Category",
                        Status: "Status",
                        Submit: "Submit",
                        Title: "Title",
                        "Join Date": "Join Date",
                        "Start Date": "Start Date",
                        "End Date": "End Date",
                        Turnover: "Turnover",
                        "No record found": "No record found",
                        Submitting: "Submitting",
                        Completed: "Completed",
                        Closed: "Closed",
                        Expired: "Expired",
                        Processing: "Processing",
                        "End Time": "End Time",
                        "Transfer Count": "Transfer Count",
                        "Game name": "Game name",
                        Username: "Username",
                        Currency: "Currency",
                        "Register Time": "Register Time",
                        "Auto Promotion Turnover": "Auto Promotion Turnover",
                        "Auto Promotion Win Loss": "Auto Promotion Win Loss",
                    },
                    login: {
                        "Please enter your username": "Please enter your username",
                        "Please enter your password": "Please enter your password",
                        "Username or password is incorrect": "Username or password is incorrect",
                        "User has been suspended": "User has been suspended",
                        "You have attempted to login too many times. Please try again in 1 minute": "You have attempted to login too many times. Please try again in 1 minute.",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "Username cannot be empty and contain only alphanumeric",
                        "Password cannot be empty": "Password cannot be empty",
                        "Password should be within 8-20 alphanumeric without spacing": "Password should be within 8-20 alphanumeric without spacing",
                        "Confirm password must be the same as password": "Confirm password must be the same as password",
                        "Please enter a valid contact number with length between 6 to 12": "Please enter a valid contact number with length between 6 to 12",
                        "Please choose a currency": "Please choose a currency",
                        Register: "Register",
                        Username: "Username",
                        Password: "Password",
                        "Confirm Password": "Confirm Password",
                        "Contact Number": "Contact Number",
                        Currency: "Currency",
                        Referral: "Referral",
                        Submit: "Submit",
                    },
                    default: {
                        "Unauthorized access": "Unauthorized access",
                        "Request failed": "Request failed",
                    }
                }
            },
            in: {
                translation: {
                    header: {
                        Welcome: "Welcome",
                        Balance: "Balance",
                        Deposit: "Deposit",
                        Withdraw: "Withdraw",
                        Logout: "Logout",
                        Home: "Home",
                        Sports: "Sports",
                        Casino: "Casino",
                        Poker: "Poker",
                        Lottery: "Lottery",
                        Slots: "Slots",
                        Cockfight: "Cockfight",
                        Forex: "Forex",
                        Promotions: "Promotions",
                        Register: "Register",
                        Affilliate: "Affilliate",
                    },
                    game: {
                        "Play Now": "Play Now",
                    },
                    memberNav: {
                        "Basic Information": "Basic Information",
                        Deposit: "Deposit",
                        Withdraw: "Withdraw",
                        "Bet Record": "Bet Record",
                        Statement: "Statement",
                        "Change Password": "Change Password",
                        Bonus: "Bonus",
                        Transfer: "Transfer",
                        Turnover: "Turnover",
                        Message: "Message",
                    },
                    deposit: {
                        "Please choose a bank": "Please choose a bank",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount must be more than": "Amount must be more than",
                        "Amount cannot more than": "Amount cannot more than",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Deposit successfully": "Deposit successfully",
                        Amount: "Amount",
                        Submit: "Submit",
                        Deposit: "Deposit",
                        "Deposit List": "Deposit List",
                        No: "No",
                        DateTime: "Date & Time",
                        "Bank Name": "Bank Name",
                        Status: "Status",
                        Remark: "Remark",
                    },
                    with: {
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount must be more than": "Amount must be more than",
                        "Amount cannot more than": "Amount cannot more than",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Withdraw successfully": "Withdraw successfully",
                        Amount: "Amount",
                        Submit: "Submit",
                        Withdraw: "Withdraw",
                        "Withdraw List": "Withdraw List",
                        No: "No",
                        DateTime: "Date & Time",
                        "Bank Name": "Bank Name",
                        Status: "Status",
                        Remark: "Remark",
                        "Fix Bank Account": "Fix Bank Account",
                    },
                    bind: {
                        Bank: "Bank",
                        "Please choose a bank": "Please choose a bank",
                        "Please enter your bank account name": "Please enter your bank account name",
                        "Please enter your bank account number": "Please enter your bank account number",
                        "Please enter your valid email": "Please enter your valid email",
                        "Please enter your valid phone number": "Please enter your valid phone number",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        Email: "E-mail",
                        Tel: "Tel",
                        Submit: "Submit",
                        "Fix Bank successfully": "Fix Bank successfully",
                        "Bank account name has been used": "Bank account name has been used",
                    },
                    record: {
                        Submitting: "Submitting",
                        Completed: "Completed",
                        Processing: "Processing",
                        Expired: "Expired",
                        Canceled: "Canceled",
                        Pending: "Pending",
                    },
                    memberInfo: {
                        "Member Information": "Member Information",
                        Username: "Username",
                        "Referral Link": "Referral Link",
                        "E-mail": "E-mail",
                        Phone: "Phone",
                        Bank: "Bank",
                        "Bank Account Name": "Bank Account Name",
                        "Bank Account Number": "Bank Account Number",
                        "Total Bonus": "Total Bonus",
                        "Last Bonus": "Last Bonus",
                        Balance: "Balance",
                    },
                    betRecord: {
                        Provider: "Provider",
                        Submit: "Submit",
                    },
                    statement: {
                        Provider: "Provider",
                        Submit: "Submit",
                    },
                    date: {
                        Today: "Today",
                        Yesterday: "Yesterday",
                        "This Week": "This Week",
                        "Last Week": "Last Week",
                        "This Month": "This Month",
                        "Last Month": "Last Month",
                        "Start Date": "Start Date",
                        "End Date": "End Date",
                    },
                    table: {
                        "No.": "No.",
                        Game: "Game",
                        "Game ID": "Game ID",
                        "Bet Detail": "Bet Detail",
                        "Bet Time": "Bet Time",
                        "Bet Amount": "Bet Amount",
                        "WinLoss": "WinLoss",
                        "Bet Type": "Bet Type",
                        TypeL: "Type",
                        Pool: "Pool",
                        Result: "Result",
                        Odds: "Odds",
                        "Bet On": "Bet On",
                        "TRS ID": "TRS ID",
                        "No record found": "No record found!",
                    },
                    password: {
                        "Your password has been changed": "Your password has been changed.",
                        "Please enter your current password": "Please enter your current password.",
                        "Please enter your new password": "Please enter your new password.",
                        "Password can only contain alphabet and numbers ,length must be more than 8": "Password can only contain alphabet and numbers ,length must be more than 8.",
                        "Confirm password cannot be empty": "Confirm password cannot be empty.",
                        "New password and confirm password must be the same": "New password and confirm password must be the same.",
                        "Change Password": "Change Password",
                        "Current Password": "Current Password",
                        "New Password": "New Password",
                        "Confirm Password": "Confirm Password",
                        "Submit": "Submit",
                    },
                    turnover: {
                        "Please select a provider": "Please select a provider",
                        Turnover: "Turnover",
                        Provider: "Provider",
                        "Select All": "Select All",
                        Casino: "Casino",
                        Sports: "Sports",
                        Slots: "Slots",
                        Lottery: "Lottery",
                        Submit: "Submit",
                        "No.": "No.",
                        "Start Time": "Start Time",
                        "End Time": "End Time",
                        "No record found": "No record found",
                    },
                    transfer: {
                        "Transfer successfully": "Transfer successfully",
                        "Transfer failed": "Transfer failed",
                        "FROM and TO cannot be the same": "FROM and TO cannot be the same",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Amount cannot more than Main Balance": "Amount cannot more than Main Balance",
                        "Amount cannot more than": "Amount cannot more than",
                        "Main Balance": "Main Balance",
                        From: "From",
                        To: "To",
                        Amount: "Amount",
                        Submit: "Submit",
                    },
                    bonus: {
                        Bonus: "Bonus",
                        "Referral List": "Referral List",
                        "Referral Bonus": "Referral Bonus",
                        "Referral Bonus Record": "Referral Bonus Record",
                        "Promotion Record": "Promotion Record",
                        "Auto Promotion Record": "Auto Promotion Record",
                        "You have claimed your bonus": "You have claimed your bonus.",
                        "Please choose a bonus type": "Please choose a bonus type",
                        "Amount cannot be empty": "Amount cannot be empty",
                        "Bonus Type": "Bonus Type",
                        "Transfer Bonus": "Transfer Bonus",
                        "Withdraw Bonus": "Withdraw Bonus",
                        "No.": "No.",
                        Type: "Type",
                        Time: "Time",
                        Amount: "Amount",
                        Category: "Category",
                        Status: "Status",
                        Submit: "Submit",
                        Title: "Title",
                        "Join Date": "Join Date",
                        "Start Date": "Start Date",
                        "End Date": "End Date",
                        Turnover: "Turnover",
                        "No record found": "No record found",
                        Submitting: "Submitting",
                        Completed: "Completed",
                        Closed: "Closed",
                        Expired: "Expired",
                        Processing: "Processing",
                        "End Time": "End Time",
                        "Transfer Count": "Transfer Count",
                        "Game name": "Game name",
                        Username: "Username",
                        Currency: "Currency",
                        "Register Time": "Register Time",
                        "Auto Promotion Turnover": "Auto Promotion Turnover",
                        "Auto Promotion Win Loss": "Auto Promotion Win Loss",
                    },
                    login: {
                        "Please enter your username": "Please enter your username",
                        "Please enter your password": "Please enter your password",
                        "Username or password is incorrect": "Username or password is incorrect",
                        "User has been suspended": "User has been suspended",
                        "You have attempted to login too many times. Please try again in 1 minute": "You have attempted to login too many times. Please try again in 1 minute.",
                    },
                    register: {
                        "Username cannot be empty and contain only alphanumeric": "Username cannot be empty and contain only alphanumeric",
                        "Password cannot be empty": "Password cannot be empty",
                        "Password should be within 8-20 alphanumeric without spacing": "Password should be within 8-20 alphanumeric without spacing",
                        "Confirm password must be the same as password": "Confirm password must be the same as password",
                        "Please enter a valid contact number with length between 6 to 12": "Please enter a valid contact number with length between 6 to 12",
                        "Please choose a currency": "Please choose a currency",
                        Register: "Register",
                        Username: "Username",
                        Password: "Password",
                        "Confirm Password": "Confirm Password",
                        "Contact Number": "Contact Number",
                        Currency: "Currency",
                        Referral: "Referral",
                        Submit: "Submit",
                    },
                    default: {
                        "Unauthorized access": "Unauthorized access",
                        "Request failed": "Request failed",
                    }
                }
            }
        }
    });

export default i18n;