import React, { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import PrivateRoute from "./router/PrivateRoute";
import "react-toastify/dist/ReactToastify.css";

import {
	getDomain as domainInApi,
	getCurrentIp as getCurrentIpInApi,
} from "./api";
import {
	template as templateInConfig
} from "./config";
import Games from "./pages/games/Games";
import SlotGame from "./pages/games/SlotGame";
import CasinoGame from "./pages/games/CasinoGame";
import PokerGame from "./pages/games/PokerGame";
import Lottery from "./pages/games/lottery/Lottery";
import Promotion from "./pages/promotion/Promotion";
import { Spinner } from "react-bootstrap";
import Header from "./components/header/Header";


const App = () => {
	const dir = localStorage.getItem("dir") || "";
	const webId = localStorage.getItem("webId") || "";
	const { t, i18n } = useTranslation();
	const template = templateInConfig('995');

	useEffect(() => {
		(async () => {
			await import(`./contents/css/kg/main-${template.toLowerCase()}.scss`);
		})();


		if (webId === "") {
			const getDomainIp = async () => {
				await domainInApi();
				await getCurrentIpInApi();
			};
			getDomainIp();
		}

	}, [webId]);

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};

	const Components = (path) => {
		const tryRequire = (path) => {
			try {
				return require(`./${path + template}`);
			} catch (err) {
				return null;
			}
		};
		if (tryRequire(path)) {
			return React.lazy(() => import(`./${path + template}`));
		}
		return React.lazy(() => import(`./${path}`));
	}
	// const { dir } = useContext(Context)
	const dirr = "";
	// const Header = Components(`components/header/Header`);
	const Main = Components(`components/main/Main`);
	const Footer = Components(`components/footer/Footer`);
	const Register = Components(`pages/register/Register`);
	const Sport = Components(`pages/games/sportLobby/Sport`);
	const Casino = Components(`pages/games/casinoLobby/Casino`);
	const Slot = Components(`pages/games/slotLobby/Slot`);
	const Poker = Components(`pages/games/pokerLobby/Poker`);
	const LotteryLobby = Components(`pages/games/lotteryLobby/LotteryLobby`);
	const Deposit = Components(`pages/member/deposit/Deposit`);
	const DepositRecord = Components(
		`pages/member/deposit/depositRecord/DepositRecord`
	);
	const Withdraw = Components(`pages/member/withdraw/Withdraw`);
	const WithdrawRecord = Components(
		`pages/member/withdraw/withdrawRecord/WithdrawRecord`
	);
	const BindBank = Components(
		`pages/member/bindBank/BindBank`
	);
	const MemberInfo = Components(`pages/member/info/MemberInfo`);
	const Turnover = Components(`pages/member/turnover/Turnover`);
	const Message = Components(`pages/member/message/Message`);
	const ChangePassword = Components(
		`pages/member/password/ChangePassword`
	);
	const Bonus = Components(`pages/member/bonus/bonus/Bonus`);
	const ReferralList = Components(
		`pages/member/bonus/referralList/ReferralList`
	);
	const ReferralBonus = Components(
		`pages/member/bonus/referralBonus/ReferralBonus`
	);
	const ReferralBonusRecord = Components(
		`pages/member/bonus/referralBonusRecord/ReferralBonusRecord`
	);
	const PromoRecord = Components(
		`pages/member/bonus/promoRecord/PromoRecord`
	);
	const AutoPromoRecord = Components(
		`pages/member/bonus/autoPromoRecord/AutoPromoRecord`
	);
	const Statement = Components(`pages/member/statement/Statement`);
	const Transfer = Components(`pages/member/transfer/Transfer`);
	const TransferRecord = Components(
		`pages/member/transfer/transferRecord/TransferRecord`
	);
	const BetRecord = Components(`pages/member/betRecord/BetRecord`);

	return (
		<AuthProvider>
			<Router>
				<Suspense
					fallback={
						<Spinner
							className="d-block mx-auto"
							variant="standard"
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
						/>
					}
				>
					<Header changeLanguage={changeLanguage} t={t} />
					<Routes>
						<Route exact path="/" element={<Main />} />
						<Route exact path="/register" element={<Register t={t} />} />
						<Route exact path="/sport" element={<Sport t={t} />} />
						<Route exact path="/casino" element={<Casino t={t} />} />
						<Route exact path="/slot" element={<Slot t={t} />} />
						<Route exact path="/poker" element={<Poker t={t} />} />
						<Route exact path="/lottery-lobby" element={<LotteryLobby t={t} />} />
						<Route
							exact
							path="/deposit"
							element={
								<PrivateRoute>
									<Deposit t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/deposit-record"
							element={
								<PrivateRoute>
									<DepositRecord t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/withdraw"
							element={
								<PrivateRoute>
									<Withdraw t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/withdraw-record"
							element={
								<PrivateRoute>
									<WithdrawRecord t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/bind-bank"
							element={
								<PrivateRoute>
									<BindBank t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/member-info"
							element={
								<PrivateRoute>
									<MemberInfo t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/turnover"
							element={
								<PrivateRoute>
									<Turnover t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/message"
							element={
								<PrivateRoute>
									<Message t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/change-password"
							element={
								<PrivateRoute>
									<ChangePassword t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/bonus"
							element={
								<PrivateRoute>
									<Bonus t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/referral-bonus"
							element={
								<PrivateRoute>
									<ReferralBonus t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/referral-bonus-record"
							element={
								<PrivateRoute>
									<ReferralBonusRecord t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/referral-list"
							element={
								<PrivateRoute>
									<ReferralList t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/transfer"
							element={
								<PrivateRoute>
									<Transfer t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/transfer-record"
							element={
								<PrivateRoute>
									<TransferRecord t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/promo-record"
							element={
								<PrivateRoute>
									<PromoRecord t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/auto-promo-record"
							element={
								<PrivateRoute>
									<AutoPromoRecord t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/bet-record"
							element={
								<PrivateRoute>
									<BetRecord t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/statement"
							element={
								<PrivateRoute>
									<Statement t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/games/:game"
							element={
								<PrivateRoute>
									<Games t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/slots/:provider"
							element={
								<PrivateRoute>
									<SlotGame t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/casino/:game"
							element={
								<PrivateRoute>
									<CasinoGame t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/poker/:game"
							element={
								<PrivateRoute>
									<PokerGame t={t} />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/lottery/:lotteryId"
							element={
								<>
									<Lottery t={t} />
								</>
							}
						/>
						<Route
							exact
							path="/lottery"
							element={
								<>
									<Lottery t={t} />
								</>
							}
						/>
						<Route exact path="/promotion" element={<Promotion t={t} />} />
					</Routes>
					<Footer />
				</Suspense>
			</Router>
			<ToastContainer />
		</AuthProvider>
	);
}

export default App;
