import TableStatement from "../../../components/table/TableStament";

const Special = (props) => {

    return (
        <>
            <div className="lottery-bet d-flex mt-2">
                <TableStatement loading={props.loading} data="" url="" provider="" t={props.t} />
            </div>
        </>
    )
}

export default Special;