import { useEffect } from "react";
import { useState } from "react";
import { Table, Container, Row, Col, Spinner } from "react-bootstrap";
import { transferRecord as transferRecordInApi } from "../../../../api"

import MenuKg from "../../../../components/menu/MenuKg";
import TransferMenuKg from "../transferMenu/TransferMenuKg";

const TransferRecord = (props) => {
    const [record, setRecord] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const transferRecord = async () => {
            setLoading(true);
            const response = await transferRecordInApi();
            //setRecord(response.data)
            setLoading(false);
        }
        transferRecord();
    }, [])

    const transferId = { 1: "Main Balance", 5: "918Kiss", 20: "We1Poker", 24: "KH Gaming", 25: "XE88", 28: "Mega888", 1036: "KaiYuan" };
    const status = ["Progressing", "Success", "Cancel"];
    return (
        <>
            <Container className="container-lg member-info my-3 mb-5" fluid>
                <Row className="gx-0">
                    <Col lg={3}>
                        <MenuKg t={props.t} />
                    </Col>
                    <Col lg={9} className="ms-n4">
                        <Row className="py-2 flex-grow-1 d-flex flex-column">
                            <TransferMenuKg t={props.t} />
                            <Table striped hover className="mt-3">
                                <thead className="thead-bg">
                                    <tr>
                                        <th>No.</th>
                                        <th>Date & Time</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="6">
                                                    <Spinner
                                                        className="d-block mx-auto"
                                                        variant="standard"
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" />
                                                </td>
                                            </tr> :
                                            record.length > 0 ?
                                                record.map((rec, index) =>
                                                    <tr key={`d-record-${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{rec.insert_time}</td>
                                                        <td>{transferId[rec.from_gameid]}</td>
                                                        <td>{transferId[rec.to_gameid]}</td>
                                                        <td className={rec.amount > 0 ? "text-success" : "text-danger"}>{rec.amount}</td>
                                                        <td>{status[rec.status]}</td>
                                                    </tr>
                                                ) : <tr><td colSpan="6" className="text-center">No record found !</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default TransferRecord