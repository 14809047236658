import { useState } from "react";
import {
    Container,
    Row,
    Form,
    Button,
    Table,
    Spinner
} from "react-bootstrap";
import MemberNav from "../../MemberNav";
import { referralBonusRecord as referralBonusRecordInApi } from "../../../../api";
import BonusMenu from "../bonusMenu/BonusMenu";
import Date from "../../../../components/date/Date";

const ReferralBonusRecord = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const referralBonusRecord = async () => {
            setLoading(true);
            const response = await referralBonusRecordInApi(formData);
            //console.log(response)
            if (response.data) {
                setRecord(response.data);
            }

            setLoading(false);
        };
        referralBonusRecord();
    };
    const updateDate = (data) => {
        setFormData(prev => { return { ...prev, ...data } })
    }
    const statusArr = {
        0: "Submitting",
        1: "Completed",
        2: "Closed",
        3: "Expired",
        4: "Processing",
    };
    //console.log(formData);
    return (
        <Container className="d-flex">
            <MemberNav t={props.t} />
            <Row className="py-2 flex-grow-1 d-flex flex-column right-content">
                <BonusMenu t={props.t} />
                <Form.Group className="my-3">
                    <Date data={formData} handleChange={handleChange} selectDate={updateDate} t={props.t} />
                    <Button variant="light" className="btn bg-primary m-auto mt-2 d-block" onClick={(e) => handleSubmit(e)}>Submit</Button>
                </Form.Group>
                <Table striped bordered hover variant="light">
                    <thead>
                        <tr>
                            <th>{props.t('bonus.No.')}</th>
                            <th>{props.t('bonus.Type')}</th>
                            <th>{props.t('bonus.End Time')}</th>
                            <th>{props.t('bonus.Status')}</th>
                            <th>{props.t('bonus.Transfer Bonus')}</th>
                            <th>{props.t('bonus.Transfer Count')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td colSpan="6">
                                        <Spinner
                                            className="d-block mx-auto"
                                            variant="standard"
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true" />
                                    </td>
                                </tr> :
                                record.length > 0 ?
                                    record.map((rec, index) => (
                                        <tr key={`refB-record-${index}`}>
                                            <td>{index + 1}</td>
                                            <td>Provider</td>
                                            <td>{rec.end_time}</td>
                                            <td>{props.t('bonus.' + statusArr[rec.status])}</td>
                                            <td>
                                                {rec.ref_amount
                                                    ? parseFloat(rec.ref_amount).toFixed(2)
                                                    : "0.00"}
                                            </td>
                                            <td>{rec.transfer_count}</td>
                                        </tr>
                                    )) : <tr><td colSpan="6" className="text-center">{props.t('bonus.No record found')}</td></tr>
                        }
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
};

export default ReferralBonusRecord;
