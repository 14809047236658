import { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Spinner
} from "react-bootstrap";
import { statement as statementInApi, gameSwitch as gameSwitchInApi } from "../../../api";

import MenuKg from "../../../components/menu/MenuKg";
import DateKg from "../../../components/date/DateKg";
import TableStatement from "../../../components/table/TableStament";

const Statement = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);

    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
        provider: "",
        url: "",
    });

    useEffect(() => {
        const gameSwitch = async () => {
            const response = await gameSwitchInApi()
            const groupGame = {}
            response.forEach(game => {
                if (!game.category || !game.statementUrl) return;

                if (!groupGame[game.category]) groupGame[game.category] = [];
                groupGame[game.category].push({
                    value: game.statementUrl,
                    text: game.name,
                    gamesSwitch: game.gamesSwitch
                });
            })
            setGames(groupGame);
        }
        gameSwitch()
        //console.log(games)
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        const selectedProvider = event.target.options[event.target.selectedIndex].getAttribute('provider');

        if (name == 'url') {
            setRecord([]);
        }
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
                provider: selectedProvider
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!formData.provider) {
            alert("Please select a provider");
            return false;
        }

        const statement = async () => {
            setLoading(true);
            const response = await statementInApi(formData);
            if (response) {
                let responseData = response.data;
                if (response.data.report) {
                    responseData = response.data.report
                }
                setRecord(responseData);
            }

            setLoading(false);
        };
        statement();
    };

    const gameOptions = (options) => {
        return options.map((option, index) => {
            return (
                <option key={`pro-option-statement-${index}`} value={option.value} provider={option.gamesSwitch}>
                    {option.text}
                </option>
            );
        });
    };

    const updateDate = (data) => {
        setFormData(prev => { return { ...prev, ...data } })
    }

    //console.log(formData)
    return (
        <Container className="container-lg member-info my-3 mb-5" fluid>
            <Row>
                <Col lg={3}>
                    <MenuKg t={props.t} />
                </Col>
                <Col lg={9}>
                    <Row className="py-2 px-3">
                        <Form.Group className="mb-3">
                            <div className="mb-3">
                                <select className="form-select" name="url" onChange={(e) => handleChange(e)}>
                                    <option>{props.t('statement.Provider')}</option>
                                    {
                                        games &&
                                        Object.keys(games).map((gameGroup, index) =>
                                            <optgroup key={`pro-group-statement-${index}`} label={gameGroup}>
                                                {gameOptions(games[gameGroup])}
                                            </optgroup>
                                        )
                                    }
                                </select>
                            </div>
                            <DateKg data={formData} handleChange={handleChange} selectDate={updateDate} t={props.t} />

                            <Button
                                variant="light"
                                className="btn btn-red-gradient px-4 btn-shadow mt-3"
                                onClick={(e) => handleSubmit(e)}
                                disabled={loading}>
                                {loading ? (
                                    <Spinner
                                        variant="standard"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    props.t('statement.Submit')
                                )}
                            </Button>
                        </Form.Group>
                        <div className="py-2">
                            <TableStatement loading={loading} data={record} url={formData.url} provider={formData.provider} t={props.t} />
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Statement