import { useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Spinner,
    Modal
} from "react-bootstrap";
import { getMessage as getMessageInApi, updateMessage as updateMessageInApi } from "../../../api";
import HTMLReactParser from "html-react-parser";

import MenuKg from "../../../components/menu/MenuKg";
import DateKg from "../../../components/date/DateKg";
import TableStatement from "../../../components/table/TableStament";

const Message = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);

    const [formData, setFormData] = useState({
        startDate: "",
        endDate: "",
        provider: "Message",
        url: "",
        type: "1",
    });

    const popUpModal = (msgId) => {
        for (const x in record) {
            if (record[x].id_mod_msg_member === msgId) {
                setModalStatus(true);
                setFormData((prevFormData) => {
                    return {
                        ...prevFormData,
                        ...record[x],
                    };
                });
                if (parseInt(record[x].status) === 0) {
                    const updateMessage = async () => {
                        const responseMsg = await updateMessageInApi(msgId);
                        console.log(parseInt(responseMsg.status))
                        if (responseMsg && parseInt(responseMsg.status) === 1) {
                            let newRecord = [...record];
                            newRecord[x].status = 1;
                            setRecord(newRecord);
                        }
                    }
                    updateMessage();
                }
                break;
            }
        }
    }
    const closePopUp = () => {
        setModalStatus(false);
    }
    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name == 'url') {
            setRecord([]);
        }
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const getMessage = async () => {
            setLoading(true);
            const response = await getMessageInApi(formData);
            console.log(response)
            if (response) {
                let responseData = response.data;
                if (response.data) {
                    responseData = response.data
                }
                setRecord(responseData);
            }

            setLoading(false);
        };
        getMessage();
    };

    const updateDate = (data) => {
        setFormData(prev => { return { ...prev, ...data } })
    }

    //console.log(formData)
    return (
        <Container className="container-lg member-info my-3 mb-5" fluid>
            {
                modalStatus &&
                <Modal show="true">
                    <Modal.Header>
                        <Modal.Title>{formData.caption}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            dangerouslySetInnerHTML={{ __html: HTMLReactParser(formData.content) }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={closePopUp} className="btn btn-red-gradient btn-shadow mx-1">
                            {props.t("Message.Close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            <Row>
                <Col lg={3}>
                    <MenuKg t={props.t} />
                </Col>
                <Col lg={9}>
                    <Row className="py-2 px-3">
                        <h4 className="d-flex border-bottom border-1 py-3 px-0 mb-3">{props.t("Message.Message")}</h4>
                        <Form.Group className="mb-3">
                            <DateKg data={formData} handleChange={handleChange} selectDate={updateDate} t={props.t} />

                            <Button
                                variant="light"
                                className="btn btn-red-gradient px-4 btn-shadow mt-3"
                                onClick={(e) => handleSubmit(e)}
                                disabled={loading}>
                                {loading ? (
                                    <Spinner
                                        variant="standard"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    props.t('statement.Submit')
                                )}
                            </Button>
                        </Form.Group>
                        <div className="py-2">
                            <TableStatement loading={loading} data={record} popUpModal={popUpModal} url={formData.url} provider={formData.provider} t={props.t} />
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Message