import { useState, useEffect } from "react";
import { lotteryColokSubmitter as lotteryColokSubmitterInApi } from "../../../api";
const Colok = (props) => {
    const [loading, setLoading] = useState(false);
    const numOfRow = 5;
    const [totalAmt, setTotalAmt] = useState(0);
    const [totalNetAmt, setTotalNetAmt] = useState(0);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        let currentTotalAmt = 0;
        for (const [key, value] of Object.entries(formData)) {
            let currentAmt = value === "" ? 0 : parseInt(value);
            if (key.includes("amount") && !key.includes("net")) {
                currentTotalAmt = currentTotalAmt + currentAmt;
            }
        }
        setTotalAmt(currentTotalAmt);
    }, [formData]);
    console.log(formData);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let netAmtArr = [];

        // Maybe change the input name format
        if (name.includes('amount')) {
            const nameNetAmt = 'net-' + name;
            const amt = parseFloat(value);
            const lottType = value.length;
            const disc = props.currentSetting[props.lottName[lottType]].discount;
            const netAmt = (amt - (amt * disc / 100)).toFixed(2);
            netAmtArr = { [nameNetAmt]: netAmt };
        }
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
                ...netAmtArr
            };
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (window.confirm('Confirm betting?')) {
            const lotteryColokSubmitter = async () => {
                setLoading(true);
                const data = { lotteryId: props.currentLott.type2, betInfo: formData, numRow: numOfRow };
                const response = await lotteryColokSubmitterInApi(data);

                if (parseInt(response.code) === 1) {
                    alert("Bet successfully, please check it in bet list");
                }

                setLoading(false);
            };
            lotteryColokSubmitter();
        }
    }

    return (
        <>
            <div className="lottery-info d-flex align-items-center mt-2 p-3">
                <div className="lottery-desc me-auto">COLOK ANGKA</div>
                <div className="lottery-time d-flex align-items-center pt-1">
                    <p className="me-2 mb-0">
                        <span className="fw-bold">DISCOUNT</span> : <span>{props.currentSetting['Angka'] && props.currentSetting['Angka'].discount}%</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">KEI</span> : <span>{props.currentSetting['Angka'] && props.currentSetting['Angka'].kei}</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">ODDS-1</span> : <span>{props.currentSetting['Angka'] && props.currentSetting['Angka'].factor}</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">ODDS-2</span> : <span>{props.currentSetting['Angka'] && props.currentSetting['Angka'].factor2}</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">ODDS-3</span> : <span>{props.currentSetting['Angka'] && props.currentSetting['Angka'].factor3}</span>
                    </p>
                    <p className="mb-0">
                        <span className="fw-bold">ODDS-4</span> : <span>{props.currentSetting['Angka'] && props.currentSetting['Angka'].factor4}</span>
                    </p>
                </div>
            </div>
            <div className="lottery-bet row">
                <div className="lottery-bet-info col-6">
                    <div className="lottery-label row">
                        <span className="col-4">Colok Number</span>
                        <span className="col-4">Amount</span>
                        <span className="col-4">Net Amount</span>
                    </div>
                    {
                        [...Array(numOfRow)].map((v, index) => (
                            <div className="lottery-col row" key={`Colok-angka-${index}`}>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        maxLength="1"
                                        value={index}
                                        readOnly
                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`amount${index + 1}`}
                                        onChange={handleChange}
                                        maxLength="6"
                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`net-amount${index + 1
                                            }`}
                                        value={formData[`net-amount${index + 1}`] ? formData[`net-amount${index + 1}`] : ""}
                                        readOnly
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="lottery-bet-info col-6">
                    <div className="lottery-label row">
                        <span className="col-4">Colok Number</span>
                        <span className="col-4">Amount</span>
                        <span className="col-4">Net Amount</span>
                    </div>
                    {
                        [...Array(numOfRow)].map((v, index) => (
                            <div className="lottery-col row" key={`Colok-angka-${index + numOfRow}`}>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        maxLength="1"
                                        value={index + numOfRow}
                                        readOnly
                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`amount${index + numOfRow}`}
                                        onChange={handleChange}
                                        maxLength="6"
                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`net-amount${index + numOfRow
                                            }`}
                                        value={formData[`net-amount${index + numOfRow}`] ? formData[`net-amount${index + numOfRow}`] : ""}
                                        readOnly
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="row mt-2 mx-0 justify-content-between">
                <div className="col-6 px-0 pe-1">
                    <div className="lottery-info d-flex align-items-center p-3">
                        <div className="lottery-desc me-auto">COLOK MACAU</div>
                        <div className="lottery-time d-flex align-items-center pt-1">
                            <p className="me-2 mb-0">
                                <span className="fw-bold">DISCOUNT</span> : <span>{props.currentSetting['Macau'] && props.currentSetting['Macau'].discount}%</span>
                            </p>
                            <p className="mb-0">
                                <span className="fw-bold">ODDS</span> : <span>{props.currentSetting['Macau'] && props.currentSetting['Macau'].factor2}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-6 px-0 ps-1">
                    <div className="lottery-info d-flex align-items-center p-3">
                        <div className="lottery-desc me-auto">COLOK NAGA</div>
                        <div className="lottery-time d-flex align-items-center pt-1">
                            <p className="me-2 mb-0">
                                <span className="fw-bold">DISCOUNT</span> : <span>{props.currentSetting['Naga'] && props.currentSetting['Naga'].discount}%</span>
                            </p>
                            <p className="mb-0">
                                <span className="fw-bold">ODDS</span> : <span>{props.currentSetting['Naga'] && props.currentSetting['Naga'].factor3}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lottery-bet row">
                <div className="lottery-bet-info col-6">
                    <div className="lottery-label row">
                        <span className="col-4">Colok Number</span>
                        <span className="col-4">Amount</span>
                        <span className="col-4">Net Amount</span>
                    </div>
                    {
                        [...Array(numOfRow)].map((v, index) => (
                            <div className="lottery-col row" key={`Colok-macau-${index + (numOfRow * 2) + 1}`}>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`num${index + (numOfRow * 2) + 1}`}
                                        onChange={handleChange}
                                        maxLength="2"
                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`amount${index + (numOfRow * 2) + 1}`}
                                        onChange={handleChange}
                                        maxLength="6"
                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`net-amount${index + (numOfRow * 2) + 1
                                            }`}
                                        value={formData[`net-amount${index + (numOfRow * 2) + 1}`] ? formData[`net-amount${index + (numOfRow * 2) + 1}`] : ""}
                                        readOnly
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="lottery-bet-info col-6">
                    <div className="lottery-label row">
                        <span className="col-4">Colok Number</span>
                        <span className="col-4">Amount</span>
                        <span className="col-4">Net Amount</span>
                    </div>
                    {
                        [...Array(numOfRow)].map((v, index) => (
                            <div className="lottery-col row" key={`Colok-Naga-${index + (numOfRow * 3) + 1}`}>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`num${index + (numOfRow * 3) + 1}`}
                                        onChange={handleChange}
                                        maxLength="3"
                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`amount${index + (numOfRow * 3) + 1}`}
                                        onChange={handleChange}
                                        maxLength="6"
                                    />
                                </div>
                                <div className="col-4">
                                    <input
                                        type="text"
                                        name={`net-amount${index + (numOfRow * 3) + 1
                                            }`}
                                        value={formData[`net-amount${index + (numOfRow * 3) + 1}`] ? formData[`net-amount${index + (numOfRow * 3) + 1}`] : ""}
                                        readOnly
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="lottery-info d-flex align-items-center mt-2 p-3">
                <div className="lottery-desc me-auto">COLOK JITU</div>
                <div className="lottery-time d-flex align-items-center pt-1">
                    <p className="me-2 mb-0">
                        <span className="fw-bold">DISCOUNT</span> : <span>{props.currentSetting['Jitu'] && props.currentSetting['Jitu'].discount}%</span>
                    </p>
                    <p className="me-2 mb-0">
                        <span className="fw-bold">KEI</span> : <span>{props.currentSetting['Jitu'] && props.currentSetting['Jitu'].kei}</span>
                    </p>
                    <p className="mb-0">
                        <span className="fw-bold">ODDS</span> : <span>{props.currentSetting['Jitu'] && props.currentSetting['Jitu'].factor}</span>
                    </p>
                </div>
            </div>
            <div className="lottery-bet d-flex">
                <div className="lottery-bet-info w-100">
                    <div className="lottery-label row">
                        <span className="col-3">Colok Jitu</span>
                        <span className="col-3">Location</span>
                        <span className="col-3">Amount</span>
                        <span className="col-3">Net Amount</span>
                    </div>
                    {
                        [...Array(5)].map((v, index) => (
                            <div className="lottery-col row" key={`Colok-Naga-${index + (numOfRow * 3) + 6}`}>
                                <div className="col-3">
                                    <input
                                        type="text"
                                        name={`num${index + (numOfRow * 3) + 6}`}
                                        onChange={handleChange}
                                        maxLength="1"
                                    />
                                </div>
                                <div className="col-3 justify-content-around">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <input
                                            type="radio"
                                            name={`location${index + (numOfRow * 3) + 6}`}
                                            value="1"
                                            onChange={handleChange}
                                            className="me-1"
                                        />
                                        <span className="text-black">AS</span>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <input
                                            type="radio"
                                            name={`location${index + (numOfRow * 3) + 6}`}
                                            value="2"
                                            onChange={handleChange}
                                            className="me-1"
                                        />
                                        <span className="text-black">KOP</span>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <input
                                            type="radio"
                                            name={`location${index + (numOfRow * 3) + 6}`}
                                            value="3"
                                            onChange={handleChange}
                                            className="me-1"
                                        />
                                        <span className="text-black">KEPALA</span>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <input
                                            type="radio"
                                            name={`location${index + (numOfRow * 3) + 6}`}
                                            value="4"
                                            onChange={handleChange}
                                            className="me-1"
                                        />
                                        <span className="text-black">EKOR</span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <input
                                        type="text"
                                        name={`amount${index + (numOfRow * 3) + 6}`}
                                        onChange={handleChange}
                                        maxLength="6"
                                    />
                                </div>
                                <div className="col-3">
                                    <input
                                        type="text"
                                        name={`net-amount${index + (numOfRow * 3) + 6
                                            }`}
                                        value={formData[`net-amount${index + (numOfRow * 3) + 6}`] ? formData[`net-amount${index + (numOfRow * 3) + 6}`] : ""}
                                        readOnly
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="lottery-footer my-3 d-flex align-items-center px-3">
                <p className="me-2 mb-0">
                    <span className="me-2">Total Amount:</span>
                    <span>{parseFloat(totalAmt).toFixed(2)}</span>
                </p>
                <p className="me-2 mb-0">
                    <span className="me-2">Total Net Amount:</span>
                    <span>{parseFloat(totalNetAmt).toFixed(2)}</span>
                </p>
                <a href="#" onClick={(e) => handleSubmit(e)} className="lottery-submit mx-2 px-4">
                    Submit
                </a>
            </div>
        </>
    )
}

export default Colok;