import { Row, Col, Container } from "react-bootstrap";
import LotteryMenu from "./LotteryMenu";
import {
    lotteryInfo as lotteryInfoFromApi,
    gameSetting as gameSettingFromApi,
} from "../../../api";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Lottery2D3D4D from "./Lottery2D3D4D";
import Combination from "./Combination";
import Shio from "./Shio";
import Colok from "./Colok";
import Alternate from "./Alternate";
import Lottery2dFront from "./Lottery2dFront";
import Lottery2dMid from "./Lottery2dMid";
import Special from "./Special";
import Result from "./Result";

const Lottery = (props) => {
    const [loading, setLoading] = useState(false);
    const [setting, setSetting] = useState([]);
    const [currentSetting, setCurrentSetting] = useState([]);
    const [lottery, setLottery] = useState([]);
    const [currentLott, setCurrentLott] = useState([]);
    const [lotteryType, setLotteryType] = useState("2D3D4D");

    const { lotteryId } = useParams("");

    const lottName = {
        1: '2D', 2: '3D', 3: '4D', 4: "BigSmall", 5: "OddEven", 6: "Combination", 7: "Angka", 8: "Macau", 9: "Naga", 10: "Jitu", 11: "Pig", 12: "Dog", 13: "Roaster", 14: "Monkey",
        15: "Goat", 16: "Horse", 17: "Snake", 18: "Dragon", 19: "Rabbit", 20: "Tiger", 21: "Cow", 22: "Rat", 106: "2D Front", 107: "2D Mid"
    };

    // Categorize all type of lottery setting
    const filterSetting = (setting, defaultLott) => {
        let newSetting = {};
        for (let s in setting) {
            const set = setting[s];
            if (set.type2 === defaultLott.type2) {
                newSetting[lottName[parseInt(set.type3)]] = { ...set };
            }
        }

        setCurrentSetting(newSetting);
    }

    useEffect(() => {
        const lotteryInfo = async () => {
            setLoading(true);
            const resLottery = await lotteryInfoFromApi();
            const resultLottery = resLottery.data
            let defaultLott = { ...resultLottery[0] }; // If lotteryId is empty, get the first lottery and set to default
            if (resultLottery) {
                if (lotteryId) {
                    defaultLott = resultLottery.filter(lott => lott.type2 === lotteryId)[0];
                }
                console.log(defaultLott, 'default');
                setCurrentLott({ ...defaultLott });
                setLottery(resultLottery);
            }
            const resSetting = await gameSettingFromApi();
            const resultSetting = resSetting.data
            if (resultSetting) {
                // Set lottery setting
                filterSetting(resultSetting, defaultLott);
                setSetting(resultSetting);
            }
            setLoading(false);
        };
        lotteryInfo();

    }, []);

    // Get current lottery setting
    useEffect(() => {
        const currentLottery = lottery.filter(lott => parseInt(lott.type2) === parseInt(lotteryId))[0];
        setCurrentLott({ ...currentLottery });
        filterSetting(setting, currentLottery);

    }, [lotteryId]);

    const handleLotteryType = (event) => {
        const type = event.target.getAttribute("href");
        setLotteryType(type.replace("#", ""));
    };

    const lotteryForm = () => {
        switch (lotteryType) {
            case "Combination":
                return <Combination currentSetting={currentSetting} currentLott={currentLott} lottName={lottName} />
            case "Shio":
                return <Shio currentSetting={currentSetting} currentLott={currentLott} lottName={lottName} />
            case "Colok":
                return <Colok currentSetting={currentSetting} currentLott={currentLott} lottName={lottName} />
            case "Alternate":
                return <Alternate currentSetting={currentSetting} currentLott={currentLott} lottName={lottName} />
            case "2D_Front":
                return <Lottery2dFront currentSetting={currentSetting} />
            case "2D_Mid":
                return <Lottery2dMid currentSetting={currentSetting} />
            case "Special":
                return <Special loading={loading} t={props.t} />
            case "Result":
                return <Result t={props.t} currentLott={currentLott} />
            default:
                return <Lottery2D3D4D currentSetting={currentSetting} currentLott={currentLott} lottName={lottName} />
        }
    }
    return (
        <Container className="lottery">
            <Row className="mt-3">
                <Col lg={2} className="p-0">
                    <LotteryMenu lotteryInfo={lottery} currentId={lotteryId} />
                </Col>

                <Col lg={10} className="lottery-content">
                    <div className="lottery-type d-flex align-items-center justify-content-between mt-2">
                        <a
                            href="#2D3D4D"
                            className={lotteryType === "2D3D4D" ? "active" : ""}
                            onClick={(e) => handleLotteryType(e)}
                        >
                            2D3D4D
                        </a>
                        <a
                            href="#Combination"
                            className={
                                lotteryType === "Combination" ? "active" : ""
                            }
                            onClick={(e) => handleLotteryType(e)}
                        >
                            Combination
                        </a>
                        <a
                            href="#Shio"
                            className={lotteryType === "Shio" ? "active" : ""}
                            onClick={(e) => handleLotteryType(e)}
                        >
                            Shio
                        </a>
                        <a
                            href="#Colok"
                            className={lotteryType === "Colok" ? "active" : ""}
                            onClick={(e) => handleLotteryType(e)}
                        >
                            Colok
                        </a>
                        <a
                            href="#Alternate"
                            className={
                                lotteryType === "Alternate" ? "active" : ""
                            }
                            onClick={(e) => handleLotteryType(e)}
                        >
                            Alternate
                        </a>
                        <a
                            href="#2D_Front"
                            className={
                                lotteryType === "2D_Front" ? "active" : ""
                            }
                            onClick={(e) => handleLotteryType(e)}
                        >
                            2D Front
                        </a>
                        <a
                            href="#2D_Mid"
                            className={lotteryType === "2D_Mid" ? "active" : ""}
                            onClick={(e) => handleLotteryType(e)}
                        >
                            2D Mid
                        </a>
                        <a
                            href="#Special"
                            className={
                                lotteryType === "Special" ? "active" : ""
                            }
                            onClick={(e) => handleLotteryType(e)}
                        >
                            Special
                        </a>
                        <a
                            href="#Result"
                            className={lotteryType === "Result" ? "active" : ""}
                            onClick={(e) => handleLotteryType(e)}
                        >
                            Result
                        </a>
                    </div>
                    {
                        lotteryForm()
                    }
                </Col>

            </Row>
        </Container>
    );
};

export default Lottery;
