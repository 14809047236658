import { useEffect } from "react";
import { useState } from "react";
import { Table, Container, Row, Spinner } from "react-bootstrap";
import { referralList as referralListInApi } from "../../../../api";
import MemberNav from "../../MemberNav";
import BonusMenu from "../bonusMenu/BonusMenu";

const ReferralList = (props) => {
    const [record, setRecord] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const referralList = async () => {
            setLoading(true);
            const response = await referralListInApi();
            if (response.data) {
                setRecord(response.data);
            }
            setLoading(false);
            //console.log(response)
        }
        referralList();
    }, [])

    return (
        <>
            <Container className="d-flex">
                <MemberNav t={props.t} />
                <Row className="py-2 flex-grow-1 d-flex flex-column">
                    <BonusMenu t={props.t} />
                    <Table className="mt-3" striped bordered hover variant="light">
                        <thead>
                            <tr>
                                <th>{props.t('bonus.No.')}</th>
                                <th>{props.t('bonus.Username')}</th>
                                <th>{props.t('bonus.Bonus')}</th>
                                <th>{props.t('bonus.Turnover')}</th>
                                <th>{props.t('bonus.Currency')}</th>
                                <th>{props.t('bonus.Register Time')}</th>
                                <th>{props.t('bonus.Status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td colSpan="7">
                                            <Spinner
                                                className="d-block mx-auto"
                                                variant="standard"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" />
                                        </td>
                                    </tr> :
                                    record.length > 0 ?
                                        record.map((rec, index) =>
                                            <tr key={`ref-record-${index}`}>
                                                <td>{index + 1}</td>
                                                <td>{rec.username}</td>
                                                <td>{rec.laster_refer}</td>
                                                <td>{rec.turnover}</td>
                                                <td>{rec.currency}</td>
                                                <td>{rec.open_time}</td>
                                                <td>{rec.sess_id ? props.t('bonus.Online') : props.t('bonus.Offline')}</td>
                                            </tr>
                                        ) : <tr><td colSpan="7" className="text-center">{props.t('bonus.No record found')}</td></tr>
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )
}

export default ReferralList