import { useState, useEffect } from "react";
import { lotteryShioSubmitter as lotteryShioSubmitterInApi } from "../../../api";

const Shio = (props) => {
    const [loading, setLoading] = useState(false);
    const [totalAmt, setTotalAmt] = useState(0);
    const [totalNetAmt, setTotalNetAmt] = useState(0);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        let currentTotalAmt = 0;
        for (const [key, value] of Object.entries(formData)) {
            let currentAmt = value === "" ? 0 : parseInt(value);
            if (key.includes("amount") && !key.includes("net")) {
                currentTotalAmt = currentTotalAmt + currentAmt;
            }
        }
        setTotalAmt(currentTotalAmt);
    }, [formData]);
    console.log(formData);
    const handleChange = (event) => {
        const { name, value } = event.target;
        const type = event.target.getAttribute('data-type');
        const typeName = event.target.getAttribute('data-num');
        const type2 = event.target.getAttribute('data-type2');
        let netAmtArr = [];
        // Maybe change the input name format
        if (name.includes('amount')) {
            const nameNetAmt = 'net-' + name;
            const amt = parseFloat(value);
            const disc = props.currentSetting[props.lottName[type2]].discount;
            console.log(type2, 'disc')
            const netAmt = (amt - (amt * disc / 100)).toFixed(2);
            netAmtArr = { [nameNetAmt]: netAmt };
        }

        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: value,
                [typeName]: type,
                ...netAmtArr
            };
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        // Check balance is it more than betting amount

        if (window.confirm('Confirm betting?')) {
            const lotteryShioSubmitter = async () => {
                setLoading(true);
                const data = { lotteryId: props.currentLott.type2, betInfo: formData };
                const response = await lotteryShioSubmitterInApi(data);

                if (parseInt(response.code) === 1) {
                    alert("Bet successfully, please check it in bet list");
                }

                setLoading(false);
            };
            lotteryShioSubmitter();
        }
    }
    const currentSetting = Object.keys(props.currentSetting);
    let x = 0;
    const filterLottery = currentSetting.map((set, index) => {
        const lottery = props.currentSetting[set];
        const type3 = parseInt(lottery.type3);
        if (type3 >= 11 && type3 <= 22) {
            x++;
            return (
                <div className="lottery-col row" key={`Shio-${index}`}>
                    <div className="col-2">
                        <span className="text-black">{set}</span>
                    </div>
                    <div className="col-2">
                        <span className="text-black">{lottery.discount}%</span>
                    </div>
                    <div className="col-2">
                        <span className="text-black">{lottery.kei}</span>
                    </div>
                    <div className="col-2">
                        <span className="text-black">{lottery.factor}</span>
                    </div>
                    <div className="col-2">
                        <input
                            type="text"
                            name={`amount${x}`}
                            onChange={handleChange}
                            data-type={lottery.ex}
                            data-type2={lottery.type3}
                            data-num={`type${x}`}
                            maxLength="6"
                        />
                    </div>
                    <div className="col-2">
                        <input
                            type="text"
                            name={`net-amount${x}`}
                            value={formData[`net-amount${x}`] ? formData[`net-amount${x}`] : ""}
                            readOnly
                        />
                    </div>
                </div>
            )
        }
    });

    return (
        <>

            <div className="lottery-bet d-flex">
                <div className="lottery-bet-info w-100">
                    <div className="lottery-label row">
                        <span className="col-2">Shio</span>
                        <span className="col-2">Discount</span>
                        <span className="col-2">Kei</span>
                        <span className="col-2">Odds</span>
                        <span className="col-2">Amount</span>
                        <span className="col-2">Net Amount</span>
                    </div>
                    {filterLottery}
                </div>
            </div>
            <div className="lottery-footer my-3 d-flex align-items-center px-3">
                <p className="me-2 mb-0">
                    <span className="me-2">Total Amount:</span>
                    <span>{parseFloat(totalAmt).toFixed(2)}</span>
                </p>
                <p className="me-2 mb-0">
                    <span className="me-2">Total Net Amount:</span>
                    <span>{parseFloat(totalNetAmt).toFixed(2)}</span>
                </p>
                <a href="#" onClick={(e) => handleSubmit(e)} className="lottery-submit mx-2 px-4">
                    Submit
                </a>
            </div>
        </>
    )
}

export default Shio;