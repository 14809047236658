import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { gameInfo as gameInConfig } from "../../config";
import { Container, Spinner } from "react-bootstrap";

const Games = () => {
	const [gameUrl, setGameUrl] = useState("");
	const [gameApi, setGameApi] = useState("");
	const [loading, setLoading] = useState(false);

	const { game } = useParams();
	const { session_id, useracc, now_ip } = JSON.parse(localStorage.getItem("token"));
	const webId = localStorage.getItem("webId");
	const param = "login.php?web_id=" + webId + "&username=" + useracc + "&platform=PC&platfrom=PC&" + "language=en&sess_id=" + session_id + "&token=" + session_id;

	useEffect(() => {

		const gameInfo = async () => {
			setLoading(true);
			const gameType = game === "sv388" ? "Cockfight" : "Sport";
			const response = await gameInConfig({ gametype: gameType });

			for (let i = 0; i < response.length; i++) {
				const gameInfo = response[i];
				if (gameInfo.url === game) {
					let api = gameInfo.api + param
					if (gameInfo.deviceType) {
						api = api + "&deviceType=" + gameInfo.deviceType;
					} else if (gameInfo.gameType) {
						api = api + "&gametype=" + gameInfo.gameType;
					} else if (gameInfo.providerGameId) {
						api = api + "&game_id=" + gameInfo.providerGameId;
					} else if (gameInfo.gameIp) {
						api = api + "&ip=" + now_ip;
					}
					if (gameInfo.url === 'w88-casino') {
						api = gameInfo.api + "&lang=en" + "&token=" + session_id;
					}
					console.log(api);
					if (gameInfo.apiReturnData === 1) {
						setGameApi(api);
					} else {
						setGameUrl(api);
					}

					break;
				}
			}
			setLoading(false);
		};
		gameInfo();

	}, []);

	useEffect(() => {
		if (gameApi) {
			const loginUrl = async () => {
				setLoading(true);

				const getUrl = await fetch(gameApi, {
					method: "POST"
				})
					.then((response) => {
						return response.json();
					})
					.then((json) => {
						let loginUrl = json.link ? json.link : json.LoginURL;
						if (json.data) {
							loginUrl = json.data;
						}
						setGameUrl(loginUrl);
					});

				setLoading(false);
			};
			loginUrl();
		}
	}, [gameApi])

	return (
		<>
			<Container>
				{loading ? (
					<Spinner
						variant="standard"
						as="span"
						animation="border"
						size="md"
						role="status"
						aria-hidden="true"
						className="text-white m-auto d-block mt-4"
					/>
				) : (
					<iframe src={gameUrl} className="w-100" style={{ height: "52rem" }} />
				)}
			</Container>
		</>
	);
};

export default Games;
