import { Container, Row, Col, Table } from "react-bootstrap"
import { Link } from "react-router-dom";
import MenuKg from "../../../components/menu/MenuKg";

const MemberInfo = (props) => {
    const user = JSON.parse(localStorage.getItem("token"));
    console.log(user);
    return (
        <Container className="container-lg member-info my-3 mb-5" fluid>
            <Row>
                <Col lg={3}>
                    <MenuKg t={props.t} />
                </Col>
                <Col lg={9}>
                    <Row className="py-2 px-3 flex-grow-1">
                        <h4 className="border-bottom border-1 py-3 fw-bold px-0">{props.t('memberInfo.Member Information')}</h4>
                        <Table className="member-info-table mt-4">
                            <tbody>
                                <tr>
                                    <td>{props.t('memberInfo.Username')}:</td>
                                    <td>{user.useracc}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.Referral Link')}:</td>
                                    <td className="d-flex align-items-center">
                                        <span className="me-auto">https://www.wwww.www</span>
                                        <Link className="btn btn-gold-nobg btn-ref" to="/">Copy</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.E-mail')}:</td>
                                    <td>{user.email}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.Phone')}:</td>
                                    <td>{user.tel}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.Bank')}:</td>
                                    <td>{user.bank_name}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.Bank Account Name')}:</td>
                                    <td>{user.bank_acc_name}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.Bank Account Number')}:</td>
                                    <td>{user.bank_acc_no}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.Total Bonus')}:</td>
                                    <td>{parseFloat(user.total_bonus).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.Last Bonus')}:</td>
                                    <td>{parseFloat(user.last_bonus).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>{props.t('memberInfo.Balance')}:</td>
                                    <td>{parseFloat(user.balance).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>Registration Date:</td>
                                    <td>{user.open_time}</td>
                                </tr>
                                <tr>
                                    <td>Last Login:</td>
                                    <td>{user.login_time}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Link to="/" className="btn btn-red-gradient btn-shadow w-25">
                            Referral List
                        </Link>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default MemberInfo