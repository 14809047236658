import { useState } from "react";
import { Link } from "react-router-dom";

import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Navbar,
	Nav,
	Spinner,
	Dropdown
} from "react-bootstrap";

function Header(props) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	let balance = parseFloat(props.auth.token.balance).toFixed(2);
	//console.log(props.auth.token);

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const { username, password } = event.target;
		if (username.value === '') {
			alert(props.t('login.Please enter your username'));
			username.focus();
			return false;
		} else if (password.value === '') {
			alert(props.t('login.Please enter your password'));
			password.focus();
			return false;
		}
		props.signInBtn(username.value, password.value);
	};
	return (
		<>
			<div className="header-top">
				<Container>
					<Row className="pt-3 pb-3">
						<Col sm={8} className="d-flex">
							{props.auth.token.session_id ? (
								<>
									<div className="d-flex align-items-center">
										<div className="text-white mx-1">
											{props.t('header.Welcome')}, {props.auth.token.useracc} : {props.t('header.Balance')} (
											{props.auth.token.currency_name}): {balance}
										</div>
										<Link to="/deposit" className="btn bg-primary mx-1">
											{props.t('header.Deposit')}
										</Link>
										<Link to="/withdraw" className="btn bg-primary mx-1">
											{props.t('header.Withdraw')}
										</Link>
										<Button
											variant="light"
											className="btn bg-primary mx-1"
											onClick={props.OutBtn}
										>
											{props.t('header.Logout')}
										</Button>
									</div>
								</>
							) : (
								<>
									<Form className="d-flex mx-1" onSubmit={handleSubmit}>
										<Form.Group className="mx-1" controlId="formBasicUsername">
											<Form.Control
												type="text"
												placeholder="Username"
												value={username}
												name="username"
												onChange={(e) => setUsername(e.target.value)}
											/>
										</Form.Group>

										<Form.Group className="mx-1" controlId="formBasicPassword">
											<Form.Control
												type="password"
												placeholder="Password"
												value={password}
												name="password"
												onChange={(e) => setPassword(e.target.value)}
											/>
										</Form.Group>
										{/* <Form.Group className="mx-1" controlId="formBasicCode">
                      <Form.Control type="text" placeholder="Code" />
                    </Form.Group> */}
										<Button
											variant="light"
											className="btn bg-primary"
											type="submit"
											disabled={props.auth.loading}
										>
											{props.auth.loading ? (
												<Spinner
													variant="standard"
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
											) : (
												"Login"
											)}
										</Button>
									</Form>
									<Link to="/register" className="btn bg-primary">
										{props.t('header.Register')}
									</Link>
								</>
							)}
						</Col>
						<Col
							sm={4}
							className="d-flex align-items-center justify-content-end"
						>
							<a href="/">{props.t('header.Affilliate')}</a>
							<Dropdown className="ms-3">
								<Dropdown.Toggle variant="dark" id="dropdown-basic" className="bg-transparent border-0">
									{props.country[0].flag}
									<span className="ms-1 ">{props.country[0].language}</span>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{props.country.map((c, index) => index > 0 && <Dropdown.Item href="#" onClick={() => props.changeLanguage(c.languageCode === 'hk' ? 'tw' : c.languageCode)} key={`language-dropdown-${index}`}>{c.flag} <span>{c.language}</span></Dropdown.Item>)}
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="header-bottom">
				<Container>
					<Navbar variant="dark">
						<Container>
							<Navbar.Brand as={Link} to="/">
								<img src={`../images/logo/logo-khmergaming.png`} alt="Logo" />
							</Navbar.Brand>
							<Nav className="ml-auto">
								<Nav.Link as={Link} to="/">
									{props.t('header.Home')}
								</Nav.Link>
								<Nav.Link as={Link} to="/sport">
									{props.t('header.Sports')}
								</Nav.Link>
								<Nav.Link as={Link} to="/casino">
									{props.t('header.Casino')}
								</Nav.Link>
								<Nav.Link as={Link} to="/poker">
									{props.t('header.Poker')}
								</Nav.Link>
								<Nav.Link as={Link} to="/lottery">{props.t('header.Lottery')}</Nav.Link>
								<Nav.Link as={Link} to="/slot">
									{props.t('header.Slots')}
								</Nav.Link>
								<Nav.Link as={Link} to="/games/sv388">{props.t('header.Cockfight')}</Nav.Link>
								<Nav.Link as={Link} to="/promotion">{props.t('header.Promotions')}</Nav.Link>
							</Nav>
						</Container>
					</Navbar>
				</Container>
			</div>
		</>
	);
}

export default Header;
